import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';

import { iconCancel, iconSave } from "app/common/utils/icons.utils";

import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { ProfileService } from 'app/services/profile.service';
import { ModalService } from 'app/common/services/modal.service';
import { Company, EntityDefinition, GroupDefinition, Product } from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'app/services/product.service';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ToastService } from 'app/common/services/toasts.service';
import { URLUtils } from 'app/services/url-utils';

@Component({
    selector: 'popup-info',
    templateUrl: './popup-info.component.html',
	styleUrls: ['popup-info.component.scss']
})
export class PopupInfoComponent implements OnInit {
	data: {
		sectionId
	};
	dataLoaded: boolean = false;

	ngOnInit() {
		
		this.dataLoaded = true;
	}

    constructor(
		private activeModal: NgbActiveModal) {
			
    }

	
	public get title() {
		switch (this.data.sectionId) {
			case "RECIPE": return "RECIPE Information";
			case "Enviromental score": return "Enviromental score";
			case "Frequency of consumption": return "Frequency of consumption";
			case "Dietary reccomandation": return "Dietary reccomandation";
			case "Carbon footprint": return "Carbon footprint";
			case "WATER FOOTPRINT": return "WATER FOOTPRINT";
			case "Fish sustainability index": return "Fish sustainability index";
			case "NUTRITIONAL INDICATORS": return "NUTRITIONAL INDICATORS";
			
			default:
				break;
		}
		return "search ingrediant";
	}
	
	public close = (): void => {
		this.activeModal.close(false);
    }
}


