import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';


@Component({
	selector: 'about-page',
	templateUrl: './about.page.html',
	styleUrls: ['about.page.scss']
})
export class AboutPage implements OnInit, AfterViewInit{
	companyId: string;
	certificate: any;
	
	constructor(
		private _router: Router,
	) {

	}


	ngAfterViewInit(): void {
		
	}

	ngOnInit() {
		
		
	}
	goToSection = (section) => {
		const queryParams = {};
		this._router.navigate([section], { queryParams: queryParams });
	}

	openSite = () => {
		window.open("https://switchdiet.eu/about-switch-project/", "_new");
	}
	
}