import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { Roles, UserProfile } from 'app/models/profile.models';
import { forkJoin, of, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import moment from 'moment'
// import { CertVerifyModal } from './cert-verifier-modal/cert-verifier.modal';
import { ModalService } from 'app/common/services/modal.service';
import { ViewportScroller } from '@angular/common';
import { CompanyService } from 'app/services/company.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LPButtonLinkType, LPSectionButtonLink, LandingPageDefinition, LandingPageSection } from 'app/models/landing-page.model';
import { LandingPageService } from 'app/services/landing-page.service';
import { Group, IngrediantService } from 'app/services/ingrediant.service';



@Component({
	selector: 'food-items-list',
	templateUrl: './food-items-list.component.html',
	styleUrls: ['food-items-list.component.scss']
})
export class FoodItemsListComponent implements OnInit, AfterViewInit{
	pageLoaded = false;
	filterText = "";
	@Output("itemSelected") itemSelected: EventEmitter<any> = new EventEmitter();
	
	constructor(
		private _router: Router,
		private _viewportScroller: ViewportScroller,
		private _translate: TranslateService,
		private _modalService: ModalService,
		private _landingPageService: LandingPageService,
		private _activatedRoute: ActivatedRoute,
		private _companyService: CompanyService,
		private _ingrediantService: IngrediantService
	) {

	}

	selectItem = (item: Group) => {
		if (!item.items || item.items.length == 0)
			this.itemSelected.emit(item);
	}
		

	ngAfterViewInit(): void {
		
	}

	data: Group[] = [];
	ngOnInit() {
		this._ingrediantService.getAll().subscribe((res) => {
			this.data = res;
			console.log("data: ", this.data);
			
			this.pageLoaded = true;

		})
		

		// console.log(this.data);
		

		// this.pageLoaded = true;
	}

	
}