import { Component, forwardRef, Input, Optional, Host, SkipSelf, Inject, Output, EventEmitter, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseInputComponent } from './base-input.component';
import { TranslateService } from '@ngx-translate/core';
import { MediaFileDescription } from 'app/services/FileProvider.service';

@Component({
    selector: 'input-file',
    templateUrl: 'input-file.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputFileComponent),
        multi: true,
    }]
})

export class InputFileComponent extends BaseInputComponent {
	@Output() onKeyUp: EventEmitter<string> = new EventEmitter<string>();
	@Input() possibileMediaTypes: string[] = [];
	@Input() notShowAlertOnFileDelete;
    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService) {
        super(controlContainer, translateService);
    }

    protected toInternalFormat(value: any): any {
        return value;
    }

    protected toExternalFormat(value: any): any {
        if (value == "") {
            return null;
        }
        return value;
    }
    public keyUp(event: any) {
        this.onKeyUp.emit(event.target.value);
    }

	updateFile(event: MediaFileDescription) {
		if (event && event.id)
			this.value = event.id;
		else
			this.value = null;
	}
}