import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataTableColumn, DataTableAction, DataTableColumnAlignment, ListTableManager } from './types';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SubscriptionLike } from 'rxjs';
import { BaseComponent } from '../base.component';
import { SortCriteria } from 'app/common/models/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormFieldDescription } from 'app/common/models/forms';
import { iconSave, iconCancel} from "app/common/utils/icons.utils"


@Component({
    selector: 'list-table-filter',
    templateUrl: './list-table-filter.component.html'
})
export class ListTableFilter implements OnInit {
	@Input() filterValue: any;
	@Input() filterFielsDescription: FormFieldDescription[] = [];
	@Output() requestFilterUpdate: EventEmitter<any> = new EventEmitter();
    
	public form: FormGroup = null;
	public iconSave = iconSave;
	public iconCancel = iconCancel;

	constructor(private _fb: FormBuilder) {
	}
	ngOnInit() {
		let group: any = {};
		this.filterFielsDescription.forEach((filter) => {
			group[""+filter.fieldName] = [null, filter.validators];
		});
		this.form = this._fb.group(group);
		if (this.filterValue) {
			this.form.patchValue(this.filterValue);
		}
	}

	requestUpdate = () => {
		this.requestFilterUpdate.emit(this.form.value);
	}

	requestReset = () => {
		this.form.reset();
		this.requestFilterUpdate.emit(this.form.value);
	}
}