import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'modal',
    templateUrl: './modal.component.html',
	styles: [`
		.modal-title {
			color: white;
		}
		.modal-text-color {
			color: white;
		}
  `]
})
export class ModalComponent {
    @Input() public headerBgColor: string = "";
    @Input() public headerFgColor: string = "";
    @Input() public title: string = "";
	@Input() public iconClass: string;
	
    @Output() public onClose: EventEmitter<any> = new EventEmitter();
    constructor() {
    }

    public dismiss = (): void => {
        this.onClose.emit();
    }

    public get headerClass() {
        return `modal-header ${this.headerBgColor}  ${this.headerFgColor}`.trim();
    }
}
