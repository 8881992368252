import { Component, Input, OnInit } from "@angular/core";
import { ModalOptions } from "app/common/models/models";
import { ModalService } from "app/common/services/modal.service";
import { iconQRCode } from "app/common/utils/icons.utils";
import { ClaimedNFT } from "app/models/profile.models";
import { CompanyService } from "app/services/company.service";
import { EntityService } from "app/services/entity.service";
import { ProductService } from "app/services/product.service";
import { URLUtils } from "app/services/url-utils";
import { SimpleQRCodeComponent } from "../modals/simple-qrcode.component";

@Component({
    selector: 'claimed-nft',
    templateUrl: './claimed-nft.component.html'
})
export class ClaimedNFTComponent implements OnInit {
	@Input() claimedNFT: ClaimedNFT;

	qrCodeIcon = iconQRCode;
	claimedDescription: any = "";

	constructor(private _modalService: ModalService, private _companyService: CompanyService, private _productService: ProductService, private _entityService: EntityService ) {

	}

	ngOnInit(): void {
		if (this.claimedNFT) {
			
			this._companyService.getCompanyByField("companyId", this.claimedNFT.domain).subscribe((result) => {
				this.claimedDescription = "NFT - " + result.name;
			});
			
		}	
	}
	private _nftUrl;
	get nftUrl() {
		if (this._nftUrl) return this._nftUrl;
		if (this.claimedNFT) {

			this._nftUrl = URLUtils.getDefaultCertPagePatternURL().replace("{companyId}", this.claimedNFT.domain).replace("{id}",this.claimedNFT.cert_id);
			
			return this._nftUrl;
		}
	}

	showCode() {
		let modalOption: ModalOptions<boolean> = {
			size: 'md'
		}
		this._modalService.show(SimpleQRCodeComponent, {url: this._nftUrl, title: "QRcode del tuo NFT"}, modalOption);
	}

}

