import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { iconCancel } from "app/common/utils/icons.utils";
import { timer } from "rxjs";

enum VERIFICATION_STEP {
	LOADING_CERT_DATA,
	HASH_CALCULATION,
	HASH_DOWLOAD,
	TERMINATE
}

@Component({
    selector: 'cert-verifier-modal',
    templateUrl: './cert-verifier.modal.html',
	styleUrls: ['cert-verifier.modal.scss']
})
export class CertVerifyModal implements OnInit {
	iconCancel = iconCancel;
	
	status: VERIFICATION_STEP = VERIFICATION_STEP.LOADING_CERT_DATA;
	
	certDataLoadError = false;
	hashCalculationError = false;
	hashDownloadError = false;

	data: {
		certificate: any,
		certSignature: any
	};

	constructor(
		private activeModal: NgbActiveModal
		// private _fb: FormBuilder,
		// private _productService: ProductService,
		// private _modalService: ModalService,
		// private _toastService: ToastService,
		// private _userProfileService: ProfileService,
		// private _entityService: EntityService,
		// private _blockChainService: BlockChainService
		) {
			
    }

	ngOnInit(): void {
		timer(2000).subscribe((result) => {
			this.status = VERIFICATION_STEP.HASH_CALCULATION;
			this.certDataLoadError = false;
			timer(2000).subscribe((res) => {
				this.status = VERIFICATION_STEP.HASH_DOWLOAD;
				timer(2000).subscribe((res) => {
					this.status = VERIFICATION_STEP.TERMINATE;
					
				})
			})
		})
	}



	public close = (): void => {
		this.activeModal.close();

    }
}
