import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { PipeModule } from 'app/pipes/pipe.module';
import { LandingPage } from './landing-page.page';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { CertVerifyModal } from './cert-verifier-modal/cert-verifier.modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


export const LandingPageRoutes: Routes = [
    { path: 'landing', component: LandingPage, canActivate: []},
    { path: 'landing/:companyId/:certId', component: LandingPage, canActivate: []},
    { path: 'landing/:companyId/:lpId/:certId', component: LandingPage, canActivate: []},
    
];

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
		BrowserModule,
        ComponentsModule,
        RouterModule.forChild(LandingPageRoutes),
		NgJsonEditorModule,
		ArchwizardModule,
		PipeModule
		,
		NgbModule
    ],
    exports: [
		CertVerifyModal
	],
    declarations: [
		LandingPage,
		CertVerifyModal
		// UnautorizedPage
    ],
    providers: [],
	entryComponents: [CertVerifyModal]
})
export class LandingPageModule { }