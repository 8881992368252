import { Component, forwardRef, Input, Optional, Host, SkipSelf, Inject, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseInputComponent } from './base-input.component';
import { TranslateService } from '@ngx-translate/core';
import { JsonEditorOptions } from 'ang-jsoneditor';

@Component({
    selector: 'input-json',
    templateUrl: './input-json.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputJSONComponent),
        multi: true,
    }]
})

export class InputJSONComponent extends BaseInputComponent {
    @Output() onKeyUp: EventEmitter<string> = new EventEmitter<string>();
    @Input() rows: number = 1;
    @Input() maxLength: number = Number.MAX_SAFE_INTEGER;
	editorOptions: JsonEditorOptions;
    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService) {
        super(controlContainer, translateService);
		this.editorOptions = new JsonEditorOptions()
		this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
	}
	

    protected toInternalFormat(value: string): string {
		if (value)
			return JSON.parse(value);
		return "";
        // return value;
    }

    protected toExternalFormat(value: string): string {
        if (value == "") {
            return null;
        }
        // return value;
		return JSON.stringify(value);
    }
    public keyUp(event: any) { // without type info
        this.onKeyUp.emit(event.target.value);
    }
}
