import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Roles } from "app/models/profile.models";
import { Observable } from "rxjs";
import { ProfileService } from "./profile.service";

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private _profileService: ProfileService,
		private _router: Router) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		// if (this.authService.hasValidIdToken()) {
		// 	return true;
		// }

		// this.authService.login();
		// return false;
			// return true;
		if (this._profileService.isInRole(Roles.DASHBOARD_USER)) {
			return true;
		}
		
		// // Redirect to the login page
		return this._router.parseUrl('/unautorized-page');
		// return true;
	}

	
}            