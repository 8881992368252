import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Roles } from 'app/models/profile.models';
import { ProfileService } from 'app/services/profile.service';
import { environment } from 'environments/environment';

@Component({
	selector: 'unautorized-page',
	templateUrl: './unautorized-page.page.html'
})
export class UnautorizedPage implements OnInit {
	email = environment.domainConfig.emailContact;
	constructor(
		private _router: Router,
		private _userProfileService: ProfileService,
	) {

	}
	ngOnInit() {
		if (this._userProfileService.isInRole(Roles.DASHBOARD_USER)) {
			this._router.navigate(["/user-profile"], { });
		}
	}

}