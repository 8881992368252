import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';

import { iconCancel, iconSave } from "app/common/utils/icons.utils";

import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { ProfileService } from 'app/services/profile.service';
import { ModalService } from 'app/common/services/modal.service';
import { Company, EntityDefinition, GroupDefinition, Product } from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'app/services/product.service';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ToastService } from 'app/common/services/toasts.service';
import { URLUtils } from 'app/services/url-utils';

@Component({
    selector: 'ingrediant-search-modal',
    templateUrl: './ingrediant-search-modal.component.html'
})
export class IngrediantSearchModalComponent implements OnInit {
	data: {
	};
	dataLoaded: boolean = false;

	ngOnInit() {
		
		this.dataLoaded = true;
	}

    constructor(
		private activeModal: NgbActiveModal,
		private _toastService: ToastService,
		private _fb: FormBuilder,
		private _productService: ProductService,
		private _userProfileService: ProfileService,
		private _modalService: ModalService) {
			
    }

	
	public get title() {
		return "search ingrediant";
	}
	
	onItemSelected = (item) => {
		this.activeModal.close((item.id)? item: { id: item });
	}
    
	public close = (): void => {
		this.activeModal.close(false);
    }
}


