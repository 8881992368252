import { Component, forwardRef, Optional, Host, SkipSelf, Input, Inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseInputComponent } from './base-input.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'input-slide',
    templateUrl: './input-slide.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputSlideComponent),
        multi: true
    }]
})
export class InputSlideComponent extends BaseInputComponent<boolean, boolean> {
    @Input() public horizontal: boolean = true;
    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService) {
        super(controlContainer, translateService);
    }


    protected toInternalFormat(value: boolean): boolean {
        return value;
    }
    protected toExternalFormat(value: boolean): boolean {
        return value;
    }
}
