import { Component, forwardRef, Optional, Host, SkipSelf, Inject, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseInputComponent } from './base-input.component';
import { TranslateService } from '@ngx-translate/core';
import { CultureService } from '../../services/culture.service';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';

@Component({
    selector: 'input-decimal',
    templateUrl: './input-decimal.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputDecimalComponent), multi: true }
    ]
})

export class InputDecimalComponent extends BaseInputComponent<number> {
    @Input() maxLength: number = Number.MAX_SAFE_INTEGER;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService, private _cultureService: CultureService) {
        super(controlContainer, translateService);
    }
    protected toInternalFormat(value: number): string {
        if (value != null) {
            if (value != 0) {
                let newValue = value.toString();
                return newValue.replaceAll(".", this.decimalSeparator);
            }
            return value.toString();
        }
    }

    protected toExternalFormat(value: string): number {
        let cleanValue = value != null && value != "" ? value.trim() : null;
        if (cleanValue != null) {
            cleanValue = cleanValue.replaceAll(this.thousandSeparator, "");
            cleanValue = cleanValue.replaceAll(this.decimalSeparator, ".");
        }
        return cleanValue != null ? parseFloat(cleanValue) : null;
    }
    private get decimalSeparator() {
        let cultureCode = this._cultureService.getCurrentCulture().code;
        return getLocaleNumberSymbol(cultureCode, NumberSymbol.Decimal)
    }
    private get thousandSeparator() {
        let cultureCode = this._cultureService.getCurrentCulture().code;
        return getLocaleNumberSymbol(cultureCode, NumberSymbol.Group)
    }

}
