import { Component, forwardRef, Input, Optional, Host, SkipSelf } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseInputComponent } from './base-input.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'input-password',
    templateUrl: './input-password.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputPasswordComponent), multi: true }
    ]
})

export class InputPasswordComponent extends BaseInputComponent {
    @Input() rows: number = 1;
    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService) {
        super(controlContainer, translateService);
    }

    protected toInternalFormat(value: string): string {
        return value;
    }

    protected toExternalFormat(value: string): string {
        if (value == "") {
            return null;
        }
        return value;
    }

}
