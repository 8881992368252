import { Injectable } from "@angular/core";
import { ToastItem, ToastTypes } from "../models/models";

@Injectable()
export class ToastService {
    public toasts: ToastItem[] = [];

    public showSuccess(message: string) {
        this.toasts.push(new ToastItem(message, ToastTypes.Success));
    }
    public showInfo(message: string) {
        this.toasts.push(new ToastItem(message, ToastTypes.Info));
    }
    public showDanger(message: string) {
        this.toasts.push(new ToastItem(message, ToastTypes.Danger));
    }

    public remove = (toast: ToastItem): void => {
        this.toasts = [...this.toasts.filter(t => t !== toast)];
    }
}