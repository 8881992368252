import { Component, forwardRef, Input, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { iconMicrophone, iconPlay, iconStop } from 'app/common/utils/icons.utils';
import { DiDServiceAPI } from 'app/services/d-id-streaming-api.service';
import { ChatGPT } from 'app/services/open-ai-api.service';
enum AVATAR_STATUS {
	STOPPED, STARTED, RECORDING, WAITING
}

declare var MediaRecorder: any;

@Component({
    selector: 'talking-avatar',
    templateUrl: './talking-avatar.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TalkingAvatarComponent), multi: true }
    ],
    styleUrls: ["./talking-avatar.component.scss"]
})
export class TalkingAvatarComponent implements OnInit, OnDestroy {
	private avatarStatus: AVATAR_STATUS = AVATAR_STATUS.STOPPED;
	buttonIcon = iconPlay
	@Input() avatarLogo: string;
	@ViewChild('mediaElement') mediaElement: ElementRef;
	
	// const assistant_id= "asst_XQIzDUr2MfEDneZGF2d68jBf"; // Olio di Roma
	private assistant_id= "asst_JptHZZYQwvWrGlVzRQy1ah6M"; // biolu

	private audioContext: AudioContext = null;
	private mediaRecorder: any;
	private chunks = [];

	/**
	 * Configurazione Olio di Roma
	 */
	textSubject = "";
	// const textSubject = "sull'Olio di Roma";
	welcomeMessageText = "CIAO";
	// const welcomeMessageText = "CIAO, SONO OLIVIO ROMANO, IL TUO ASSISTENTE VIRTUALE SULL'OLIO DI ROMA. PREMI IL PULSANTE PLAY SUL TUO SMARTPHONE E PARLA CON ME, SONO A TUA DISPOSIZIONE. PREMI STOP QUANDO AVRAI TERMINATO CON LA TUA DOMANDA";
	noResponseMessage = "Mi dispiace ma non sono riuscito ad elaborare una risposta. Prova a riformulare la domanda."
	// document.getElementById("logo-sinistro").innerHTML = "<img src='asset/olio-di-roma-logo-share.png'>";
	// document.getElementById("logo-destro").innerHTML = "<img src='asset/Logo_it_IGP_4c.svg.png'>";
	maxWordNumbers = 30;


	finitudeRequest = `. Fornisci una risposta che contiene al massimo ${this.maxWordNumbers} parole senza citare alcuna fonte.`;
	// const finitudeRequest = ". Please provide a short answer without citing any sources."


	private _chatGPT: ChatGPT;
	private _dId: DiDServiceAPI;
	// private config ={
	// 	// "face": {
	// 	// 	"top_left": [
	// 	// 		0,
	// 	// 		0
	// 	// 	],
	// 	// 	"size": 512
	// 	// },
	// 	// "config": {
	// 	// 	"motion_factor": 0,
	// 	// 	"align_expand_factor": 0,
	// 	// 	"stitch": true
	// 	// },
	// 	// source_url: "s3://d-id-create-images-prod/google-oauth2|107778935542107823025/upl_sACg6Gk3swImGmI6-YaJt/image.png" // OLIVIO
	// 	// source_url: "s3://d-id-create-images-prod/google-oauth2|107778935542107823025/upl_PvqBMme6pUqzbuVFbeO-c/image.png" // Nadia Savino
	// 	// source_url: "https://create-images-results.d-id.com/google-oauth2%7C107778935542107823025/upl_OPafZ_eZ3jU2NAfdn1zNB/image.png"
	// 	source_url: "https://create-images-results.d-id.com/google-oauth2%7C107778935542107823025/upl_hRGDRDauLciHbBWybwFEz/image.png"
	// 	// source_url: 'https://d-id-public-bucket.s3.amazonaws.com/or-roman.jpg',
	// 	// source_url: "https://dashboard-demo.posti.world/persona1.jpg",
	// 	// source_url: "https://dashboard-demo.posti.world/olivio.jpeg",
	// 	// source_url: "https://cdn.studenti.stbm.it/images/2017/01/10/gioconda-orig.jpeg"
	// 	// source_url: "https://dashboard-demo.posti.world/olivio-face.jpg",
	// 	// driver_url: "bank://classics"
	// };


	// DID_API = {
	// 	"key": 
	// 	"url": 
	// 	"service": "talks"
	// }
	private configuration = {
		// var voice_id = "it-IT-DiegoNeural";
		voice_id: "it-IT-FabiolaNeural",
		apiKey: "dm1hcmV0dG9AZ21haWwuY29t:s-enBPDKiOUBSUgzocAdH",
		serviceUrl: "https://api.d-id.com",
		service: "talks",
		silentUrl: "https://dashboard-demo.posti.world/lp-demo-biolu-v1.0.3/asset/untitled_video-savino.mp4",
		sessionConfig: {
			source_url: "https://create-images-results.d-id.com/google-oauth2%7C107778935542107823025/upl_hRGDRDauLciHbBWybwFEz/image.png"
		}
	};


	ngOnDestroy(): void {
	}
	ngOnInit(): void {
		this._chatGPT = new ChatGPT();
		this._dId = new DiDServiceAPI();
	}

	playOrStop = () => {
		switch (this.avatarStatus) {
			case AVATAR_STATUS.STOPPED:
				// start avatar
				this.initAudio();
				
				break;
			case AVATAR_STATUS.STARTED:
				this.startRecording();
				break;
			case AVATAR_STATUS.RECORDING:
				this.stopRecording();
				break;
			default:
				break;
		}
		
	}

	private setStarted = () => {
		this.avatarStatus = AVATAR_STATUS.STARTED;
		this.buttonIcon = iconMicrophone;
	}

	private startGPTAndSession = () => {
		try {
			this._chatGPT.startThread();
		} catch (err) {
			return;
		}
		this.configuration.sessionConfig.source_url = this.avatarLogo;
		this._dId.createNewSession(this.configuration, this.mediaElement.nativeElement, "CIao A tutti").then(() => {
			this.setStarted();
		});
		// let videoWrap = document.querySelector("#videoWrap");
		// videoWrap.classList.remove("video-wrap-hide");
		// videoWrap.classList.add("video-wrap-show");


		// let startAppContainer = document.querySelector("#startAppContainer");
		// startAppContainer.classList.add("hidden-button");
		// recBtn.classList.remove("hidden-button");
	}

	initAudio = () => {
		console.log("audio init...", navigator)
		if (!this.audioContext)
			this.audioContext = new AudioContext();

		if (this.audioContext.state === 'suspended') {
			this.audioContext.resume().then((r) => {
				navigator.mediaDevices.getUserMedia({ audio: true }).then((media) => {
					console.log("Ecco 2 ", media);
					this.startGPTAndSession()
				});
			})
		}
		else {
			navigator.mediaDevices.getUserMedia({ audio: true }).then((media) => {
				console.log("Ecco", media);
				this.startGPTAndSession()
			});
		}
	}


	private setRecording = () => {
		this.isRecording = true;
		this.avatarStatus = AVATAR_STATUS.RECORDING;
		this.buttonIcon = iconStop;
	}
	private setStopRecording = () => {
		this.isRecording = false;
		this.avatarStatus = AVATAR_STATUS.STARTED;
		this.buttonIcon = iconMicrophone;
	}

	isRecording = false;
	canRecording = true;

	startRecording = async () => {
		if (this.canRecording) {
			console.log("starting recording");
			if (this.audioContext.state === 'suspended') {
				await this.audioContext.resume();
			}
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			this.mediaRecorder = new MediaRecorder(stream);
			this.mediaRecorder.ondataavailable = (event) => this.chunks.push(event.data);
			this.mediaRecorder.start();
			this.setRecording();
		}
		else {
			console.log("unable to recording..");
		}
	}

	stopRecording = async () => {
		if (this.mediaRecorder) {
			this.mediaRecorder.onstop = async () => {
				const audioData = await new Blob(this.chunks).arrayBuffer();
				const audioBuffer = await this.audioContext.decodeAudioData(audioData);
				const wavBlob = this.bufferToWave(audioBuffer, audioBuffer.length);

				await this._dId.talk("Attendi qualche secondo che sto elaborando la risposta");
				// appendMessage("", true);
				let message = await this._chatGPT.convertAudioIntoText(wavBlob);
				//console.log("message is: ", message);
				if (message.text) {
					// appendMessage(message.text, true);
					// appendMessage("", false);
					this.sendQuestion(message.text + this.finitudeRequest);

				}
				this.chunks = [];
			};

			this.mediaRecorder.stop();
			this.setStopRecording();
		}
	}


	private bufferToWave = (abuffer, len) => {
		let numOfChan = abuffer.numberOfChannels,
			length = len * numOfChan * 2 + 44,
			buffer = new ArrayBuffer(length),
			view = new DataView(buffer),
			channels = [],
			i, sample,
			offset = 0,
			pos = 0;

		// write WAVE header
		setUint32(0x46464952);                         // "RIFF"
		setUint32(length - 8);                         // file length - 8
		setUint32(0x45564157);                         // "WAVE"

		setUint32(0x20746d66);                         // "fmt " chunk
		setUint32(16);                                 // length = 16
		setUint16(1);                                  // PCM (uncompressed)
		setUint16(numOfChan);
		setUint32(abuffer.sampleRate);
		setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
		setUint16(numOfChan * 2);                      // block-align
		setUint16(16);                                 // 16-bit (hardcoded in this demo)

		setUint32(0x61746164);                         // "data" - chunk
		setUint32(length - pos - 8);                   // chunk length

		// write interleaved data
		for (i = 0; i < abuffer.numberOfChannels; i++)
			channels.push(abuffer.getChannelData(i));

		while (pos < length) {
			for (i = 0; i < numOfChan; i++) {             // interleave channels
				sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
				sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
				view.setInt16(pos, sample, true);          // write 16-bit sample
				pos += 2;
			}
			offset++                                     // next source sample
		}

		// create Blob
		return new Blob([buffer], { type: "audio/wav" });

		function setUint16(data) {
			view.setUint16(pos, data, true);
			pos += 2;
		}

		function setUint32(data) {
			view.setUint32(pos, data, true);
			pos += 4;
		}
	}



	waitingResponse = false;
	private sendQuestion = async (request) => {
		this.waitingResponse = true;
		// request = _chatGPT.cleanText(request);
		if (request) {
			let messageResult = await this._chatGPT.addMessageToThread(request);
			// await talk( 	"Attendi qualche secondo. devo elaborare la risposta alla tua domanda: " + request);
			await this._chatGPT.runThread(this.assistant_id);
			this.tryStatus(59);
		}
	}

	private tryStatus = async (step) => {
		if (step > 0) {
			setTimeout(() => {
				this._chatGPT.retriveRunStatus().then(async (status) => {
					console.log("tentativo " + step + ". stato: " + status);
					if (status == "completed") {
						this.tryGetMessage();
					}
					else if (status == 'failed') {
						// this.appendMessage(this.noResponseMessage, false);

						await this._dId.talk(this.noResponseMessage);
						this.waitingResponse = false;
					}
					else {
						if (step%10 == 0) 
						await this._dId.talk("Attendi qualche secondo. Sto elaborando la risposta.");
						this.tryStatus(step-1);
					}
				})
			}, 2000);
		}
		else {
			// appendMessage(noResponseMessage, false);
			await this._dId.talk( this.noResponseMessage);
			this.waitingResponse = false;
		}
	}


	private tryGetMessage = async () => {
		let messages = await this._chatGPT.getMessages();
		
		if (messages && messages.data && messages.data[0] && messages.data[0].content && messages.data[0].content[0] && messages.data[0].content[0].text) {
			let text = await this._chatGPT.cleanText(messages.data[0].content[0].text.value);
			// appendMessage(text, false);
			await this._dId.talk( text + ".                 Vuoi chiedrmi qualcos'altro "+this.textSubject+"?");
			// this.responses.push({user: "Assistente", message: messages.data[0].content[0].text.value});
		}
		else {
			// appendMessage(noResponseMessage, false);
			await this._dId.talk( this.noResponseMessage)
		}
		this.waitingResponse = false;
	}


}
