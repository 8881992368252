import { Injectable } from "@angular/core";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { weekdays } from "moment";

@Injectable()
export class DatepickerI18nService extends NgbDatepickerI18n {
    private _localizeItems: Record<string, LocalizeItem> = {
        'en': english,
        'it': italian,
        'es': spanish
    };
    private _cultureCode: string = null;

    public changeLocale = (cultureCode: string): void => {
        this._cultureCode = cultureCode;
    }

    public getWeekdayShortName = (weekday: number): string => {
        return this._localizeItems[this._cultureCode].weekdayNames[weekday - 1].substr(0, 2);
    }
    public getMonthShortName = (month: number): string => {
        return this._localizeItems[this._cultureCode].monthNames[month - 1].substr(0, 3);
    }
    public getMonthFullName = (month: number): string => {
        return this.getMonthShortName(month);
    }
    public getDayAriaLabel = (date: NgbDateStruct): string => {
        return `${date.day}-${date.month}-${date.year}`;
    }
}

class LocalizeItem {
    public monthNames: string[] = [];
    public weekdayNames: string[] = []
}

const english: LocalizeItem = {
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    weekdayNames: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
}
const italian: LocalizeItem = {
    monthNames: ["Gennaio", "Febbraio", "Marzo", "April", "Maggoi", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
    weekdayNames: ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"]
}
const spanish: LocalizeItem = {
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    weekdayNames: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
}