
export const iconTrash = "ft-trash-2";
export const iconEdit = "ft-edit";
export const iconSave = "ft-check";
export const iconRefresh = "ft-repeat";
export const iconCancel = "ft-x";
export const iconAdd = "ft-plus";
export const iconPublish = "icon-cloud-upload";
export const iconShare = "icon-share";
export const iconUserAdd = "fas fa-user-plus";
export const iconUserRemove = "fas fa-user-times";
export const iconMoreUsers = "fas fa-user-friends";
export const iconElementView = "fas fa-eye";
export const iconPropertiesEdit = "fas fa-wrench";
export const iconMap = "fas fa-map-marked-alt";
export const iconQRCode = "fas fa-qrcode";
export const iconBeacon = "fas fa-tablets";
export const iconSpaces = "fas fa-landmark";
export const iconMonument = "fas fa-monument";
export const iconPolygon = "fas fa-draw-polygon";
export const iconCenter = "fas fa-map-marker";
export const iconDownload = "fas fa-cloud-download-alt";
export const iconSetting = "fas fa-cogs";
export const iconLink = "fas fa-link";
export const iconCertPage = "fas fa-shield-alt";
export const iconUserAvatar = "fas fa-user-circle";
export const iconArrowBack = "fas fa-arrow-left";
export const iconUserShield = "fas fa-user-shield";
export const iconPlay = "fas fa-play";
export const iconStop = "fas fa-stop";
export const iconMicrophone = "fas fa-microphone";

export const iconCert = "cert-icon";