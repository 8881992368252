import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SelectListitem } from "app/common/models/models";
import { FieldDefinition, FieldType } from "app/models/entities.model";

@Component({
    selector: 'entity-field',
    templateUrl: './entity-field.component.html'
})
export class EntityField implements OnInit {
	loaded: boolean = false;
	@Input() fieldDef: FieldDefinition;
	@Input() formGroup: FormGroup;
	possibleValues: SelectListitem[] = [];
	possibileMediaTypes;

	ngOnInit(): void {
		if (this.fieldDef.fieldType == FieldType.MULTIPLE_SELECT || this.fieldDef.fieldType == FieldType.SINGLE_SELECT) {
			if (this.fieldDef.possibleValues) {
				this.possibleValues = this.fieldDef.possibleValues.map((value) => { return new SelectListitem(value,value)});
			}
		}
		if (this.fieldDef.fieldType == FieldType.IMAGE) {
			this.possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
		}
		this.loaded = true;
	}
}