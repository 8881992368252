import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { PipeModule } from 'app/pipes/pipe.module';
import { UserListPage } from './user-list/user-list.page';
import { EditUserRoleModal } from './edit-user/edit-user-roles.page';
import { AddACRToEntityModal } from './acr-components/add-acr-to-entity.modal';
import { UserEnablingList } from './user-enabling/user-enabling.page';
// import { EditBaseProductPage } from './edit-base-product/edit-base-product.page';



export const AdminRoutes: Routes = [
    { path: 'admin/users-list', component: UserListPage, canActivate: []},
    { path: 'admin/disabled-users-list', component: UserEnablingList, canActivate: []},
    // { path: 'admin/edit-base-entity', component: EditBaseProductPage, canActivate: []},
    // { path: 'admin/edit-base-entity/:id', component: EditBaseProductPage, canActivate: []},
	
];


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule.forChild(AdminRoutes),
		NgJsonEditorModule,
		ArchwizardModule,
		PipeModule
    ],
    exports: [
		EditUserRoleModal,
		AddACRToEntityModal
	],
    declarations: [
		UserListPage,
		// EditBaseProductPage,
		EditUserRoleModal,
		AddACRToEntityModal,
		UserEnablingList
    ],
    providers: [],
	entryComponents: [
		EditUserRoleModal,
		AddACRToEntityModal
	]
})
export class AdminModule { }
