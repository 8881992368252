import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable} from 'rxjs';

import { iconCancel, iconSave, iconTrash } from "app/common/utils/icons.utils";

import { ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ModalService } from 'app/common/services/modal.service';
import { Company, EntityDefinition, FieldType, Product} from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'app/services/product.service';
import { ToastService } from 'app/common/services/toasts.service';
import { URLUtils } from 'app/services/url-utils';

import { EntityService } from 'app/services/entity.service';
import { DataTableAction, DataTableColumn, ListTableManager } from 'app/common/components/table/types';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableUtils } from 'app/common/utils/data-table.utils';

@Component({
    selector: 'entities-list-modal',
    templateUrl: './entities-list.modal.html'
})
export class ModalEntityList extends BaseListTablePage<any> {
	numberOfAvailableItems: number = 0;
	dataLoaded;
	public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];

	private fieldToShow = [];
	
	iconCancel = iconCancel;
	iconSave = iconSave;
	iconDelete = iconTrash;
	
	data: {
		entityId: string;
		entityDef: EntityDefinition;
		product: Product,
		companyId: string
	};


	constructor(
		_activatedRoute: ActivatedRoute,
		private activeModal: NgbActiveModal,
		private _fb: FormBuilder,
		private _productService: ProductService,
		private _modalService: ModalService,
		private _toastService: ToastService,
		private _entityService: EntityService
		) {
			super(_activatedRoute,false);
    }


	templateNFT: EntityDefinition = {
		entityId: "nft",
		name: "NFT",
		certVerifyerUrl: environment.domainConfig.defaultCertPagePatternURL,
		groups: [
			{
				isArray: false,
				fields: [
					{
						fieldId: "descrizione",
						fieldLabel: "descrizione",
						fieldToCertify: true,
						fieldType: FieldType.DESCRIPTION,
						hidden: false,
						validators: []
					}
				],
				groupId: "nft_gid",
				groupLabel: "Informazioni specifiche del NFT"
			}
		],
		hasLinkedNFT: false,
		landingPageDefinitionId: ""

	}
	protected getDataTableManager = (): ListTableManager<any> => {
        return new ListTableManager(this.getEntities)
    }

	private getEntities = (): Observable<any[]> => {
		return new Observable(observer => {
				
				this.fieldToShow = ["id"];
				this.templateNFT.groups.forEach((g) => {
					g.fields.forEach((f) => {
						if (!f.hidden) {
							this.fieldToShow.push(g.groupLabel+"."+f.fieldLabel);
						}
					})
				})
				// this._entityService.getAll(this.data.companyId,this.data.product.productId,this.data.entityDef.entityId + ".nft")

				this._entityService.getAllByField(this.data.companyId,this.data.product.productId,this.data.entityDef.entityId + ".nft", "refEntityId", this.data.entityId)
				.pipe(
					catchError((error) => {
						// let toShow: any[] = [];
						// observer.next(toShow);
						return error;
					})
				).subscribe(
					(result) => {

					let toShow: any[] = [];
					if (result && result.forEach) {
						this.numberOfAvailableItems = result.length;
						result.forEach((element) => {
							let newElement = new Object();
	
							Object.assign(newElement, element);
							for (let key in element) {
								if (key != 'lastUpdateDate' && key != 'domains' && key != 'id') {
									for (let innerKey in element[key]) {
										newElement[innerKey] = element[key][innerKey];
									}
								}
							}
							toShow.push(newElement);
						})
					}
					observer.next(toShow);
					observer.complete();
				},
				(error) => {
					
				}
				)
		});

		
	}

	initialize = (): void => {
		console.log("data: ", this.data);
		
		this.createColumms();
		this.createActions();
		this.dataLoaded = true;
		this.dataTableManager.startReload();
	}
	public close = (): void => {
		this.activeModal.close(null);
    }

    private createColumms = (): void => {
		this.tableColumns.push(DataTableUtils.createStringColumn("id", "Identificativo", false));
		

		this.templateNFT.groups.forEach((group) => {
			if (!group.isArray) {
				group.fields.forEach((field) => {
					if (!field.hidden) {
						switch (field.fieldType) {
							case FieldType.DATE:
								this.tableColumns.push(DataTableUtils.createDateColumn(field.fieldLabel, field.fieldLabel, false));
								break;
							// case FieldType.DATETIME:
							// 	this.tableColumns.push(DataTableUtils.createDateTimeColumn(field.fieldLabel, field.fieldLabel, false));
							// 	break;
							case FieldType.DECIMAL:
							case FieldType.INTEGER:
								this.tableColumns.push(DataTableUtils.createNumberColumn(field.fieldLabel, field.fieldLabel, (field.fieldType == FieldType.DECIMAL)? 2:0, false))
								break;
							case FieldType.DESCRIPTION:
							case FieldType.SINGLE_SELECT:
							case FieldType.STRING:
								this.tableColumns.push(DataTableUtils.createStringColumn(field.fieldLabel, field.fieldLabel, false));
								break;
							case FieldType.MULTIPLE_SELECT:
								this.tableColumns.push(DataTableUtils.createArrayColumn(field.fieldLabel, field.fieldLabel, null, false));
								break;
							default:
								break;
						}
					}
				})
			}
			
		})
		
    }

    private createActions = (): void => {
        let button = new DataTableAction();
		button.funcToInvoke = this.showBlockchainCert;
		button.label = "Visualizza certificato in blockchain";
		button.icon = "fas fa-shield-alt";
		button.enableFunc =  (row) => {return row.certified};
		this.tableActions.push(button);
    }
	

	public showBlockchainCert = (row: any): void => {
		let url = URLUtils.getCertPageURL(
			<Company>{companyId: this.data.companyId},
			this.data.product,
			<EntityDefinition>{entityId: this.data.entityId},
			row);
		
		window.open(url, "_blank");
	}

	title = "";
}


