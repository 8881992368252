import { Injectable } from "@angular/core";
import { UserProfile } from "app/models/profile.models";
import { environment } from "environments/environment";
import moment from "moment";
import { Observable, of } from "rxjs";
import { BlockChainService } from "./blockchain.service";
import { HttpService } from "./http.service";
import { URLUtils } from "./url-utils";
import { catchError, map } from "rxjs/operators";
import { LandingPageDefinition } from "app/models/landing-page.model";


@Injectable({
	providedIn: 'root'
})
export class LandingPageService {
	private baseUrl = environment.services.apiBasePath;
	constructor(private _httpService: HttpService, private _chainService: BlockChainService) {
	}
	public getAll = (domain: string, fieldsName?: string[]): Observable<LandingPageDefinition[]> => {
		let queryParams = ""
		if (fieldsName && fieldsName.length>0) {
			queryParams = "?";
			fieldsName.forEach(field => {
				queryParams += "fields="+field+ "&";
			})
		}
		return this._httpService.get(this.baseUrl + "/" + domain + "/new_landing_page_def" + queryParams, "json");
	}
	public get = (domain: string, id: any): Observable<LandingPageDefinition> => {
		let url = this.baseUrl + "/" + domain + "/new_landing_page_def/" + id;
		return this._httpService.get(url);
	}
	public getByField = (domain: string, fieldId: string, value: any): Observable<LandingPageDefinition> => {
		let url = this.baseUrl + "/" + domain + "/new_landing_page_def/" + fieldId + "/" + value;
		return this._httpService.get(url);
	}
	public delete = (domain: string, id: any): Observable<LandingPageDefinition> => {
		let url = this.baseUrl + "/" + domain + "/new_landing_page_def/" + id;
		return this._httpService.delete(url);
	}
	public saveOrUpdate = (domain: string, product: LandingPageDefinition, toNotify: boolean = false): Observable<LandingPageDefinition> => {
		let url = this.baseUrl + "/" + domain + "/new_landing_page_def";
		if (product.id)
			return this._httpService.put(url + "/"+product.id + "?notify="+toNotify, product,"json");
		return this._httpService.post(url + "?notify="+toNotify,product,"json");
	}
	
	public getCertificate = (domain: string, id: any): Observable<any> => {
		let url = environment.services.certDataBaseUrl.replace("{{companyId}}",domain).replace("{{certId}}",id);
		return this._httpService.get(url).pipe(
			catchError((error) => {
				return of(null);
			})
		);
	}
}