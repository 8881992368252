import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filter'
})
export class FilterPipe implements PipeTransform {
	transform(items: any[], searchTerm: string, labelKeys?: string[]): any {
		if (!items) {
			return null;
		}
		if (!searchTerm || searchTerm.trim() == "") return items;
		let search = searchTerm.toLowerCase()
		return items.filter(
			item => {

				if (labelKeys) {
					for (let i = 0; i < labelKeys.length; i++) {
						if ((""+item[labelKeys[i]]).toLowerCase().includes(search) === true) return true;
					}
				}
				return false;
			}
		);
	}
}
