import { Component, forwardRef, Input, Optional, Host, SkipSelf, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseInputComponent } from './base-input.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { debounceTime, catchError, tap, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'input-autocomplete',
    templateUrl: './input-autocomplete.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputAutocompleteComponent), multi: true }
    ],
    styleUrls: ["./input-autocomplete.component.scss"]
})

export class InputAutocompleteComponent extends BaseInputComponent implements OnDestroy {
    @Input() public loader: (value: string) => Observable<any[]>;
    @Input() public itemKey: string = "id";
    @Input() public itemLabel: string = "label";
    @Input() public minChars: number = 3;
    @Input() public actionItems: AutocompleteActionItem[] = [];
    @Input() public set initialItem(value: any) {
        if (value != null) {
            this.initialText = value[this.itemLabel];
        }
    }
    @Output() public onSelectedItem: EventEmitter<any> = new EventEmitter<any>()

    public loading: boolean = false;
    public initialText: string = null;
    public get visibleActionItems() {
        return this.actionItems.filter(f => f.visible == true);
    }

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService) {
        super(controlContainer, translateService);
    }
    ngOnDestroy(): void {
    }

    protected toInternalFormat(value: string): string {
        return value;
    }

    protected toExternalFormat(value: string): string {
        return value;
    }


    public onTextChange = (value: string): void => {
        if (this.value != null) {
            this.value = null;
        }
    }

    public formatter = (result: any): string => {
        return result[this.itemLabel];
    }

    public search = (text$: Observable<string>) => {
        let a = this;
        return text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() =>
                this.loading = true
            ),
            switchMap(term => {
                if ((term || "").length < this.minChars) {
                    return of([]);
                }
                return this.loader(term).pipe(tap(() => { }),
                    catchError(() => {
                        return of([]);
                    }))
            }),
            tap(() =>
                this.loading = false
            )
        )
    }

    public onSelect = (value: NgbTypeaheadSelectItemEvent): void => {
        this.value = value && value.item ? value.item[this.itemKey] : null;
        this.onSelectedItem.emit(value);
    }
}

export class AutocompleteActionItem {
    public label: string = null;
    public callback: () => void = null;
    public visible: boolean = true;
}
