import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import { FilterPipe } from './filter.pipe';
import { SearchPipe } from './search.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { DatePipe, DateTimePipe, FullDatePipe, SmallTimePipe, TimePipe } from './date.pipe';
import { FilterIngredientPipe } from './ingredients-filter.pipe';

@NgModule({
  declarations:[FilterPipe, SearchPipe, ShortNamePipe,
	DatePipe,
	FullDatePipe,
	DateTimePipe,
	TimePipe,
	SmallTimePipe,
	FilterIngredientPipe
],
  imports:[CommonModule],
  exports:[FilterPipe, SearchPipe, ShortNamePipe,
	DatePipe,
	FullDatePipe,
	DateTimePipe,
	TimePipe,
	SmallTimePipe,
	FilterIngredientPipe
	]
})

export class PipeModule{}
