import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { iconAdd, iconCancel, iconSave } from "app/common/utils/icons.utils";

import { ProfileService } from 'app/services/profile.service';
import { ModalService } from 'app/common/services/modal.service';
import { Company, EntityDefinition, Product } from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ToastService } from 'app/common/services/toasts.service';
import { v4 as uuidv4 } from 'uuid';
import { SelectListitem } from 'app/common/models/models';
import { LandingPageDefinition, LandingPageSection } from 'app/models/landing-page.model';
import { LandingPageService } from 'app/services/landing-page.service';

@Component({
    selector: 'field-selector-modal',
    templateUrl: './field-selector-modal.page.html'
})
export class FieldSelectorModalForm implements OnInit {
	iconCancel = iconCancel;
	iconAdd = iconAdd;
	public entityForm: FormGroup = null;	
	data: {
		placeholders: SelectListitem[]
	};
	

	ngOnInit() {
		this.initForm();
		
	}

    constructor(
		private activeModal: NgbActiveModal,
		private _fb: FormBuilder) {
			
    }

	
	public get title() {
		return "Seleziona un campo del certificato";
	}


	private initForm = (initData?: LandingPageDefinition) => {
		 let entityFormDef: any = {
			placeholder: [null, Validators.required]
		};

		this.entityForm = this._fb.group(entityFormDef);
	}

	
	public get canSaveForm(): boolean {
		return this.entityForm.valid;
	}
    public save() {
		console.log("saving: ", this.entityForm.value);
		this.activeModal.close(this.entityForm.value.placeholder);
    }
	public close = (): void => {
		this.activeModal.close(null);
    }
}


