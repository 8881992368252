import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { iconAdd, iconCancel, iconSave } from "app/common/utils/icons.utils";

import { ProfileService } from 'app/services/profile.service';
import { ModalService } from 'app/common/services/modal.service';
import { CertificatePageDefinition, CertificatePageSection, Company, EntityDefinition, GroupDefinition, Product } from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ToastService } from 'app/common/services/toasts.service';
import { v4 as uuidv4 } from 'uuid';
import { SelectListitem } from 'app/common/models/models';
import { CertificatePageService } from 'app/services/certificate-page.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'certificate-editor',
    templateUrl: './certificate-page-editor-modal.page.html'
})
export class CertificateModalForm implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconCancel = iconCancel;
	iconSave = iconSave;
	iconAdd = iconAdd;
	public entityForm: FormGroup = null;	
	public groups: CertificatePageSection[] = [];
	public groupValidity: boolean[] = [];
	data: {
		entity: EntityDefinition,
		product: Product,
		company: Company,
		userProfile: UserProfile,
		pageId: any
		// certificateDefinition: CertificatePageDefinition;
	};
	dataLoaded: boolean = false;
	certFields: SelectListitem[] = [
	];
	
	certificateDefinition: CertificatePageDefinition;


	ngOnInit() {
		console.log("data: ", this.data);
		if (this.data && this.data.entity) {
			this.data.entity.groups.forEach((group) => {
				group.fields.forEach((field) => {
					if (field.fieldToCertify)
						this.certFields.push({id:  field.fieldLabel, label:  field.fieldLabel});
				})
			})
		}
		if (this.data && this.data.product) {
			this.data.product.productFields.forEach((field) => {
				this.certFields.push({id: "Prodotto - " + field.name, label: "Prodotto - " + field.name});
			});
		}

		
		if (this.data && this.data.pageId) {
			this._landingPageService.get(this.data.company.companyId, this.data.pageId).subscribe((result) => {
				this.certificateDefinition = result;
				this.initForm(result);
				this.dataLoaded = true;
			},
			(error) => {
				this.initForm();
				this.dataLoaded = true;
			}
			)
		}
		else {
			this.initForm();
			this.dataLoaded = true;
		}
		
		

	}

    constructor(
		private activeModal: NgbActiveModal,
		private _toastService: ToastService,
		private _fb: FormBuilder,
		private _landingPageService: CertificatePageService,
		private _userProfileService: ProfileService,
		private _modalService: ModalService) {
			
    }

	addGroup = (value?: CertificatePageSection) => {
		if (!value) {
			value = new CertificatePageSection();
			value.buttons = [];
			value.linkedFields = [];
		}
		this.groups.push(value);
		this.groupValidity.push(false);
	}
	removeGroup = (index: number) => {
		this.groups.splice(index,1);
		this.groupValidity.splice(index,1);
	}
	validateGroup = (index: number, valid: boolean) => {
		this.groupValidity[index] = valid;
	}
	public get title() {
		return "editor Pagina del certificato";
	}


	private initForm = (initData?: CertificatePageDefinition) => {
		let phones = [];
		if (this.data.company.phoneNumber && this.data.company.phoneNumber.length > 0)
			phones = [this.data.company.phoneNumber];
		 let entityFormDef: any = {
			pageId: [(initData)? initData.pageId: uuidv4()],
			backgroudColor: [(initData)? initData.backgroudColor: null, Validators.required],
			textColor: [(initData)? initData.textColor: null, Validators.required],
			companyLogo: [(initData)? initData.companyLogo: null],
			companyName: [(initData)? initData.companyName: this.data.company.name, Validators.required],
			companyAddress: [(initData)? initData.companyAddress: this.data.company.address, Validators.required],
			companyPlace: [(initData)? initData.companyPlace: this.data.company.city, Validators.required],
			phones: [(initData)? initData.phones: phones, Validators.required],
			email: [(initData)? initData.email: this.data.company.email, Validators.required],
			vat: [(initData)? initData.vat: this.data.company.piva, Validators.required]
		};

		this.entityForm = this._fb.group(entityFormDef);
	
		if (initData && initData.sections) {
			initData.sections.forEach((group) => {
				this.addGroup(group);
			})
		}
	}

	get isAdmin() {
		return this._userProfileService.isInRole(Roles.SUPER_ADMIN);
	}

    public save(certData?: boolean) {
		console.log("saving: ", this.entityForm.value);
		console.log("saving: ", this.groups);
		if (!this.certificateDefinition) {
			this.certificateDefinition = new CertificatePageDefinition();
		}

		Object.assign(this.certificateDefinition, this.entityForm.value);
		this.certificateDefinition.sections = this.groups;
		
		this._landingPageService.saveOrUpdate(this.data.company.companyId, this.certificateDefinition).pipe(
			catchError(error => {
				this._toastService.showDanger("Si è verificato un errore durante il salvataggio dei dati.");
				return of(null);
			})
		)
		.subscribe(
			(result) => {
				this.activeModal.close(result);
			},
			(error) => {
				this._toastService.showDanger("Si è verificato un errore dusante il lalvataggio dei dati");
			}
		)

		// this.activeModal.close(true);
        // if (this.canSaveForm) {
			
		// 	let toSave: EntityDefinition = this.entityForm.value;
		// 	toSave.groups = this.groups;

		// 	if (this.data.product && this.data.product.linkedEntity) {
		// 		let found = false;
		// 		for (let i=0; i < this.data.product.linkedEntity.length && !found; i++) {
		// 			if (this.data.product.linkedEntity[i].entityId == toSave.entityId) {
		// 				found = true;
		// 				this.data.product.linkedEntity[i] = toSave;
		// 			}
		// 		}
		// 		if (!found) this.data.product.linkedEntity.push(toSave);
		// 	}
		// 	else {
		// 		this.data.product.linkedEntity = [toSave];
		// 	}
			
			
        //     this._productService.saveOrUpdate(this.data.company.companyId, this.data.product)
		// 	.pipe(
		// 		catchError(error => {
		// 			this._modalService.showErrors(["Si è verificato un errore durante il salvataggio dei dati."]);
					
		// 			return of(null);
		// 		})
		// 	)
		// 	.subscribe(
		// 		(result) => {
		// 			if (result) this._toastService.showSuccess("Salvataggio avvenuto con successo");
		// 			this.activeModal.close(result);
					
		// 		},
		// 		(error) => {
		// 			this._toastService.showDanger("Si è verificato un errore dusante il lalvataggio dei dati");
		// 		}
		// 	)
        // }
		// else {
		// }
    }

	public get canSaveForm(): boolean {
		return this.entityForm.valid && this.groups.length > 0 &&  this.groupValidity.indexOf(false)<0;
	}

	public close = (): void => {
		this.activeModal.close(null);
    }
}


