import { defaultCultureConfiguration } from "app/common/services/culture.service";
import { Input, Output, EventEmitter, Component, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ModalService } from "app/common/services/modal.service";
import { TranslateService } from "@ngx-translate/core";
import { CommonValidators } from "./validators/common.validator";
import { FormFieldDescription } from "app/common/models/forms";

class CultureDef {
	language: string;
	name: string;
}


@Component({
	selector: 'localized-input-container',
	templateUrl: './localized-input-container.component.html',
	styles: [`
		.close {
			font-size: 1.4rem;
			opacity: 0.1;
			transition: opacity 0.3s;
		}
		.nav-link:hover > .close {
			opacity: 0.8;
		}
		
		.invalid {
			border-color: #F55252;
		}
		.invalid-tab {
			border: 2px solid #F55252;
		}
  `]
})

export class LocalizedInputContainer {
	@ViewChild("navContainer") navContainer;
	@Input() fields: FormFieldDescription[];
	@Output() onKeyUp: EventEmitter<string> = new EventEmitter<string>();
	@Input() rows: number = 1;
	@Input() maxLength: number = Number.MAX_SAFE_INTEGER;


	constructor(private _fb: FormBuilder, private _modalService: ModalService, private _translate: TranslateService) {
		defaultCultureConfiguration.cultures.forEach((c) => {
			this.localizedItems.push({ language: c.code, name: c.description });
		})
	}


	public id: string = null;
	activeId = 0;

	@Input() formArray: FormArray;
	@Input() label: string = null;
	@Input() placeHolder: string = null;
	@Input() icon: string = null;
	@Input() requiredCultures: CultureDef[] = [];
	@Input() minRequiredCulturesNumber: number = 0;

	protected localizedItems: CultureDef[] = [];

	public get invalid(): boolean {
		if (this.formArray)
			return !this.formArray.valid;
		return false;
		// if (this.formArray)
		// 	return !this.formArray.valid || ((this.errorMessage)?true:false);
		// return false;
	}

	public invalidIndex(index): boolean {
		if (this.formArray && this.formArray.controls[index])
			return !this.formArray.controls[index].valid;
		return false;
	}
	public get correctPlaceHolder() {
		return this.placeHolder || this.label;
	}


	public get currentItems(): AbstractControl[] {
		if (this.formArray && this.formArray.controls.length > 0)
			return this.formArray.controls;
		return [];
	}

	public get remainingCulture(): CultureDef[] {
		if (this.formArray)
			return this.localizedItems.filter((item) => { return (<any[]>this.formArray.value).findIndex((el) => { return el.language == item.language }) < 0 });
		return this.localizedItems;
	}


	addItem($event, language: string) {
		let dataDef: any = {
			language: [language, Validators.required]
		};
		this.fields.forEach((field) => {
			dataDef[""+field.fieldName] = [field.defaultValue]
			field.validators.forEach((validator) => {
				dataDef[""+field.fieldName].push(validator);
			})
		})
		this.formArray.push(this._fb.group(dataDef))
		$event.preventDefault();
		// this.navContainer.select(0)
		$event.stopImmediatePropagation();
		this.activeId = this.formArray.controls.length - 1;
	}
	setSelect(id) {
		this.navContainer.select(id);
	}

	removeItem($event, index) {
		$event.preventDefault();
		let callback = (data) => {
			if (data) {
				if (index == this.formArray.controls.length - 1)
					this.activeId = (this.activeId > 0) ? this.activeId - 1 : 0;
				else if (index == this.activeId)
					this.activeId = 0;
				else
					this.activeId = (this.activeId > 0) ? this.activeId - 1 : 0;
				this.formArray.removeAt(index);
			}

		}
		this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
			callback: callback,
			size: "md"
		});
	}
	public get errorMessage(): string {
		return CommonValidators.getErrorMessages(this.formArray,this._translate)[0];
	}
}