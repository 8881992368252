import { ModuleWithProviders, NgModule } from "@angular/core";
import { NgbDatepickerI18n, NgbToastModule } from "@ng-bootstrap/ng-bootstrap";
import { CultureService } from "./culture.service";
import { DatepickerI18nService } from "./datepicker-i18n.service";
import { EventBusService } from "./event-bus.service";
import { ModalService } from "./modal.service";
import { SpinnerService } from "./spinner.service";
import { ToastService } from "./toasts.service";

@NgModule({
    declarations: [
    ],
    imports: [
    ],
    providers: [
    ],
    exports: [
    ],
    entryComponents: [
    ],
})
export class CommonServicesModule {
    static forRoot(): ModuleWithProviders<CommonServicesModule> {
        return {
            ngModule: CommonServicesModule,
            providers: [
                CultureService,
                ModalService,
                NgbToastModule,
                ToastService,
                EventBusService,
                SpinnerService,
                { provide: NgbDatepickerI18n, useClass: DatepickerI18nService }
            ]
        };
    }

}
