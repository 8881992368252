import { BaseAvatarStreamingService } from "./base-avatar-streaming-api.service";


const presenterInputByService = {
	talks: {
		// "face": {
		// 	"top_left": [
		// 		0,
		// 		0
		// 	],
		// 	"size": 512
		// },
		// "config": {
		// 	"motion_factor": 0,
		// 	"align_expand_factor": 0,
		// 	"stitch": true
		// },
		// source_url: "s3://d-id-create-images-prod/google-oauth2|107778935542107823025/upl_sACg6Gk3swImGmI6-YaJt/image.png" // OLIVIO
		// source_url: "s3://d-id-create-images-prod/google-oauth2|107778935542107823025/upl_PvqBMme6pUqzbuVFbeO-c/image.png" // Nadia Savino
		// source_url: "https://create-images-results.d-id.com/google-oauth2%7C107778935542107823025/upl_OPafZ_eZ3jU2NAfdn1zNB/image.png"
		source_url: "https://create-images-results.d-id.com/google-oauth2%7C107778935542107823025/upl_hRGDRDauLciHbBWybwFEz/image.png"
		// source_url: 'https://d-id-public-bucket.s3.amazonaws.com/or-roman.jpg',
		// source_url: "https://dashboard-demo.posti.world/persona1.jpg",
		// source_url: "https://dashboard-demo.posti.world/olivio.jpeg",
		// source_url: "https://cdn.studenti.stbm.it/images/2017/01/10/gioconda-orig.jpeg"
		// source_url: "https://dashboard-demo.posti.world/olivio-face.jpg",
		// driver_url: "bank://classics"
	},
	clips: {
		presenter_id: 'rian-lZC6MmWfC1',
		driver_id: 'mXra4jY38i'
	}
}


// document.querySelector("#startAppBtn").addEventListener("click", connectHandler);

export class DiDServiceAPI extends BaseAvatarStreamingService{
	// DID_API = {
	// 	"key": "dm1hcmV0dG9AZ21haWwuY29t:s-enBPDKiOUBSUgzocAdH",
	// 	"url": "https://api.d-id.com",
	// 	"service": "talks"
	// }
	private configuration: {
		voice_id: string,
		apiKey: string,
		serviceUrl: string,
		service: "talks" | "clips",
		sessionConfig: any,
		silentUrl: string
		
	};
	// // var voice_id = "it-IT-DiegoNeural";
	// voice_id = "it-IT-FabiolaNeural";
	
	
	d_id_welcomeMessage = "";
	d_id_initialized = false;
	RTCPeerConnection = (
		window.RTCPeerConnection ||
		(<any>window).webkitRTCPeerConnection ||
		(<any>window).mozRTCPeerConnection
	).bind(window);
	
	d_id_peerConnection;
	d_id_streamId;
	d_id_sessionId;
	d_id_sessionClientAnswer;
	
	d_id_statsIntervalId;
	d_id_videoIsPlaying;
	d_id_lastBytesReceived;
	
	d_id_videoElement: any;
	// d_id_videoElement.setAttribute('playsinline', '');

	maxRetryCount = 3;
	maxDelaySec = 4;
	
	
	
	public stopStreamHandler = () => {}

	public createNewSession = async (configuration, d_id_videoElement, welcomeMessage) => {
		this.d_id_videoElement = d_id_videoElement;
		this.d_id_videoElement.setAttribute('playsinline', '');
		this.configuration = configuration;
		console.log("Config: ", this.configuration);
		
		this.d_id_welcomeMessage = welcomeMessage;
		if (this.d_id_peerConnection && this.d_id_peerConnection.connectionState === 'connected') {
			return;
		}
	
		this.d_id_stopAllStreams();
		this.d_id_closePC();
	
		const sessionResponse = await this.d_id_fetchWithRetries(`${this.configuration.serviceUrl}/talks/streams`, {
			method: 'POST',
			headers: {
				Authorization: `Basic ${this.configuration.apiKey}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(configuration.sessionConfig),
			// body: JSON.stringify(presenterInputByService[this.configuration.service]),
		});
	
		const { id: newStreamId, offer, ice_servers: iceServers, session_id: newSessionId } = await sessionResponse.json();
		this.d_id_streamId = newStreamId;
		this.d_id_sessionId = newSessionId;
	
		try {
			this.d_id_sessionClientAnswer = await this.d_id_createPeerConnection(offer, iceServers);
		} catch (e) {
			console.log('error during streaming setup', e);
			this.d_id_stopAllStreams();
			this.d_id_closePC();
			return;
		}
	
		const sdpResponse = await fetch(`${this.configuration.serviceUrl}/${this.configuration.service}/streams/${this.d_id_streamId}/sdp`, {
			method: 'POST',
			headers: {
				Authorization: `Basic ${this.configuration.apiKey}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				answer: this.d_id_sessionClientAnswer,
				session_id: this.d_id_sessionId,
			}),
		});
	};
	
	public talk = async (textToSpeech) =>{
		if (this.d_id_peerConnection?.signalingState === 'stable' || this.d_id_peerConnection?.iceConnectionState === 'connected') {
			if (textToSpeech && textToSpeech.trim()) {
				let text = textToSpeech.trim();
				try {
					const playResponse = await this.d_id_fetchWithRetries(`${this.configuration.serviceUrl}/${this.configuration.service}/streams/${this.d_id_streamId}`, {
						method: 'POST',
						headers: {
							Authorization: `Basic ${this.configuration.apiKey}`,
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							script: {
								type: 'text',
								provider: {
									type: "microsoft",
									voice_id: this.configuration.voice_id
									
			
								},
								input: text
							},
							...(this.configuration.service === 'clips' && {
								background: {
									color: '#FFFFFF'
								}
							}),
							config: {
								stitch: true,
							},
							session_id: this.d_id_sessionId,
						}),
					});
				} catch (error) {
					this.createNewSession(this.configuration, this.d_id_videoElement, this.d_id_welcomeMessage);
					const playResponse = await this.d_id_fetchWithRetries(`${this.configuration.serviceUrl}/${this.configuration.service}/streams/${this.d_id_streamId}`, {
						method: 'POST',
						headers: {
							Authorization: `Basic ${this.configuration.apiKey}`,
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							script: {
								type: 'text',
								provider: {
									type: "microsoft",
									voice_id: this.configuration.voice_id
								},
								input: text
							},
							...(this.configuration.service === 'clips' && {
								background: {
									color: '#FFFFFF'
								}
							}),
							config: {
								stitch: true,
							},
							session_id: this.d_id_sessionId,
						}),
					});
				}
				
			}
		}
	}
	
	public closeConnectionHandler = async () => {
		await fetch(`${this.configuration.serviceUrl}/${this.configuration.service}/streams/${this.d_id_streamId}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Basic ${this.configuration.apiKey}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ session_id: this.d_id_sessionId }),
		});
	
		this.d_id_stopAllStreams();
		this.d_id_closePC();
		this.d_id_initialized = false;
		this.d_id_streamId = null;
		this.d_id_sessionId = null;
	}
	
	
	public d_id_onIceGatheringStateChange = () => {
	
	}
	public d_id_onIceCandidate = (event) => {
		console.log('d_id_onIceCandidate', event);
		if (event.candidate) {
			const { candidate, sdpMid, sdpMLineIndex } = event.candidate;
	
			fetch(`${this.configuration.serviceUrl}/${this.configuration.service}/streams/${this.d_id_streamId}/ice`, {
				method: 'POST',
				headers: {
					Authorization: `Basic ${this.configuration.apiKey}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					candidate,
					sdpMid,
					sdpMLineIndex,
					session_id: this.d_id_sessionId,
				}),
			});
		}
	}
	public d_id_onIceConnectionStateChange = () => {
		if (this.d_id_peerConnection.iceConnectionState === 'failed' || this.d_id_peerConnection.iceConnectionState === 'closed') {
			this.d_id_stopAllStreams();
			this.d_id_closePC();
		}
	}
	public d_id_onConnectionStateChange = () => {
		// not supported in firefox
		
	}
	public d_id_onSignalingStateChange = () => {
		
	}
	
	public d_id_onVideoStatusChange = (d_id_videoIsPlaying, stream) => {
		let status;
		if (d_id_videoIsPlaying) {
			status = 'streaming';
			const remoteStream = stream;
			this.d_id_setVideoElement(remoteStream);
		} else {
			status = 'empty';
			this.d_id_playIdleVideo();
		}
	
	}
	
	public d_id_onTrack = (event) => {
		/**
		 * The following code is designed to provide information about wether currently there is data
		 * that's being streamed - It does so by periodically looking for changes in total stream data size
		 *
		 * This information in our case is used in order to show idle video while no video is streaming.
		 * To create this idle video use the POST https://api.d-id.com/talks (or clips) endpoint with a silent audio file or a text script with only ssml breaks
		 * https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html#break-tag
		 * for seamless results use `config.fluent: true` and provide the same configuration as the streaming video
		 */
	
		if (!event.track || event.track.kind !== "video") return;
	
		this.d_id_statsIntervalId = setInterval(async () => {
			const stats = await this.d_id_peerConnection.getStats(event.track);
			stats.forEach((report) => {
				if (report.type !== 'inbound-rtp' || (report.mediaType && report.mediaType !== 'video' ) || (report.kind && report.kind !== 'video')) {
					return;
				}
				// if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
					const videoStatusChanged = this.d_id_videoIsPlaying !== report.bytesReceived > this.d_id_lastBytesReceived;
	
					if (videoStatusChanged) {
						this.d_id_videoIsPlaying = report.bytesReceived > this.d_id_lastBytesReceived;
						this.d_id_onVideoStatusChange(this.d_id_videoIsPlaying, event.streams[0]);
					}
					this.d_id_lastBytesReceived = report.bytesReceived;
				// }
			});
			
			
		}, 500);
	}
	
	public d_id_createPeerConnection = async (offer, iceServers) => {
		if (!this.d_id_peerConnection) {
			this.d_id_peerConnection = new RTCPeerConnection({ iceServers });
			this.d_id_peerConnection.addEventListener('icegatheringstatechange', this.d_id_onIceGatheringStateChange, true);
			this.d_id_peerConnection.addEventListener('icecandidate', this.d_id_onIceCandidate, true);
			this.d_id_peerConnection.addEventListener('iceconnectionstatechange', this.d_id_onIceConnectionStateChange, true);
			this.d_id_peerConnection.addEventListener('connectionstatechange', this.d_id_onConnectionStateChange, true);
			this.d_id_peerConnection.addEventListener('signalingstatechange', this.d_id_onSignalingStateChange, true);
			this.d_id_peerConnection.addEventListener('track', this.d_id_onTrack, true);
		}
	
		await this.d_id_peerConnection.setRemoteDescription(offer);
		console.log('set remote sdp OK');
	
		const d_id_sessionClientAnswer = await this.d_id_peerConnection.createAnswer();
		console.log('create local sdp OK');
	
		await this.d_id_peerConnection.setLocalDescription(d_id_sessionClientAnswer);
		console.log('set local sdp OK');
	
		return d_id_sessionClientAnswer;
	}
	
	public d_id_setVideoElement = (stream) => {
		if (!stream) return;
		this.d_id_videoElement.srcObject = stream;
		this.d_id_videoElement.loop = false;
	
		// safari hotfix
		if (this.d_id_videoElement.paused) {
			this.d_id_videoElement
				.play()
				.then((_) => { })
				.catch((e) => { });
		}
	}
	
	public d_id_playIdleVideo = () => {
		console.log("d_id_videoElement", this.d_id_videoElement);
		
		this.d_id_videoElement.srcObject = undefined;
		this.d_id_videoElement.src = this.configuration.silentUrl;
		this.d_id_videoElement.loop = true;

		if (this.d_id_initialized == false) {
			this.d_id_initialized = true;	
			this.talk(this.d_id_welcomeMessage).then(() => {});
		}
		this.d_id_videoElement.oncanplay = () => {
			console.log("CAN Play...");
			console.log(this.d_id_videoElement);
			
			// this.d_id_videoElement.play();
		}
		// this.d_id_videoElement.play();
	}
	
	public d_id_stopAllStreams = () => {
		if (this.d_id_videoElement.srcObject) {
			console.log('stopping video streams');
			this.d_id_videoElement.srcObject.getTracks().forEach((track) => track.stop());
			this.d_id_videoElement.srcObject = null;
			this.d_id_videoElement.src = null;
		}
	}
	
	public d_id_closePC = (pc = this.d_id_peerConnection) => {
		if (!pc) return;
		console.log('stopping peer connection');
		pc.close();
		pc.removeEventListener('icegatheringstatechange', this.d_id_onIceGatheringStateChange, true);
		pc.removeEventListener('icecandidate',this. d_id_onIceCandidate, true);
		pc.removeEventListener('iceconnectionstatechange', this.d_id_onIceConnectionStateChange, true);
		pc.removeEventListener('connectionstatechange', this.d_id_onConnectionStateChange, true);
		pc.removeEventListener('signalingstatechange', this.d_id_onSignalingStateChange, true);
		pc.removeEventListener('track', this.d_id_onTrack, true);
		clearInterval(this.d_id_statsIntervalId);
		console.log('stopped peer connection');
		this.d_id_videoIsPlaying = undefined;
		this.d_id_lastBytesReceived = undefined;
		if (pc === this.d_id_peerConnection) {
			this.d_id_peerConnection = null;
		}
	}
	
	
	
	public d_id_fetchWithRetries = async (url, options, retries = 1) => {
		try {
			return await fetch(url, options);
		} catch (err) {
			if (retries <= this.maxRetryCount) {
				const delay = Math.min(Math.pow(2, retries) / 4 + Math.random(), this.maxDelaySec) * 1000;
	
				await new Promise((resolve) => setTimeout(resolve, delay));
	
				console.log(`Request failed, retrying ${retries}/${this.maxRetryCount}. Error ${err}`);
				return this.d_id_fetchWithRetries(url, options, retries + 1);
			} else {
				throw new Error(`Max retries exceeded. error: ${err}`);
			}
		}
	}
}
