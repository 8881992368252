import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { GroupItem } from 'app/services/ingrediant.service';

@Component({
	selector: 'nutriscore-detail',
	templateUrl: './nutriscore-detail.component.html',
	styleUrls: ['nutriscore-detail.component.scss']
})
export class NutriscoreDetailComponent implements OnInit, AfterViewInit{
	@Input("item") item: GroupItem;
	pageLoaded = false;
	measurementInGrams= true;
	insertLabelMeasurementUnit = "";
	measurementUnit = "";

	constructor() {

	}
	_quantity = 100;
	get quantity() {
		return this._quantity;
	}
	set quantity(v) {
		console.log("set quantity");
		
		this._quantity = v;

		this.items = this.items.map((e) => {
			if (e.isNum)
				e.value = <number>e.baseValue * this._quantity / 100;
			return e;
		})
		
		
		// .forEach(e => {
		// 	if (Number.isInteger(e.baseValue))
		// 		e.value = <number>e.baseValue * this._quantity / 100;
		// })
		this.index++;
	}
	index = 0;
	classes = ["A", "B", "C", "D", "E"];
	colors = {
		"Orange": "#f39202",
		"Yellow": "#ffde01",
		"Dark green": "#007518",
		"Light green": "#95c11f",
		"Red": "#dd1e00"
	}
	
	ngAfterViewInit(): void {
		
	}

	ngOnInit() {
		this.pageLoaded = true;
		this.items.push({label: "Energy", baseValue: this.item.energy, value: this.item.energy, measurementUnit: "Kcal", color: this.colors[this.item.waterFootprintBanding], isNum: true});
		this.items.push({label: "Proteins", baseValue: this.item.proteins, value: this.item.proteins, measurementUnit: "g", color: this.colors[this.item.waterFootprintBanding], isNum: true});
		this.items.push({label: "Fat", baseValue: this.item.fat, value: this.item.fat, measurementUnit: "g", color: this.colors[this.item.waterFootprintBanding], isNum: true});
		this.items.push({label: "Saturated Fat", baseValue: this.item.saturatedFat, value: this.item.saturatedFat, measurementUnit: "g", color: this.colors[this.item.waterFootprintBanding], isNum: true});
		this.items.push({label: "Monounsaturated Fat", baseValue: this.item.monounsaturatedFat, value: this.item.monounsaturatedFat, measurementUnit: "g", color: this.colors[this.item.waterFootprintBanding], isNum: true});
		this.items.push({label: "Polyunsaturated Fat", baseValue: this.item.polyunsaturatedFat, value: this.item.polyunsaturatedFat, measurementUnit: "g", color: this.colors[this.item.waterFootprintBanding], isNum: true});
		this.items.push({label: "Carbohydrates", baseValue: this.item.carbohydrates, value: this.item.carbohydrates, measurementUnit: "g", color: this.colors[this.item.waterFootprintBanding], isNum: true});
		this.items.push({label: "Soluble", baseValue: this.item.soluble, value: this.item.soluble, measurementUnit: "g", color: this.colors[this.item.waterFootprintBanding], isNum: true});
		this.items.push({label: "Fiber", baseValue: this.item.fiber, value: this.item.fiber, measurementUnit: "g", color: this.colors[this.item.waterFootprintBanding], isNum: true});
	
		if (this.item && this.item.FATTY_or_LEAN_fish) {
			this.items.push({label: "FATTY or LEAN fish", baseValue: this.item.FATTY_or_LEAN_fish, value: this.item.FATTY_or_LEAN_fish, measurementUnit: "", color: this.colors[this.item.waterFootprintBanding], isNum: false});
		}
		if (this.item && this.item.unitsWaterfootprint == "liters water/kg") {
			this.measurementInGrams = true;
			this.insertLabelMeasurementUnit = "Insert the grams";
			this.measurementUnit = "g.";
		}
		else {
			this.measurementInGrams = false;
			this.insertLabelMeasurementUnit = "Insert the ml";
			this.measurementUnit = "ml.";
		}
	}

	
	items: any[] = [
		
	]
	
}