import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { PipeModule } from 'app/pipes/pipe.module';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NewLandingPage } from './new-landing-page.page';
import { TalkingAvatarComponent } from './talking-avatar-component/talking-avatar.component';



export const LandingPageRoutes: Routes = [
    { path: 'landing-v2', component: NewLandingPage, canActivate: []},
    { path: 'landing-v2/:companyId/:certId', component: NewLandingPage, canActivate: []},
    { path: 'landing-v2/:companyId/:lpId/:certId', component: NewLandingPage, canActivate: []},
    
];

@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
		BrowserModule,
        ComponentsModule,
        RouterModule.forChild(LandingPageRoutes),
		NgJsonEditorModule,
		ArchwizardModule,
		PipeModule
		,
		NgbModule
    ],
    exports: [
		TalkingAvatarComponent
	],
    declarations: [
		NewLandingPage,
		TalkingAvatarComponent
		// CertVerifyModal
		// UnautorizedPage
    ],
    providers: [],
	entryComponents: [
		TalkingAvatarComponent
		// CertVerifyModal
	]
})
export class NewLandingPageModule { }