import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastService } from "app/common/services/toasts.service";
import { iconCancel, iconSave } from "app/common/utils/icons.utils";
import { ProfileService } from "app/services/profile.service";

@Component({
    selector: 'update-password-modal',
    templateUrl: './update-password-modal.component.html'
})
export class UpdatePasswordComponent implements OnInit {
	public data: any;
	title = "Aggiorna la password";
	iconCancel= iconCancel;
	iconSave = iconSave;
	formGroup: FormGroup;
	constructor(private _activeModal: NgbActiveModal, private _userService: ProfileService,private _fb: FormBuilder, private _toastService: ToastService) {
	}

	ngOnInit() {
		this.formGroup = this._fb.group({
			// currentPassword: [null, Validators.required],
			temporary: [false, Validators.required],
			value: [null, Validators.required],
			type: ["password"]
		})
		// this.formGroup.get("newPassword").valueChanges.subscribe((value) => {
		// 	this.formGroup.get("confirmation").patchValue(value);
		// })
	}
	public get validForm() {
		return this.formGroup && this.formGroup.valid;
	}
	update = () => {
		if (this.formGroup.valid) {
			this._userService.updatePassword(this.formGroup.value).subscribe((result) => {
				this._toastService.showSuccess("La tua password è stata cambiata");
				this._activeModal.close(true);
			},
			(error) => {
				this._toastService.showDanger("Salvataggio della password NON avvenuto correttamente");
			}
			)
		}
		
	}

	close = () => {
		this._activeModal.close(false);
	}
}