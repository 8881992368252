import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmData } from '../../models/models';

@Component({
    selector: 'confirm-modal',
    templateUrl: 'confirm-modal.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})

export class ConfirmModalComponent implements OnInit {
    public data: ModalConfirmData;

    constructor(public activeModal: NgbActiveModal) {

    }

    ngOnInit(): void {
    }

    public close = (): void => {
        this.activeModal.close(false);
    }
    public ok = (): void => {
        this.activeModal.close(true);
    }
}