import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidatorUtils } from './validator-utils';
import { CustomValidationErrors, CustomValidationErrorFunc } from './models';
import { TranslateService } from '@ngx-translate/core';
import { CultureInfo } from 'app/common/services/culture.service';
import { BlockChainService } from 'app/services/blockchain.service';

export class CommonValidators {
	public static stringMaxLengthValidator(num: number): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let v = {};
			v[CommonValidators.maxLen] = {value: control.value, length: num};
			return (control.value && control.value.length > num) ? v : null;
		};
	}
	public static stringMinLengthValidator(num: number): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let v = {};
			v[CommonValidators.minLen] = {value: control.value, length: num};
			return (control.value && control.value.length < num) ? v : null;
		};
	}
	public static noMinusCharValidator(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let v = {};
			v[CommonValidators.noMinus] = {value: control.value};
			return (control.value && control.value.indexOf("-")>=0) ? v : null;
		};
	}
    public static required(control: AbstractControl): CustomValidationErrors | null {
        if (control.value == null || control.value.toString().trim() == '') {
            return ValidatorUtils.required();
        }
        return null;
    };

	public static requiredImage(control: AbstractControl): CustomValidationErrors | null {
        if (control.value == null || control.value == undefined || control.value== 0 || control.value.toString().trim() == '') {
            return ValidatorUtils.required();
        }
        return null;
    };
    public static requiredIf(condition): CustomValidationErrorFunc | null {
        let func = (control: AbstractControl): CustomValidationErrors | null => {
            if (!condition()) {
                return null;
            }
            return CommonValidators.required(control);
        }
        return func;
    }

	public static arrayMinItemsValidator(num: number, vKey: string = CommonValidators.arrayMinItemsValidatorKey): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let v = {};
			v[vKey] = {value: control.value, length: num};
			return (!control.value || (control.value && control.value.length < num)) ? v : null;
		};
	}

	public static localizedMinItemsValidator(num: number, vKey: string = CommonValidators.localizedMinItemsValidatorKey): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let v = {};
			v[vKey] = {value: control.value, cultureNumbers: num};
			return (!control.value || (control.value && control.value.length < num)) ? v : null;
		};
	}

	public static requiredCultures(requiredCulturesKey: CultureInfo[], vKey: string = CommonValidators.requiredCulturesKey): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let v = {};
			let remainingCultures: CultureInfo[] = [];
			if (control.value && control.value.length>0) {
				requiredCulturesKey.forEach((key) => {
					let index = control.value.findIndex((value) => {return value.language == key.code});
					if (index < 0)
					remainingCultures.push(key)
				})
			}
			v[vKey] = {value: control.value, remainingCultures: remainingCultures};
			return (remainingCultures.length>0) ? v : null;
		};
	}

	public static walletPrivateKeyValidator(publicKey: string, _blockChainService: BlockChainService): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let v = {};
			v["privateKeyNotValid"] = {value: control.value};
			let verified = false;
			try {
				verified = _blockChainService.verifyPublicKey(publicKey, control.value);
			} catch (error) {
				verified = false;
			}
			return (control.value && verified) ? null : v;
		};
	}

	public static patternMatchingValidator(regExp: RegExp, vKey: string = CommonValidators.genericPatternKey): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let v = {};
			v[vKey] = {value: control.value};
			return (control.value && !regExp.test(control.value)) ? v : null;
		};
	}

	private static emailPattern = /\S+@\S+\.\S+/;
	private static legalChars = /^[a-z0-9 \-]+$/i;
	private static websitePattern = /^(https?):\/\/[^\s$.?#].[^\s]*$/;
	private static secureWebsitePattern = /^(https):\/\/[^\s$.?#].[^\s]*$/;
	private static noWhiteSpaceAtBeginEndPattern = /^\S$|^\S[\s\S]*\S$/;

	public static noWhiteSpaceAtStardAndEnd(vKey: string = CommonValidators.noWhiteSpaceAtBeginEnd): ValidatorFn {
		return CommonValidators.patternMatchingValidator(CommonValidators.noWhiteSpaceAtBeginEndPattern, CommonValidators.noWhiteSpaceAtBeginEnd);
	}
	public static emailFormatValidator(vKey: string = CommonValidators.localizedMinItemsValidatorKey): ValidatorFn {
		return CommonValidators.patternMatchingValidator(CommonValidators.emailPattern, CommonValidators.emailPatternKey);
	}
	public static websiteFormatValidator(vKey: string = CommonValidators.localizedMinItemsValidatorKey): ValidatorFn {
		return CommonValidators.patternMatchingValidator(CommonValidators.websitePattern, CommonValidators.websitePatternKey);
	}
	public static secureWebsiteFormatValidator(vKey: string = CommonValidators.localizedMinItemsValidatorKey): ValidatorFn {
		return CommonValidators.patternMatchingValidator(CommonValidators.secureWebsitePattern, CommonValidators.secureWebsitePatternKey);
	}
	public static legalCharsValidator(vKey: string = CommonValidators.localizedMinItemsValidatorKey): ValidatorFn {
		return CommonValidators.patternMatchingValidator(CommonValidators.legalChars, "caratterinonvalidi");
	}
		

	public static localizedMinItemsValidatorKey = "validation.localizedMinItemsValidator";
	public static arrayMinItemsValidatorKey = "arrayMinItemsValidator";
	public static requiredCulturesKey = "validation.requiredCulturesKey";
	public static genericPatternKey = "validation.genericPatternKey";
	public static emailPatternKey = "invalidEmail";
	public static websitePatternKey = "invalidWebsiteURL";
	public static secureWebsitePatternKey = "invalidSecureWebsiteURL";
	public static noWhiteSpaceAtBeginEnd = "noWhiteSpaceAtBeginEnd";
	public static minLen = "minLen";
	public static maxLen = "MaxLen";
	public static noMinus = "noMinus";

	public static getErrorMessages(control: AbstractControl, translate: TranslateService): string[] {
		var errors: string[] = [];
		if (control.errors) {
			
			if (control.errors[CommonValidators.localizedMinItemsValidatorKey]) {
				let length = control.errors[CommonValidators.localizedMinItemsValidatorKey].cultureNumbers;
				errors.push(
					(""+translate.instant((length>1) ? "validation.minCulturesPluralRequired":"validation.minCulturesSingolarRequired")).replaceAll("{{cultureNumbers}}", ""+length)
				)
			}
			
			if (control.errors[CommonValidators.requiredCulturesKey]) {
				let remainingCultures: CultureInfo[] = control.errors[CommonValidators.requiredCulturesKey].remainingCultures;
				let cultures = " ";
				remainingCultures.forEach((c) => {cultures += " " + c.description;});
				errors.push(
					translate.instant(
						(remainingCultures.length==1)? "validation.cultureRequired":"validation.culturesRequired"
					) + cultures
				);
			}
			if (control.errors[CommonValidators.minLen]) {
				let v = control.errors[CommonValidators.minLen].length;
				errors.push(
					"il campo deve contenere almeno " + v + " caratteri"
				);
			}
			if (control.errors[CommonValidators.maxLen]) {
				let v = control.errors[CommonValidators.maxLen].length;
				errors.push(
					"il campo può contenere al massimo " + v + " caratteri"
				);
			}
			if (control.errors[CommonValidators.noMinus]) {
				let v = control.errors[CommonValidators.noMinus].length;
				errors.push(
					"il campo non può contenere il carattere '-'"
				);
			}
		}
		return errors;
	} 

    // public static requiredIf(control: AbstractControl, validateFunc: () => boolean): CustomValidationErrors | null {
    //     if (validateFunc() && (control.value == null || control.value.toString().trim() == '')) {
    //         return ValidatorUtils.required();
    //     }
    //     return null;
    // };
}
