import { Component, forwardRef, Input, Optional, Host, SkipSelf, Inject, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { BaseInputComponent } from './base-input.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'input-color',
    templateUrl: './input-color.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputColorComponent),
        multi: true,
    }],
    styleUrls: ["./input-color.component.scss"]
})

export class InputColorComponent extends BaseInputComponent {
    @Output() onKeyUp: EventEmitter<string> = new EventEmitter<string>();
    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService) {
        super(controlContainer, translateService);
    }

    protected toInternalFormat(value: string): string {
        return value;
    }

    protected toExternalFormat(value: string): string {
        if (value == "") {
            return null;
        }
        return value;
    }
    public keyUp(event: any) { // without type info
        this.onKeyUp.emit(event.target.value);
    }

}
