import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Roles, UserProfile } from '../models/profile.models';
import { HttpService } from './http.service';
import jwtDecode from "jwt-decode";
import { environment } from 'environments/environment';
import moment from 'moment'
import { Company } from 'app/models/entities.model';
import { SwitchDataLakeService } from './switch-data-lake.service';
import { rowData } from 'assets/data/data';


export enum GROUP_ICON {
	"ANIMAL FAT" = "assets/img/icons/switch-lp/Animal fat.svg",
	"CEREALS" = "assets/img/icons/switch-lp/Cereals.svg",
	"BAKERY" = "assets/img/icons/switch-lp/Bakery.svg",
	"VEGETABLES" = "assets/img/icons/switch-lp/Vegetables.svg",
	"PASTA&RICE" = "assets/img/icons/switch-lp/Pasta.svg",
	"LEGUMES" = "assets/img/icons/switch-lp/Legumes.svg",
	"FRUIT" = "assets/img/icons/switch-lp/Fruit.svg",
	"DAIRY" = "assets/img/icons/switch-lp/Dairy.svg",
	"MEAT" = "assets/img/icons/switch-lp/Meat.svg",
	"FISH" = "assets/img/icons/switch-lp/Fish-1.svg",
	"SHELLFISH" = "assets/img/icons/switch-lp/Shellfish.svg",
	"FISH&SHELLFISH" = "assets/img/icons/switch-lp/Fish.svg",
	"EGGS" = "assets/img/icons/switch-lp/Eggs.svg",
	"OILS&FAT" = "assets/img/icons/switch-lp/Oil.svg",
	"OILS&VEGETABLE FAT" = "assets/img/icons/switch-lp/Oil.svg",
	"NUTS&SEEDS" = "assets/img/icons/switch-lp/Nuts.svg",
	"SUGAR&HONEY&SWEETS" = "assets/img/icons/switch-lp/Sugar.svg",
	"DRESSING" = "assets/img/icons/switch-lp/Dressing.svg",
	"COFFEE&COCOA&TEA&INFUSES" = "assets/img/icons/switch-lp/Coffee.svg",
	"SOFT DRINKS" = "assets/img/icons/switch-lp/Soft_drinks.svg",
	"VEGETABLES MILK&PROTEINS" = "assets/img/icons/switch-lp/Vegetalbes_milk.svg",
	"WINE&BEER" = "assets/img/icons/switch-lp/Wine.svg",
	"SPICES" = "assets/img/icons/switch-lp/Spices.svg",
	"PLANT BASED MILK&PROTEINS"= "assets/img/icons/switch-lp/plant based.svg"
	
}

export class Group {
	id: any;
	secureId?: string;
	icon?: string;
	items: GroupItem[];
	subgroups: Group[];
}
export class GroupItem {
	id;
	carbonFootprint;
	unitsCarbonFootprint;
	waterFootprint;
	unitsWaterfootprint;
	carbonFootprintBanding; 
	carbonFootprintBandingImpactDescription;
	waterFootprintBanding;
	waterFootprintBandingImpactDescription;
	blueSustainabilityIndex;
	environmentalScore;
	FATTY_or_LEAN_fish;
	energy;
	proteins;
	fat;
	saturatedFat;
	monounsaturatedFat;
	polyunsaturatedFat;
	carbohydrates;
	soluble;
	fiber;
	frequencyOfConsumption;
	recommendation?;
	Recommendation_on_Sustainability_and_Nutritional?;
}

@Injectable()
export class IngrediantService {
	
	constructor() {
	}
	public getAll = (): Observable<Group[]> => {
		let data = []
		rowData.forEach((element) => {
			let found = data.find((el) => {
				return el.id.trim().toUpperCase() == element['FOOD COMMODITY GROUP'].trim().toUpperCase();
			})
			// console.log("found ", found);
			
			let group: Group = null;
			if (found) group = found;
			else {
				group = {
					id: element['FOOD COMMODITY GROUP'],
					secureId: element['FOOD COMMODITY GROUP'].split("&").join("_").split(" ").join("").split("-").join(""),
					icon: GROUP_ICON[""+element['FOOD COMMODITY GROUP']],
					subgroups: [], items: []
				};
				data.push(group);
			}

			if (element['FOOD COMMODITY SUB-GROUP'] && element['FOOD COMMODITY ITEM']) {
				found = group.subgroups.find((el) => {
					return el.id.trim().toUpperCase() == element['FOOD COMMODITY SUB-GROUP'].trim().toUpperCase();
				})
				let subgroup: Group = null;
				if (found) subgroup = found;
				else {
					subgroup = {
						id: element['FOOD COMMODITY SUB-GROUP'],
						secureId: element['FOOD COMMODITY SUB-GROUP'].split("&").join("_").split(" ").join("").split("-").join(""),
						subgroups: [], items: []
					};
					group.subgroups.push(subgroup);
				};
				// if (element['FOOD COMMODITY ITEM']) {
					element["id"] = element['FOOD COMMODITY ITEM'];
					subgroup.items.push(<any>element);
				// }
				
					
			}
			else {
				// if (element['FOOD COMMODITY ITEM']) {
					// element["id"] = element['FOOD COMMODITY ITEM'];
					// group.items.push(<any>element);
				// }
				element["id"] = element['FOOD COMMODITY SUB-GROUP'];
				group.items.push(<any>element);

			}

		});

		return of(data);
	}

}

