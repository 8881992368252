import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseComponent } from "app/common/components/base.component";
import { SelectListitem } from "app/common/models/models";
import { iconSave, iconTrash } from "app/common/utils/icons.utils";
import { FieldType, FieldValidator, ValidatorType } from "app/models/entities.model";

@Component({
    selector: 'modal-validator-editor',
    templateUrl: './modal-validator-editor.component.html'
})
export class ModalValidatorEditor extends BaseComponent implements OnInit {
	title = "definisci i vincoli per il campo";
	iconCancel = iconTrash;
	iconSave = iconSave;
	dataLoaded = false;
	entityForm: FormGroup;
	possibleFormats: SelectListitem[] = [];

	data: {
		fieldType: FieldType,
		validators: FieldValidator[]
	};

	constructor(private _activeModal: NgbActiveModal, private _fb: FormBuilder) {
		super();
	}
	ngOnInit(): void {
		let required = false;
		
		if (this.data && this.data.validators && this.data.validators.length>0) {
			required = this.data.validators.findIndex((element) => {
				return element.validatorType == ValidatorType.REQUIRED
			}) >= 0;
		}
		this.entityForm = this._fb.group({
			required: [required]
		})
		console.log("data",this.data);
		
		this.dataLoaded = true;
	}
	public get canSaveForm(): boolean {
		return this.entityForm && this.entityForm.valid;
	}

	public save() {
        if (this.canSaveForm) {
			let validators: FieldValidator[] = [];
			if (this.entityForm.value.required) validators.push({validatorType:ValidatorType.REQUIRED,constraint:[]});
			this._activeModal.close(validators);
        }
    }

	public close = (): void => {
		this._activeModal.close(false);
    }
}
