import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { iconSetting, iconTrash } from "app/common/utils/icons.utils";

import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { FieldType, FieldValidator, GroupDefinition, ValidatorType } from 'app/models/entities.model';

import { v4 as uuidv4 } from 'uuid';
import { ModalOptions, SelectListitem } from 'app/common/models/models';
import { ModalValidatorEditor } from './modal-validator-editor.component';
import { ModalService } from 'app/common/services/modal.service';

@Component({
    selector: 'group-definition',
    templateUrl: './group-definition.component.html'
})
export class GroupDefinitionComponent implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconFieldRemove = iconTrash;
	iconSetting = iconSetting;
	public currentEntityDef: any = {};
	@Input("group") group: GroupDefinition = null;
	@Output() requestDeleteGroup = new EventEmitter<GroupDefinition>();
	@Output() groupValid = new EventEmitter<boolean>();
	public entityForm: FormGroup = null;
	public entityFields: FormArray = null;

	ngbAccordion;
	fieldTypeItems: SelectListitem[] = [
		{id: FieldType.STRING, label: "Campo di testo"},
		{id: FieldType.DESCRIPTION, label: "Descrizione testuale"},
		{id: FieldType.DATE, label: "Data"},
		// {id: FieldType.DATETIME, label: "Data e Ora"},
		{id: FieldType.INTEGER, label: "Numero intero"},
		{id: FieldType.DECIMAL, label: "Numero decimale"},
		{id: FieldType.IMAGE, label: "Immagine"},
		{id: FieldType.SINGLE_SELECT, label: "Elenco con selezione singola"},
		{id: FieldType.MULTIPLE_SELECT, label: "Elenco con selezione multipla"}
	];
	ngOnInit() {
		
		this.initForm();
		this.groupValid.emit(this.isValid);
	}

    constructor(private _fb: FormBuilder, private ref: ChangeDetectorRef, private _modalService: ModalService) {
			
    }

	addField = (value?: any) => {
		let validators: FieldValidator[] = [
			{validatorType: ValidatorType.REQUIRED, constraint: []}
		];
		let patter = /^[a-z0-9 \/]+$/i;
		let id = uuidv4();
		this.possibleValuesMap[""+id] = [];
		let additional = this._fb.group({
			fieldId: [id, CommonValidators.required],
			fieldLabel: [null, [
				CommonValidators.required,
				CommonValidators.patternMatchingValidator(patter,"Il campo deve essere composto solo di lettere, numeri, spazi e simbolo /"),
				CommonValidators.noWhiteSpaceAtStardAndEnd("Il campo non deve contenere spazi all'inizio ed alla fine")]],
			fieldType: [null, CommonValidators.required], //FieldType,
			defaultValue:  [null],
			possibleValues: [[]],
			constraint: [null],
			validators: [validators],
			hidden: [false],
			fieldToCertify: [true]
		})
		if (value) additional.patchValue(value);
		this.entityFields.push(additional);
	}
	possibleValuesMap = {};

	removeField = (index: number) => {
		this.entityFields.removeAt(index);
	}

	deleteGroup = (event: MouseEvent) => {
		event.preventDefault();
		this.requestDeleteGroup.emit(this.group);
	}

	private initForm = () => {
		let patter = /^[a-z0-9 \/]+$/i;
		this.entityFields = this._fb.array([]);
		if (this.group && this.group.fields) {
			this.group.fields.forEach(element => {
				this.addField(element);
			});
		}
		let groupDefinition = {
			groupId: [uuidv4()],
			groupLabel: [null, [
				CommonValidators.required,
				CommonValidators.patternMatchingValidator(patter,"Il campo deve essere composto solo di lettere, numeri, spazi e simbolo /"),
				CommonValidators.noWhiteSpaceAtStardAndEnd("Il campo non deve contenere spazi all'inizio ed alla fine")]],
			isArray: [false],
			fields: this.entityFields
		};


		this.entityForm = this._fb.group(groupDefinition);
		if (this.group)
			this.entityForm.patchValue(this.group)
		
		this.entityForm.valueChanges.subscribe((value) => {
			Object.assign(this.group,value);
			this.groupValid.emit(this.isValid);
		})
	}

	fieldIsDate = (field: FormGroup) => {
		let value = field.value.fieldType;
		return field.value.fieldType == FieldType.DATE;
	}
	fieldIsSelect = (field: FormGroup) => {
		let value = field.value.fieldType;
		return value == FieldType.SINGLE_SELECT;
	}
	fieldIsMultiSelect = (field: FormGroup) => {
		let value = field.value.fieldType;
		return value == FieldType.MULTIPLE_SELECT;
	}
	fieldIsFile = (field: FormGroup) => {
		let value = field.value.fieldType;
		return value == FieldType.IMAGE || value == FieldType.FILE;
	}
	fieldIsString = (field: FormGroup) => {
		let value = field.value.fieldType;
		return value != FieldType.SINGLE_SELECT && value != FieldType.MULTIPLE_SELECT && value != FieldType.DATE && value != FieldType.IMAGE && value != FieldType.FILE;
	}

	disablePossibleValues = (field: FormGroup) => {
		let value = field.value.fieldType;
		return value != FieldType.SINGLE_SELECT && value != FieldType.MULTIPLE_SELECT;
	}


	possibleValues = (field: FormGroup) => {
		let value: any[] = field.value.possibleValues;
		console.log("here");
		
		if (value) {
			return value.map((element) => {
				return {id: element, label: element}
			})
			
		}
		return [];
	}
	get groupName() {
		if (this.entityForm && this.entityForm.value.groupLabel) return this.entityForm.value.groupLabel;
		return "definisci un nome per il gruppo";
	}

	get isValid() {
		return this.entityForm && this.entityForm.valid && this.entityFields.length > 0;
	}

	defineConstraint = (field: FormControl) => {
		
	
		let modalOption: ModalOptions<FieldValidator[]> = {
			size: 'xl',
			callback: (res) => {
				console.log("received", res);
				if (res) {
					field.get("validators").patchValue(res);
					
				}
			}
		}
		let data = {
			fieldType: field.value.fieldType,
			validators: field.value.validators
		};
		this._modalService.show(ModalValidatorEditor, data, modalOption);
	}
}