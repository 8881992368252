import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableAction, DataTableColumn, ListTableManager } from 'app/common/components/table/types';
import { ModalOptions } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';
import { ToastService } from 'app/common/services/toasts.service';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import { ProfileService } from 'app/services/profile.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Roles, UserProfile } from "../../../models/profile.models";

@Component({
	selector: 'user-enabling',
	templateUrl: './user-enabling.page.html'
})

export class UserEnablingList  extends BaseListTablePage<UserProfile> {
	currentDomain;
	domainPath;
	downloadExcelEnabled = false;
	deleteEntityEnabled = false;
	numberOfAvailableItems: number = 0;
	public tableColumns: DataTableColumn[] = [];
	public tableActions: DataTableAction[] = [];
	public filters: Filters = new Filters();
	constructor(
		private _toastService: ToastService,
		_activatedRoute: ActivatedRoute, private _router: Router, private _titleService: Title, private _userProfileService: ProfileService, private _modalService: ModalService) {
		super(_activatedRoute, true);


	}

	private path = (obj, path) => {
		try {
			return eval("obj." + path);
		} catch (e) {
			return undefined;
		}
	}

	initialize = (): void => {
		this.createColumms();
		this.createActions();
		this._titleService.setTitle("Gestione utenze")
	}

	
	private getUsers = (): Observable<UserProfile[]> => {
		return this._userProfileService.getAll([
			"id",
			"userId",
			"firstName",
			"lastName",
			"email",
			"roles"
		]).pipe(
			// filter((value, index) => {
			// 	console.log("filtering " + value[index].email + " => " +  value[index].roles.lastIndexOf(Roles.DASHBOARD_USER));
				
			// 	// return value && value[index] && value[index].roles && value[index].roles.lastIndexOf(Roles.DASHBOARD_USER) < 0;

			// 	if (value) value= value.filter((user) => user.roles && user.roles.lastIndexOf(Roles.DASHBOARD_USER) < 0);
			// 	return true;
			// 	// return [];
			// }),
			map((r) => {
				r= r.map((u) => {(<any>u).name=u.firstName + " " + u.lastName; return u});
				if (r) r= r.filter((user) => user.roles && user.roles.lastIndexOf(Roles.DASHBOARD_USER) < 0);
				if (r) this.numberOfAvailableItems = r.length;
				return r;
			}))
				

	}

	protected getDataTableManager = (): ListTableManager<UserProfile> => {
		return new ListTableManager(this.getUsers)
	}

	private createColumms = (): void => {
		let fields = [
			{id: "id", hidden: true},
			{id: "userId", dataTableField: DataTableUtils.createStringColumn("userId", "User ID", true), hidden: true},
			{id: "name", dataTableField: DataTableUtils.createStringColumn("name", "Nome", true), hidden: false},
			{id: "email", dataTableField: DataTableUtils.createStringColumn("email", "email", true), hidden: false},
			// {id: "roles", dataTableField: DataTableUtils.createArrayColumn("roles", "ruoli", ""), hidden: false}			
		];
		fields.forEach(element => {
			if (!element.hidden)
				this.tableColumns.push(element.dataTableField);
		});
	}

	private createActions = (): void => {
		let button = new DataTableAction();
		button.funcToInvoke = this.edit;
		button.label = "Abilita Utente";
		button.icon = "ft-check";
		this.tableActions.push(button);

		// button = new DataTableAction();
		// button.funcToInvoke = this.delete;
		// button.label = "Elimina";
		// button.icon = "ft-trash-2";
		// this.tableActions.push(button);
	}
	
	// public delete = (row: Restaurant): void => {
	// 	let callback = (data) => {
	// 		if (data) {
	// 			this.on(this.activatedRoute.paramMap.subscribe(qp => {
	// 				this._userProfileService.deleteProfile(row.id).subscribe((result) => {
	// 					this.dataTableManager.startReload();
	// 				})

	// 			}));

				
	// 		}
	// 	}
	// 	this._modalService.showConfirm("common.dialogs.alertRemoveItemTitle", "common.dialogs.alertRemoveItemMessage", {
	// 		callback: callback,
	// 		size: "md"
	// 	});
    // }
	
	private saveResult = (result): void => {
		if (result) {
			this.dataTableManager.startReload();
		}
	}
    public edit = (row: any): void => {
		let callUpdate = (confirm) => {
			console.log("confirm " + confirm);
			
			if (confirm) {
				this._userProfileService.getProfile(row.id).subscribe((result) => {
					console.log("Abilitare utenza.....", result);
					if (result && result.roles && result.roles.lastIndexOf(Roles.DASHBOARD_USER) < 0){
						console.log("controllo effettuato.");
						result.roles.push(Roles.DASHBOARD_USER);
						console.log(result);
						
						this._userProfileService.saveOrUpdateOtherProfile(result).subscribe((newUser) => {
							this._toastService.showSuccess("Utente " + row.name + " abilitato con successo");
							this.dataTableManager.startReload();
						})
					}
					
				})
			}
		}

		let modalOption: ModalOptions<boolean> = {
			size: 'sm',
			callback: callUpdate
		}
		this._modalService.showConfirm("Abilitazione Utenza", "Sei sicuro di voler abilitare l'utente " + row.name + "?", modalOption);

		
		
    }
}


class Filters {
}