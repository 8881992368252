import { Injectable } from "@angular/core";
import { Company, Product } from "app/models/entities.model";
import { UserProfile } from "app/models/profile.models";
import { environment } from "environments/environment";
import moment from "moment";
import { Observable, of } from "rxjs";
import { BlockChainService } from "./blockchain.service";
import { HttpService } from "./http.service";
import { URLUtils } from "./url-utils";

import { catchError, map } from 'rxjs/operators';
import { SwitchDataLakeService } from "./switch-data-lake.service";

@Injectable({
	providedIn: 'root'
})
export class ProductService {
	private baseUrl = environment.services.apiBasePath;
	constructor(private _httpService: HttpService, private _chainService: BlockChainService, private _dataLake: SwitchDataLakeService) {
	}
	public getAll = (domain: string, fieldsName?: string[]): Observable<Product[]> => {
		let queryParams = ""
		if (fieldsName && fieldsName.length>0) {
			queryParams = "?";
			fieldsName.forEach(field => {
				queryParams += "fields="+field+ "&";
			})
		}

		return this._httpService.get(this.baseUrl + "/" + domain + "/product" + queryParams, "json");
	}
	public get = (domain: string, id: any): Observable<Product> => {
		let url = this.baseUrl + "/" + domain + "/product/" + id;
		return this._httpService.get(url);
	}
	public getByField = (domain: string, fieldId: string, value: any): Observable<Product> => {
		let url = this.baseUrl + "/" + domain + "/product/" + fieldId + "/" + value;
		return this._httpService.get(url);
	}
	public delete = (domain: string, id: any): Observable<Product> => {
		let url = this.baseUrl + "/" + domain + "/product/" + id;
		return this._httpService.delete(url);
	}
	public saveOrUpdate = (company: Company, product: Product, toNotify: boolean = false): Observable<Product> => {
		let domain = company.companyId;
		let url = this.baseUrl + "/" + domain + "/product";
		product.lastUpdateDate = moment().format("YYYY-MM-DD hh:mm:ss");
		let toCall;
		if (product.id)
			toCall = this._httpService.put(url + "/"+product.id + "?notify="+toNotify, product,"json");
		else
			toCall = this._httpService.post(url + "?notify="+toNotify,product,"json");

		return toCall.pipe(
			map((result) => {
				if (environment.domainConfig.allowExternalProviders) {
					this._dataLake.saveProduct(product, company).pipe(
						catchError((err, caught) => {
							console.log("Exception", err);
							
							return of(null)
						})
					)
					.subscribe((r) => {
						console.log("Data saved on data lake. Result is:", r);
						
					})
				}
				return result;
			})
		)
		
	}
	public certify = async (data: Product, company: Company, userProfile: UserProfile, secret: string): Promise<any> => {
		let fieldsToCertify : any = {};
		fieldsToCertify["azienda"] = company.name;
		if (company.province) fieldsToCertify["indirizzo azienda"] = company.address + ", " + company.cap + " " + company.city+ " (" + company.province +")" ;
		else fieldsToCertify["indirizzo azienda"] = company.address + ", " + company.cap + " " + company.city ;
		
		fieldsToCertify["prodotto"] = data.name;
		data.productFields.forEach((field) => {
			fieldsToCertify[field.name] = field.value;
		})
		if (data.image) {
			fieldsToCertify["immagine_url"] =  environment.services.mediaContentsBasePath + "/"+data.image;
			await this.getImageHash(data.image).toPromise().then((imgHash) => {
				fieldsToCertify["immagine_hash"]= imgHash;
			});
		}
		if (data.image_3d) {
			fieldsToCertify["immagine3d_url"] =  environment.services.mediaContentsBasePath + "/"+data.image_3d;
			await this.getImageHash(data.image_3d).toPromise().then((imgHash) => {
				fieldsToCertify["immagine3d_hash"]= imgHash;
			});
		}

		let toSend: any = new Object();
		toSend.identity = userProfile.email;
		toSend.name = userProfile.firstName + " " + userProfile.lastName;
		toSend.pubkey = userProfile.walletInfo.pubKey;

		toSend.azienda = company.companyId;
		toSend.cert_id = data.id

		toSend.data = fieldsToCertify;		
		
		toSend.decorator_name = data.name;
		toSend.decorator_description = "Certificato del prodotto " + data.name;
		toSend.decorator_external_url = URLUtils.getCertPageURL(company,data)
		if (data.image)
			toSend.decorator_image =  environment.services.mediaContentsBasePath + "/"+data.image;
		if (data.image_3d)
			toSend.decorator_image_3d =  environment.services.mediaContentsBasePath + "/"+data.image_3d;
		
		
		return new Observable((observer) => {
			this._chainService.certifyEntity(toSend, secret).subscribe(
				(result) => {
					if (result) {
						data.certified = true;
						data.certifiedInDateUTC = moment().format();
						this.saveOrUpdate(company,data).subscribe((r) => {
							observer.next(result);
							observer.complete();
						})
					}
					else {
						observer.next(null);
						observer.complete();
					}
				},
				(error) => {
					observer.next(null);
					observer.complete();
				})

		}).toPromise();


	}

	public getImageHash(imageId): Observable<string>{
		return this._httpService.get(environment.services.mediaContentsBasePath + "/"+imageId + "/hash", "text");
	}

	// protected  certifyData = async (entityDef, dataToCertify: any) => {
	// 	let data = this.currentDomainConfig.marshalCerdData(dataToCertify);
	// 	let certificationFieldsSpec: any = entityDef.resetCertificationFieldsSpec(dataToCertify);
		
		

	// 	let fieldsToCertify : any = {};
		
	// 	for (const element of certificationFieldsSpec) {
	// 		if (element.isArray) {
	// 			fieldsToCertify = [];
	// 			for (const obj of data[element.fieldId]) {
	// 				if (!obj.removedFromBlockChain) {
	// 					let objToCert: any = {};
	// 					for (const spec of element.arrayFieldsSpec) {
	// 						if (this.path(obj, spec.fieldId)) {
	// 							objToCert[spec.fieldLabel] =  (spec.startValue) ? spec.startValue + this.path(obj, spec.fieldId): this.path(obj, spec.fieldId);
	// 							if (spec.isImage) {
	// 								await this._restaurantProvider.getImageHash(this.path(obj, spec.fieldId)).toPromise().then((imgHash) => {
	// 									objToCert[spec.fieldLabel.replaceAll("_url","_hash")] = imgHash;
	// 								});
	// 							}
	
	// 						}
	// 					}
	// 					fieldsToCertify.push(objToCert);
	// 				}
					
	// 			}
	// 		}
	// 		else {
	// 			if (this.path(data, element.fieldId)) {
	// 				if (element.isImage) {
	// 					fieldsToCertify[element.fieldLabel + "_url"] =  (element.startValue) ? element.startValue + this.path(data, element.fieldId): this.path(data, element.fieldId);
	// 					await this._restaurantProvider.getImageHash(this.path(data, element.fieldId)).toPromise().then((imgHash) => {
	// 						fieldsToCertify[element.fieldLabel+ "_hash"]= imgHash;
	// 					});
	// 				}
	// 				else {
	// 					fieldsToCertify[element.fieldLabel] =  (element.startValue) ? element.startValue + this.path(data, element.fieldId): this.path(data, element.fieldId);
	
	// 				}
	// 			}
				

	// 		}
	// 	}
		
	// }
}