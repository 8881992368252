import { Component, forwardRef, Optional, Host, SkipSelf, Inject, Injectable, OnDestroy, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';

import { BaseInputComponent } from './base-input.component';

import moment from 'moment'
import { TranslateService } from '@ngx-translate/core';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split("/");
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        if (date) {
            let momentDate = moment(date.year + '-' + date.month + '-' + date.day, 'YYYY-MM-DD');
            return momentDate.format('L')
        }
        return null;
    }
}


@Component({
    selector: 'input-date',
    templateUrl: './input-date.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputDateComponent), multi: true }
    ]
})
export class InputDateComponent extends BaseInputComponent<string, NgbDateStruct> {
    @Input() public utc: boolean = true;
    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService) {
        super(controlContainer, translateService);
    }

    protected toInternalFormat(value: string): NgbDateStruct {
        let utc = this.utc ? moment.utc(value).local() : moment(value.substr(0, 10));
        return new NgbDate(utc.year(), utc.month() + 1, utc.date())
    }
    protected toExternalFormat(value: NgbDateStruct): string {
        let momentDate = moment().year(value.year).month(value.month - 1).date(value.day);
        return this.utc ? momentDate.toISOString() : momentDate.format('YYYY-MM-DD');
    }
}
