import { Component, Injectable } from "@angular/core";
import { ToastItem, ToastTypes } from "app/common/models/models";
import { ToastService } from "app/common/services/toasts.service";

@Component({
    selector: 'toasts',
    templateUrl: './toasts.component.html'
})
export class ToastsComponent {
    public ToastTypes = ToastTypes;
    constructor(public toastService: ToastService) {

    }

    public remove = (toast: ToastItem): void => {
        this.toastService.remove(toast);
    }

    public get toasts() {
        return this.toastService.toasts;
    }

    public getClassNames = (toast: ToastItem): string => {
        switch (toast.type) {
            case ToastTypes.Info:
                return "bg-info text-white";
            case ToastTypes.Success:
                return "bg-success text-white";
            case ToastTypes.Danger:
                return "bg-danger text-white";
        }
    }
}