import { CustomValidationErrors } from './models';

export class ValidatorUtils {
    public static required (): CustomValidationErrors {
        return { required: [] };
    }
    public static integer (): CustomValidationErrors {
        return { integer: [] };
    }
    public static hours (): CustomValidationErrors {
        return { hours: [] };
    }
    public static minutes (): CustomValidationErrors {
        return { minutes: [] };
    }
    public static seconds (): CustomValidationErrors {
        return { seconds: [] };
    }
    public static date (): CustomValidationErrors {
        return { date: [] };
    }
    public static decimal (): CustomValidationErrors {
        return { decimal: [] };
    }
    public static decimalInvalidDigits (digits: number): CustomValidationErrors {
        return { decimalInvalidDigits: [digits] };
    }
}