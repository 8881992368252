import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Roles, UserProfile } from '../models/profile.models';
import { HttpService } from './http.service';
import jwtDecode from "jwt-decode";
import { environment } from 'environments/environment';
import moment from 'moment'
import { Company } from 'app/models/entities.model';
import { SwitchDataLakeService } from './switch-data-lake.service';

var CompanyService_DOMAINS_CONFIG = "__COMPANIES";
var CompanyService_CONFIG_DOMAIN = "__CONFIGURATION";

@Injectable()
export class CompanyService {
	private baseUrl = environment.services.apiBasePath + "/" + CompanyService_DOMAINS_CONFIG + "/"+CompanyService_CONFIG_DOMAIN;
	
	constructor(private _httpService: HttpService, private _dataLake: SwitchDataLakeService) {
	}
	public getAll = (fieldsName?: string[]): Observable<Company[]> => {
		let queryParams = ""
		if (fieldsName && fieldsName.length>0) {
			queryParams = "?";
			fieldsName.forEach(field => {
				queryParams += "fields="+field+ "&";
			})
		}

		return this._httpService.get(this.baseUrl + queryParams, "json");
	}

	public getMyCompany = (profile: UserProfile): Observable<Company> => {
		if (profile && profile.company)
			return this.getCompany(profile.company.company);
		return of(null);
	}

	public getCompany = (id: any): Observable<Company> => {
		let url = this.baseUrl + "/" + id;
		return this._httpService.get(url);
	}
	public getCompanyByField = (fieldId: string, value: any): Observable<Company> => {
		let url = this.baseUrl + "/" + fieldId + "/" + value;
		return this._httpService.get(url);
	}
	public delete = (id: any): Observable<Company> => {
		let url = this.baseUrl + "/" + id;
		return this._httpService.delete(url);
	}

	public saveOrUpdate = (company: Company, toNotify: boolean = false, ): Observable<Company> => {
		let url = this.baseUrl;
		company.lastUpdateDate = moment().format("DD-MM-YYYY hh:mm");
		if (company.id)
			return this._httpService.put(url + "/"+company.id + "?notify="+toNotify, company,"json");
		return this._httpService.post(url + "?notify="+toNotify,company,"json").pipe(
			map((result) => {
				if (environment.domainConfig.allowExternalProviders) {
					this._dataLake.saveCompany(company).pipe(
						catchError((err, caught) => {
							console.log("Exception", err);
							
							return of(null)
						})
					)
					.subscribe((r) => {
						console.log("Data saved on data lake. Result is:", r);
						
					})
				}
				return result;
			})
		)
	}
	// public certifyCompany = (data: any): Observable<any> => {
	// 	let url = environment.services.blockChainService;
	// 	return this._httpService.post(url,data,"json");
	// }

	// public getImageHash(imageId): Observable<string>{
	// 	return this._httpService.get(environment.services.mediaContentsBasePath + "/"+imageId + "/hash", "text");
	// }
}

