import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { iconSetting, iconTrash } from "app/common/utils/icons.utils";

import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { ButtonLinkType, CertificatePageSection, SectionButtonLink } from 'app/models/entities.model';

import { SelectListitem } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';

@Component({
    selector: 'section-editor-form',
    templateUrl: './section-editor.form.html'
})
export class SectionEditorForm implements OnInit {
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	pdfFiles = ['application/pdf'];
	iconFieldRemove = iconTrash;
	iconSetting = iconSetting;
	public currentEntityDef: any = {};
	@Input("section") section: CertificatePageSection = null;
	@Input("index") index;
	@Output() requestDeleteGroup = new EventEmitter<CertificatePageSection>();
	@Output() groupValid = new EventEmitter<boolean>();
	
	public entityForm: FormGroup = null;
	public entityFields: FormArray = null;

	ngbAccordion;
	@Input("certFields") certFields: SelectListitem[] = [];

	get groupName() { return "Sezione " + this.index;}


	buttonLinkType: SelectListitem[] = [
		{id: ButtonLinkType.URL, label: "link a pagina web"},
		{id: ButtonLinkType.PDF, label: "File PDF"}
	];

    constructor(private _fb: FormBuilder, private ref: ChangeDetectorRef, private _modalService: ModalService) {
			
    }
	ngOnInit() {
		this.initForm();
		this.groupValid.emit(this.isValid);
	}
	get isValid() {
		return this.entityForm && this.entityForm.valid;
	}
	
	getButtonLinkType = (field: FormGroup) => {
		console.log("getButtonLinkType ", field);
		
		return field.get("type").value ;
	}
	private setValidators = ( fieldGroup: FormGroup) => {
		fieldGroup.get("url").clearValidators();
		if (fieldGroup.get("type").value == ButtonLinkType.URL) {
			fieldGroup.get("url").setValidators([CommonValidators.required, CommonValidators.websiteFormatValidator()])
		}
		else {
			fieldGroup.get("url").setValidators([CommonValidators.required])
		}
		fieldGroup.get("url").updateValueAndValidity({emitEvent: true});
	}

	addField = (value?: SectionButtonLink) => {
		let additional = this._fb.group({
			label: [(value)? value.label: null, CommonValidators.required],
			url: [(value)? value.url: null],
			type: [(value)? value.type: ButtonLinkType.URL, CommonValidators.required]
		})
		this.setValidators(additional);	
		additional.get("type").valueChanges.subscribe((value) => {
			this.setValidators(additional);	
		})

		if (value) additional.patchValue(value);
		this.entityFields.push(additional);
	}
	
	removeField = (index: number) => {
		this.entityFields.removeAt(index);
	}

	private initForm = () => {
		this.entityFields = this._fb.array([]);
		if (this.section && this.section.buttons) {
			this.section.buttons.forEach(element => {
				this.addField(element);
			});
		}
		let groupDefinition = {
			description: [null, CommonValidators.required],
			image: [null, CommonValidators.required],
			linkedFields: [[]],
			buttons: this.entityFields
		};


		this.entityForm = this._fb.group(groupDefinition);
		if (this.section)
			this.entityForm.patchValue(this.section)

		this.entityForm.valueChanges.subscribe((value) => {
			Object.assign(this.section,value);
			this.groupValid.emit(this.isValid);
		})
	}

	
	deleteGroup = (event: MouseEvent) => {
		event.preventDefault();
		this.requestDeleteGroup.emit(this.section);
	}
}