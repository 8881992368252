// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
	authentication: {
		issuer: "https://iam.posti.world/realms/posti",
		silentRefreshRedirectUri: "/assets/authentication/silent-refresh.html",
		clientId: "posti-bo",
		responseType: "code",
		scope: "openid profile offline_access roles phone address",
		redirectUri: null,
		postLogoutRedirectUri: null
	},
	services: {
		updatePasswordUrl: "https://iam.posti.world/admin/realms/posti/users/{id}/reset-password",
		blockChainService: "https://api-gateway.posti.world/blockchain-service",
		newBlockchainBasePath: "https://api-gateway.posti.world/blockchain-service",

		mediaContentsBasePath:	"https://api-gateway.posti.world/switch-api/api/v1/bo/images",
		getMediaContentsBasePath:	"https://api-gateway.posti.world/switch-images/api/v1/bo/images",
		restaurantService: "https://api-gateway.posti.world/switch-api/api/v1/bo/Restaurant",

		apiBasePath: "https://api-gateway.posti.world/switch-api/api/v1/bo",
		delegationUrl: "https://verifier.posti.world/delegate",
		certDataBaseUrl: "https://verifier.posti.world/json/{{companyId}}/{{certId}}.json",

		dataLakeUrl: "https://api-gateway.posti.world/switch-data-lake/upload/posti",
		certVerifierUrl: "https://verifier.posti.world/{companyId}/{certificateId}/verify"
	},
	certificationConfig: {

		DEFAULT_CONFIG: {
			name: "pOsti",
			identity: "virgilio.maretto@posti.world",
			pubkey: "0x94A7Ae889eA2333216e61a644345B0086D23E4De"
		},
		PECORA_NERA: {
			external_api: "almaviva",
			name: "pOsti"
		},
		PECORA_NERA_2: {
			external_api: "almaviva",
			name: "pOsti"
		}
	},
	domainConfig: {
		defaultCertPagePatternURL: "https://verifier.posti.world/{companyId}/{id}/verify",
		defaultProductLandingPagePatternURL: "https://www.posti.world/{productId}",
		defaultEntityLandingPagePatternURL: "https://www.posti.world/{productId}?id={id}",
		// companyLogo: "assets/img/logos/Vertical_logo_switch.png",
		companyLogo: "assets/img/logos/Vertical_logo_switch.png",
		portalName: "Switch",
		portalTitle: "Switch Project",
		landingPageSiteLogo: "assets/img/logos/Vertical_logo_switch.png",
		allowExternalProviders: true,
		emailContact: "info@switchproject.eu",
		showGuideButton: false,
		showSupportButton: false,
		guideButtonUrl: "",
		supportButtonUrl: ""
	},
	certificatePageBaseUrl: "https://certificate-lp.posti.world/landing",
	landingPageBaseUrl: "https://certificate-lp.posti.world/landing-v2",
	startLoginAtStartup: false,
	appRoutes: [
		{
			path: '',
			redirectTo: "switch-home",
			pathMatch: 'full',
		},
		
		{
			path: '**',
			redirectTo: 'pages/error'
		}
	]
};
