import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { iconUserAvatar } from 'app/common/utils/icons.utils';
import { UserProfile } from 'app/models/profile.models';
import { AuthService } from 'app/services/auth.service';
import { ProfileService } from 'app/services/profile.service';
import { environment } from 'environments/environment';

@Component({
	selector: 'page-header',
	templateUrl: './page-header.component.html',
	encapsulation: ViewEncapsulation.None
})
export class PageHeaderComponent implements OnInit {
	logoUrl = environment.domainConfig.companyLogo;
	portalName = environment.domainConfig.portalName;
	userProfile: UserProfile;
	iconUserAvatar = iconUserAvatar;

	constructor(
		private _router: Router,
		private _authService: AuthService,
		private _userProfileService: ProfileService) {}

	ngOnInit(): void {
		this._userProfileService.getLoggedProfile().subscribe((result) => {
			
			this.userProfile = result;
		});
	}

	public get firstName() {
		if (this.userProfile && this.userProfile.firstName)
			return this.userProfile.firstName;
	}
	public get lastName() {
		if (this.userProfile && this.userProfile.lastName)
			return this.userProfile.lastName;
	}
	logout = () => {
		this._authService.logout();
	}
	public get profileImage() {
		if (this.userProfile && this.userProfile.avatarId)
			return environment.services.mediaContentsBasePath + "/" + this.userProfile.avatarId;
		return "assets/img/icons/face-icon-0.jpg.png"
	}

	goToSection = (section) => {
		// console.log("Section: " + section);
		
		// const targetElement = document.getElementById(section);
		// console.log("targetElement ",targetElement);
		
		// if (targetElement)
		// 	targetElement.scrollIntoView({behavior: "smooth"})
		const queryParams = {};
		// queryParams['returnUrl'] = "/user-profile"
		this._router.navigate([section], { queryParams: queryParams });
	}
}
