import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { FileProvider } from 'app/services/FileProvider.service';

@Component({
	selector: 'secured-img',
	templateUrl: './secured-image.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class SecuredImage implements OnInit {
	@Input() src: string;
	@Input() getContentImage: boolean = false;
	@Input() width = "45px";

	urlImage = "assets/img/icons/file.png";
	constructor(private _fileProvider: FileProvider) {
		
	}
	ngOnInit() {
		if (this.getContentImage) {
			this.urlImage = "assets/img/icons/wait-cloud.gif";
			this._fileProvider.getFileInfo(this.src).subscribe((file) => {
				this.urlImage = file.imageUrl;
			})
		}
		else {
			this.urlImage = this.src;
		}
	}
}