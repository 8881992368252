import { AbstractControl, FormArray } from "@angular/forms";

declare module '@angular/forms' {
    interface AbstractControl {
        isValid(): boolean;
        clear(): void;
    }
}
AbstractControl.prototype.isValid = function () {
    if (!this.valid) {
        this.markAllAsTouched();
    }
    return this.valid;
}

AbstractControl.prototype.clear = function () {
    let formArray = <FormArray>this;
    while (formArray.length > 0) {
        formArray.removeAt(0);
    }
}