import { AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { Roles, UserProfile } from 'app/models/profile.models';
import { forkJoin, of, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import moment from 'moment'
// import { CertVerifyModal } from './cert-verifier-modal/cert-verifier.modal';
import { ModalService } from 'app/common/services/modal.service';
import { ModalOptions } from 'app/common/models/models';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ViewportScroller } from '@angular/common';
import { CompanyService } from 'app/services/company.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LPButtonLinkType, LPSectionButtonLink, LandingPageDefinition, LandingPageSection } from 'app/models/landing-page.model';
import { LandingPageService } from 'app/services/landing-page.service';

@Component({
	selector: 'new-landing-page',
	templateUrl: './new-landing-page.page.html',
	styleUrls: ['new-landing-page.scss']
})
export class NewLandingPage implements OnInit, AfterViewInit{
	companyId: string;
	landingPageConfig: LandingPageDefinition;
	certificate: any;
	issue_date: any;
	error: "NOT_FOUND" | "NOT_AVAILABLE";
	pageLoaded = false;
	certSignature: any = {};
	certificateId;
	@ViewChild('dataToPrint') dataToPrint: ElementRef;
	@ViewChild('CERT_DATA') CERT_DATA: ElementRef; 
	@ViewChild('landingSectionsContainer') landingSectionsContainer: ElementRef;
	@ViewChildren('sectionContainer') sectionContainer: QueryList<any>;
	landingPageSiteLogo = environment.domainConfig.landingPageSiteLogo;
	constructor(
		private _router: Router,
		private _viewportScroller: ViewportScroller,
		private _translate: TranslateService,
		private _modalService: ModalService,
		private _landingPageService: LandingPageService,
		private _activatedRoute: ActivatedRoute,
		private _companyService: CompanyService
	) {

	}

	sectionLogo;

	imageBaseUrl = environment.services.getMediaContentsBasePath + "/";

	companyLogo;

	public get avatarLogo() {
		if (this.landingPageConfig)
			return this.imageBaseUrl + this.landingPageConfig.avatarLogo;
		return "";
	}

	ngAfterViewInit(): void {
		// console.log("ngAfterViewInit");
		// timer(2000).subscribe(() => {
		// 	// this.landingSectionsContainer.nativeElement.getElementsByTagName()
		// 	// console.log(this.sectionmediaelement);
		// 	// if (this.sectionmediaelement) {
		// 	// 	let elements = this.sectionmediaelement.forEach((element) => {
		// 	// 		console.log("apply playsinline to", element);
						
		// 	// 		element.playsinline = '';
		// 	// 		element.playsinline.play();

		// 	// 		// element.setAttribute('playsinline', '');
		// 	// 	})
				
		// 	// }
		// 	this.landingPageConfig.sections.forEach((section, index) => {
		// 		console.log("try to get mediaElement " + index);
				
		// 		let mediaElement: any = document.getElementById("sectionmediaelement_"+index);
		// 		if (mediaElement) {
		// 			mediaElement.
		// 			mediaElement.oncanplay = () => {
		// 				console.log("CAN Play...");
		// 				mediaElement.muted = true;
		// 				mediaElement.play();
		// 				// mediaElement.volume = 0.75;
						
		// 			  }

					
		// 		}
		// 	});
			

		// })

		
		
		this._activatedRoute.fragment.subscribe(fragment => { 
			if (fragment){
				timer(1000).subscribe(() => {
					const targetElement = this.CERT_DATA.nativeElement
					targetElement.scrollIntoView({behavior: "smooth"})
				})
				
			}
			
		});
	}

	ngOnInit() {
		// if (this._userProfileService.isInRole(Roles.DASHBOARD_USER)) {
		// 	this._router.navigate(["/user-profile"], { });
		// }
		this.changeLanguage(this._translate.getBrowserLang());
		this._activatedRoute.paramMap.subscribe(qp => {
			

			if (qp && qp.get("companyId")) {
				this.companyId = qp.get("companyId");
			}
			if (qp) {
				let lpId = qp.get("lpId");
				this.certificateId = qp.get("certId");
				let toCall: any = {
					certificate: this._landingPageService.getCertificate(this.companyId, this.certificateId),
					company: this._companyService.getCompanyByField("companyId", this.companyId)
				};

				if (lpId) {
					toCall["landingPageConfig"] = this._landingPageService.get(this.companyId, lpId).pipe(
						catchError(r => {
							return of(null)
						}),
					)
				}
				forkJoin(toCall).pipe(
					catchError(error => {
						return of(null);
					})
				)
				.subscribe(
					(result) => {
						
						if (result) {
							this.landingPageConfig = result["landingPageConfig"];
							if (this.landingPageConfig && this.landingPageConfig.sectionsLogo) {
								this.sectionLogo = this.imageBaseUrl + this.landingPageConfig.sectionsLogo;
							}
							if (this.landingPageConfig && this.landingPageConfig.companyLogo) {
								this.companyLogo = this.imageBaseUrl + this.landingPageConfig.companyLogo;
							}
							else if (result.company && result.company.logo) {
								this.companyLogo =  environment.services.getMediaContentsBasePath + "/" + result.company.logo;
							}
							console.log("companyLogo " + this.companyLogo);
							
							this.certificate = {};
							if (result["certificate"]) {
								if (!result["certificate"].signature) {
									this.error = 'NOT_AVAILABLE';
								}
								else if (result["certificate"].data && result["certificate"].data.data) {
									this.issue_date = moment(result["certificate"].data.issue_date, "DDMMYYYY").format("DD-MM-YYYY");
									for (let key in result["certificate"].data.data) {
										let newKey = key.split("_").join(" ");
										this.certificate[newKey] = result["certificate"].data.data[key].split("_").join(" ");
										// this.certificate.data.data[newKey] = this.certificate.data.data[key].split("_").join(" ");
									}
									this.certSignature = result["certificate"].signature;

									this.landingPageConfig.sections.forEach((section) => {
										for (let k in this.certificate) {
											section.description = section.description.replaceAll("${"+k+"}", this.certificate[k] + " <i class='cert-icon'></i>");
											if (section.subtitle)
												section.subtitle = section.subtitle.replaceAll("${"+k+"}", this.certificate[k] + " <i class='cert-icon'></i>");

										}
										
									})
								}
							}
							else {
								this.error = 'NOT_FOUND';
							}
						}
						else {
							this.error = 'NOT_FOUND';
						}
						this.pageLoaded = true;
					},
					(error) => {
						console.log("", error);
						this.error = 'NOT_FOUND';
						this.pageLoaded = true;
					}
				)
			}
		});
	}

	fieldIsUrs = (fieldLabel: string) => {
		return (this.certificate[""+fieldLabel] || "").startsWith("http");
	}

	isHash = (fieldLabel: string) => {
		return (""+fieldLabel).indexOf("hash") >=0;
	}

	getLabelForField = (fieldLabel: string) => {
		if (fieldLabel.split("-").length > 1)
			return fieldLabel.split("-")[1];
		return fieldLabel;
	}

	getValueForField = (fieldLabel: string) => {
		return this.certificate[""+fieldLabel] || "";
	}

	getCertFields = () => {
		let result = [];
		for (let key in this.certificate) {
			let k;
			if (key.split("-").length > 1)
				k = key.split("-")[1];
			else k=key;
			result.push({
				label: k, value: this.certificate[key]
			})
		}
		return result;
	}

	goto = (button: LPSectionButtonLink) => {
		if (button && button.type == LPButtonLinkType.PDF)
			window.open(this.imageBaseUrl + button.url, "_blank");
		else
			window.open(button.url, "_blank");
	}

	get transactionHash() {
		return (this.certSignature && this.certSignature.anchors && this.certSignature.anchors[0])? this.certSignature.anchors[0].sourceId: "N/A";
	}

	goToPublicRegistry = () => {
		if (this.certSignature && this.certSignature.anchors && this.certSignature.anchors[0]) {
			window.open("https://polygonscan.com/tx/"+this.certSignature.anchors[0].sourceId,  "_blank");
		}
	}

	getTratteggio = (item: LandingPageSection) =>{
		if (item && item.buttons && item.buttons[0])
			switch (item.buttons[0].type) {
				case LPButtonLinkType.Facebook:
					return "assets/img/tratteggio/Tratteggio_completo_facebook.svg";
				case LPButtonLinkType.Instagram:
					return "assets/img/tratteggio/Tratteggio_completo_instagram.svg";
				case LPButtonLinkType.PDF:
					return "assets/img/tratteggio/Tratteggio_completo_piu.svg";
				case LPButtonLinkType.URL:
					return "assets/img/tratteggio/Tratteggio_completo_web.svg";
				case LPButtonLinkType.shopOnline:
					return "assets/img/tratteggio/Tratteggio_completo_carrello.svg";

				default:
					return "assets/img/tratteggio/Tratteggio_completo.svg";
			}
		return "assets/img/tratteggio/Tratteggio_completo.svg";
	}

	getButtonImage = (button: LPSectionButtonLink) => {
		switch (button.type) {
			case LPButtonLinkType.Facebook: return "assets/img/lp-buttons/facebook.svg";
			case LPButtonLinkType.Instagram: return "assets/img/lp-buttons/instagram.svg";
			case LPButtonLinkType.shopOnline: return "assets/img/lp-buttons/carrello.svg";
			case LPButtonLinkType.URL: return "assets/img/lp-buttons/web.svg";
			case LPButtonLinkType.PDF: return "assets/img/lp-buttons/info.svg";
			default:
				break;
		}
	}
	
	clickTratteggio = (item: LandingPageSection, $event, index) => {
		console.log("item",item);
		console.log($event);
		console.log("layerX, layerY: " + $event.layerX + ", " + $event.layerY);
		/**
		 * X pulsante = 79,7% - 95%
		 * Y pulsante = 77.4% - 83.5%

		 * X freccia = 75.1% - 85.9%
		 * Y freccia = 93,2% - 97%
		 */

		let x = $event.layerX / $event.target.clientWidth;
		let y = $event.layerY / $event.target.clientHeight;
		
		if ((x <= 0.95 && x >= 0.797) && (y <= 0.835 && y >= 0.774)) {
			if (item.buttons && item.buttons.length>0)
				this.goto(item.buttons[0])
		}
		else if ((x <= 0.859 && x >= 0.751) && (y <= 0.97 && y >= 0.932))  {
			let targetElement;
			if (index < this.landingPageConfig.sections.length - 1) {
				targetElement = this.sectionContainer.toArray()[index+1].nativeElement;
			}
			else {
				targetElement = this.CERT_DATA.nativeElement
			}
			targetElement.scrollIntoView({behavior: "smooth"});
		}
		// console.log("imgH, clientY: " + $event.clientX + ", " + $event.clientY);
	}
	goNextSection = (startIndex) => {
		let targetElement;
			if (startIndex < this.landingPageConfig.sections.length - 1) {
				targetElement = this.sectionContainer.toArray()[startIndex+1].nativeElement;
			}
			else {
				targetElement = this.CERT_DATA.nativeElement
			}
			targetElement.scrollIntoView({behavior: "smooth"});
	}
	verifyCert = () => {
		// let modalOption: ModalOptions<boolean> = {
		// 	size: 'sm',
		// 	callback: (res) => {
				
		// 	}
		// }
		// let data = {
		// 	certificate: this.certificate,
		// 	certSignature: this.certSignature
		// }
		// this._modalService.show(CertVerifyModal, data, modalOption);
	}
	

	print = () => {
		// var doc = new jsPDF('portrait', 'mm', 'a4');
		// var elementHTML = document.getElementById("dataToPrint");
		// doc.html(elementHTML, {
		// 	callback: function (doc) {
		// 		// Save the PDF
		// 		doc.save('certificato-'+this.certificateId+'.pdf');
			
		// 	},
		// 	margin: [15, 15, 15, 15],
		// 	autoPaging:  'text',
		// 	x: 0,
		// 	y: 0,
		// 	width: 190, //target width in the PDF document
		// 	windowWidth: 675 //window width in CSS pixels
		// });
	}

	selectedLanguageFlag = "/assets/img/flags/flag-it.png";
	changeLanguage(language: string) {
		console.log("changing languange: " + language);
		
		this._translate.use(language);
	
		if (language === 'en') {
		  this.selectedLanguageFlag = "/assets/img/flags/flag-en.png";
		}
		else if (language === 'es') {
		  this.selectedLanguageFlag = "/assets/img/flags/flag-es.png";
		}
		else if (language === 'it') {
		  this.selectedLanguageFlag = "/assets/img/flags/flag-it.png";
		}
		else if (language === 'fr') {
		  this.selectedLanguageFlag = "/assets/img/flags/flag-fr.png";
		}
	}

	openOldCert = () => {
		let url = environment.services.certVerifierUrl.replaceAll("{companyId}",this.companyId).replaceAll("{certificateId}",this.certificateId);
		window.open(url, "_blank");
		// window.open(`https://verifier.posti.world/${this.companyId}/${this.certificateId}/verify`, "_blank");
	}
}