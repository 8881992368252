import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { iconCancel, iconDownload } from "app/common/utils/icons.utils";

@Component({
	selector: 'simple-qrcode',
	templateUrl: './simple-qrcode.component.html'
})
export class SimpleQRCodeComponent implements OnInit {
	public data: any;
	title = "QRcode";
	url = "";
	iconCancel = iconCancel;
	iconSave = iconDownload;
	@ViewChild("code") code;

	constructor(private _activeModal: NgbActiveModal) {
	}

	ngOnInit() {
		this.url = this.data.url;
		if (this.data.title)
			this.title = this.data.title;
	}

	close = () => {
		this._activeModal.close(false);
	}

	saveAsImage = () => {
		// fetches base 64 date from image
		console.log(this.code);
		console.log(this.code.nativeElement);
		
		const parentElement = this.code.qrcElement.nativeElement.firstChild.src;
		console.log(parentElement);
		
		// converts base 64 encoded image to blobData
		let blobData = this.convertBase64ToBlob(parentElement);

		// saves as image
		if (window.navigator && (<any>window.navigator).msSaveOrOpenBlob) { //IE
			(<any>window.navigator).msSaveOrOpenBlob(blobData, 'Qrcode');
		} else { // chrome
			const blob = new Blob([blobData], { type: "image/png" });
			const url = window.URL.createObjectURL(blob);
			// window.open(url);
			const link = document.createElement('a');
			link.href = url;
			link.download = 'Qrcode';
			link.click();
		}

	}

	private convertBase64ToBlob = (Base64Image: any) => {
		// SPLIT INTO TWO PARTS
		const parts = Base64Image.split(';base64,');
		// HOLD THE CONTENT TYPE
		const imageType = parts[0].split(':')[1];
		// DECODE BASE64 STRING
		const decodedData = window.atob(parts[1]);
		// CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
		const uInt8Array = new Uint8Array(decodedData.length);
		// INSERT ALL CHARACTER CODE INTO UINT8ARRAY
		for (let i = 0; i < decodedData.length; ++i) {
			uInt8Array[i] = decodedData.charCodeAt(i);
		}
		// RETURN BLOB IMAGE AFTER CONVERSION
		return new Blob([uInt8Array], { type: imageType });
	}
}