import { EventEmitter } from "@angular/core";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SpinnerService {
    public show$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public show = (): void => {
        this.show$.next(true);
    }
    public hide = (): void => {
        this.show$.next(false);
    }

}

