import { EventEmitter, Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NotificationMessage } from "../models/models";

@Injectable()
export class EventBusService {
    private message$: EventEmitter<NotificationMessage> = new EventEmitter();
    public subscribe = <T extends NotificationMessage>(code: string, callback: (message: T) => void): Subscription => {
        return this.message$.pipe(filter(message =>
            code == Object.getPrototypeOf(message).constructor.code)
        ).subscribe(callback);
    }

    public emit = (message: NotificationMessage): void => {
        this.message$.emit(message);
    }
}
