import { Component, forwardRef, Input, Optional, Host, SkipSelf, Inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseInputComponent } from './base-input.component';

@Component({
    selector: 'select-box',
    templateUrl: './select-box.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SelectBoxComponent),
        multi: true,
    }]
})

export class SelectBoxComponent extends BaseInputComponent<any, any> {
    @Input() public items: any[] = [];
    @Input() public itemKey: string = "id";
    @Input() public itemLabel: string = "label";
    @Input() public showPlaceHolder: boolean = true;
    @Input() public multiple: boolean = false;

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService) {
        super(controlContainer, translateService);
        this.placeHolder = "common.select";
    }

    protected toInternalFormat(value: any): any {
        return value;
    }

    protected toExternalFormat(value: any): any {
        if (value === "") {
            return null;
        }
        return value;
    }

}
