import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmData } from '../../models/models';

@Component({
    selector: 'errors-modal',
    templateUrl: 'errors-modal.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})

export class ErrorsModalComponent implements OnInit {
    public data: string[];

    constructor(public activeModal: NgbActiveModal) {

    }

    ngOnInit(): void {
    }

    public close = (): void => {
        this.activeModal.close();
    }
}