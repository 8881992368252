export var rowData = [
    {
        "FOOD COMMODITY GROUP": "ANIMAL FAT",
        "FOOD COMMODITY SUB-GROUP": "BUTTER",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "15.49",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5553",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "758",
        "proteins": "0.8",
        "fat": "83.4",
        "saturatedFat": "48.78",
        "monounsaturatedFat": "23.72",
        "polyunsaturatedFat": "2.75",
        "carbohydrates": "1.1",
        "soluble": "1.1",
        "fiber": "0",
        "frequencyOfConsumption": "Less than 1 time per day",
        "recommendation": "Limit the intake of butter and tropical oils",
        "Recommendation_on_Sustainability_and_Nutritional": "High fat and cholesterol content with high environmental impact. It is recommended to limit consumption and prefer vegetable oils. Recommended portion 10g. "
    },
    {
        "FOOD COMMODITY GROUP": "ANIMAL FAT",
        "FOOD COMMODITY SUB-GROUP": "CREAM",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "5.45",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "1898",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "337",
        "proteins": "2.3",
        "fat": "35",
        "saturatedFat": "20.37",
        "monounsaturatedFat": "10.85",
        "polyunsaturatedFat": "0.94",
        "carbohydrates": "3.4",
        "soluble": "3.4",
        "fiber": "0",
        "frequencyOfConsumption": "Less than 1 time per day",
        "recommendation": "Limit the intake of butter and tropical oils",
        "Recommendation_on_Sustainability_and_Nutritional": "High fat and cholesterol content with moderate impact. It is recommended to limit consumption. Recommended portion 10g. "
    },
    {
        "FOOD COMMODITY GROUP": "BAKERY",
        "FOOD COMMODITY SUB-GROUP": "Plain bread and crackers",
        "FOOD COMMODITY ITEM": "BREAD MULTICEREAL",
        "carbonFootprint": "0.86",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "949.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "236",
        "proteins": "9.6",
        "fat": "0.9",
        "saturatedFat": "0.11",
        "monounsaturatedFat": "0.1",
        "polyunsaturatedFat": "0.38",
        "carbohydrates": "47.5",
        "soluble": "1.4",
        "fiber": "5.5",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of carbohydrates with low environmental impact. Good source of energy. Medium intake of B vitamins and minerals. Low fat content. Recommended daily intake aroud 90 g per day and possibly whole grain bread for highest fiber and vitamin intake"
    },
    {
        "FOOD COMMODITY GROUP": "BAKERY",
        "FOOD COMMODITY SUB-GROUP": "Plain bread and crackers",
        "FOOD COMMODITY ITEM": "BREAD PLAIN",
        "carbonFootprint": "0.99",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1042",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "269",
        "proteins": "8.4",
        "fat": "0.4",
        "saturatedFat": "0.04",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.15",
        "carbohydrates": "59.8",
        "soluble": "2",
        "fiber": "3.8",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of carbohydrates with low environmental impact. Good source of energy. Medium intake of B vitamins and minerals. Low fat content. Recommended daily intake aroud 90 g per day and possibly whole grain bread for highest fiber and vitamin intake"
    },
    {
        "FOOD COMMODITY GROUP": "BAKERY",
        "FOOD COMMODITY SUB-GROUP": "Whole bread and crackers",
        "FOOD COMMODITY ITEM": "BREAD WHOLE",
        "carbonFootprint": "0.77",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "888",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "224",
        "proteins": "8.5",
        "fat": "1.3",
        "saturatedFat": "0.27",
        "monounsaturatedFat": "0.26",
        "polyunsaturatedFat": "0.51",
        "carbohydrates": "44.1",
        "soluble": "2",
        "fiber": "6.5",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of carbohydrates with low environmental impact. Good source of energy, fibers and vitamins, low fat content. Recommended daily intake aroud 90 g per day."
    },
    {
        "FOOD COMMODITY GROUP": "BAKERY",
        "FOOD COMMODITY SUB-GROUP": "Breakfast cereals",
        "FOOD COMMODITY ITEM": "BARLEY FLAKES",
        "carbonFootprint": "3.28",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1977",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "314",
        "proteins": "7.9",
        "fat": "1.5",
        "saturatedFat": "0.31",
        "monounsaturatedFat": "0.15",
        "polyunsaturatedFat": "0.83",
        "carbohydrates": "66.1",
        "soluble": "2.4",
        "fiber": "10.3",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of carbohydrates with low environmental impact. Good source of energy. Medium intake of B vitamins and minerals. Low fat content. Recommended daily intake aroud 90 g per day and possibly whole grain bread for highest fiber and vitamin intake"
    },
    {
        "FOOD COMMODITY GROUP": "BAKERY",
        "FOOD COMMODITY SUB-GROUP": "Breakfast cereals",
        "FOOD COMMODITY ITEM": "CORNFLAKES",
        "carbonFootprint": "3.28",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2196.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "367",
        "proteins": "6.6",
        "fat": "0.8",
        "saturatedFat": "0.09",
        "monounsaturatedFat": "0.2",
        "polyunsaturatedFat": "0.42",
        "carbohydrates": "87.4",
        "soluble": "10.4",
        "fiber": "2.8",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of carbohydrates with low environmental impact. Good source of energy. Medium intake of B vitamins and minerals. Low fat content. Recommended daily intake aroud 90 g per day and possibly whole grain bread for highest fiber and vitamin intake"
    },
    {
        "FOOD COMMODITY GROUP": "BAKERY",
        "FOOD COMMODITY SUB-GROUP": "Breakfast cereals",
        "FOOD COMMODITY ITEM": "OATMEAL FLAKES",
        "carbonFootprint": "1.30",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2416",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "367",
        "proteins": "8",
        "fat": "7.5",
        "saturatedFat": "1.28",
        "monounsaturatedFat": "2.28",
        "polyunsaturatedFat": "2.65",
        "carbohydrates": "66.8",
        "soluble": "0.6",
        "fiber": "8.3",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of carbohydrates with low environmental impact. Good source of energy. Medium intake of B vitamins and minerals. Low fat content. Recommended daily intake aroud 90 g per day and possibly whole grain bread for highest fiber and vitamin intake"
    },
    {
        "FOOD COMMODITY GROUP": "BAKERY",
        "FOOD COMMODITY SUB-GROUP": "Plain bread and crackers",
        "FOOD COMMODITY ITEM": "CRISPBREAD",
        "carbonFootprint": "1.39",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1405.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "387",
        "proteins": "11.3",
        "fat": "6",
        "saturatedFat": "0.83",
        "monounsaturatedFat": "1.47",
        "polyunsaturatedFat": "2.4",
        "carbohydrates": "75",
        "soluble": "2.2",
        "fiber": "3.5",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of carbohydrates with low environmental impact. Good source of energy. Medium intake of B vitamins and minerals. Low fat content. Recommended daily intake aroud 90 g per day and possibly whole grain bread for highest fiber and vitamin intake"
    },
    {
        "FOOD COMMODITY GROUP": "BAKERY",
        "FOOD COMMODITY SUB-GROUP": "Plain bread and crackers",
        "FOOD COMMODITY ITEM": "FLAVORED CRACKERS",
        "carbonFootprint": "0.92",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1378",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "443",
        "proteins": "9.4",
        "fat": "18",
        "saturatedFat": "4.19",
        "monounsaturatedFat": "4.55",
        "polyunsaturatedFat": "7.99",
        "carbohydrates": "63",
        "soluble": "5.6",
        "fiber": "3.8",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of carbohydrates with low environmental impact. Good source of energy. Medium intake of B vitamins and minerals. Low fat content. Recommended daily intake aroud 90 g per day and possibly whole grain bread for highest fiber and vitamin intake"
    },
    {
        "FOOD COMMODITY GROUP": "BAKERY",
        "FOOD COMMODITY SUB-GROUP": "Plain bread and crackers",
        "FOOD COMMODITY ITEM": "PLAIN CRACKERS",
        "carbonFootprint": "1.27",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1458",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "386",
        "proteins": "9.4",
        "fat": "10",
        "saturatedFat": "1.96",
        "monounsaturatedFat": "2.12",
        "polyunsaturatedFat": "5.17",
        "carbohydrates": "67.5",
        "soluble": "0",
        "fiber": "2.8",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of carbohydrates with low environmental impact. Good source of energy. Medium intake of B vitamins and minerals. Low fat content. Recommended daily intake aroud 90 g per day and possibly whole grain bread for highest fiber and vitamin intake"
    },
    {
        "FOOD COMMODITY GROUP": "BAKERY",
        "FOOD COMMODITY SUB-GROUP": "Whole bread and crackers",
        "FOOD COMMODITY ITEM": "WHOLEGRAIN CRACKERS",
        "carbonFootprint": "0.88",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "687",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "402",
        "proteins": "12",
        "fat": "11.4",
        "saturatedFat": "1.66",
        "monounsaturatedFat": "2.62",
        "polyunsaturatedFat": "5.6",
        "carbohydrates": "61.7",
        "soluble": "2.1",
        "fiber": "10",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of carbohydrates with low environmental impact. Good source of energy, fibers and vitamins, low fat content. Recommended daily intake aroud 90 g per day."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "GRAINS (Generic)",
        "carbonFootprint": "0.56",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1183.43",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "355.3",
        "proteins": "12.5",
        "fat": "5.8",
        "saturatedFat": "0.8",
        "monounsaturatedFat": "0.9",
        "polyunsaturatedFat": "3.4",
        "carbohydrates": "62.8",
        "soluble": "3.1",
        "fiber": "11.9",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "FLOOUR &STARCH (Generic)",
        "carbonFootprint": "0.63",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1930",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "348.7",
        "proteins": "8.0",
        "fat": "3.4",
        "saturatedFat": "1.3",
        "monounsaturatedFat": "0.8",
        "polyunsaturatedFat": "0.8",
        "carbohydrates": "74.1",
        "soluble": "5.0",
        "fiber": "4.1",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "BARLEY FLOUR",
        "carbonFootprint": "0.90",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1929.6",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "333",
        "proteins": "10.6",
        "fat": "1.9",
        "saturatedFat": "0.39",
        "monounsaturatedFat": "0.19",
        "polyunsaturatedFat": "1.05",
        "carbohydrates": "68.4",
        "soluble": "0.8",
        "fiber": "8.5",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "BUCKWHEAT FLOUR",
        "carbonFootprint": "1.96",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1929.6",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "326",
        "proteins": "12.6",
        "fat": "3.1",
        "saturatedFat": "0.6",
        "monounsaturatedFat": "1.03",
        "polyunsaturatedFat": "1.12",
        "carbohydrates": "60.6",
        "soluble": "2.6",
        "fiber": "10",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "DURUM WHEAT SEMOLINA",
        "carbonFootprint": "0.46",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1929.6",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "346",
        "proteins": "11.5",
        "fat": "0.5",
        "saturatedFat": "0.1",
        "monounsaturatedFat": "0.05",
        "polyunsaturatedFat": "0.2",
        "carbohydrates": "76.9",
        "soluble": "1.9",
        "fiber": "3.6",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "GRAHAM FLOUR",
        "carbonFootprint": "0.47",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1929.6",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "534",
        "proteins": "5.9",
        "fat": "32.1",
        "saturatedFat": "19.41",
        "monounsaturatedFat": "9.1",
        "polyunsaturatedFat": "1.01",
        "carbohydrates": "59",
        "soluble": "59",
        "fiber": "0.2",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "MAIZE FLOUR",
        "carbonFootprint": "0.95",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1167",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "341",
        "proteins": "8.7",
        "fat": "2.7",
        "saturatedFat": "0.31",
        "monounsaturatedFat": "0.66",
        "polyunsaturatedFat": "1.41",
        "carbohydrates": "73.5",
        "soluble": "1.5",
        "fiber": "3.1",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "MILLET FLOUR",
        "carbonFootprint": "1.20",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1929.6",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "357",
        "proteins": "10.8",
        "fat": "4.3",
        "saturatedFat": "0.54",
        "monounsaturatedFat": "0.92",
        "polyunsaturatedFat": "2.61",
        "carbohydrates": "71.6",
        "soluble": "1.7",
        "fiber": "3.5",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "OATMEAL FLOUR",
        "carbonFootprint": "0.87",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2536",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "378",
        "proteins": "12.6",
        "fat": "7.1",
        "saturatedFat": "1.46",
        "monounsaturatedFat": "2.53",
        "polyunsaturatedFat": "3.09",
        "carbohydrates": "66.3",
        "soluble": "1.2",
        "fiber": "7.6",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "RICE FLOUR",
        "carbonFootprint": "2.41",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2429",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "332",
        "proteins": "7.3",
        "fat": "0.5",
        "saturatedFat": "0.13",
        "monounsaturatedFat": "0.16",
        "polyunsaturatedFat": "0.23",
        "carbohydrates": "79.1",
        "soluble": "0",
        "fiber": "1",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "RYE FLOUR",
        "carbonFootprint": "0.79",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1930",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "342",
        "proteins": "11.7",
        "fat": "2",
        "saturatedFat": "0.24",
        "monounsaturatedFat": "0.25",
        "polyunsaturatedFat": "0.93",
        "carbohydrates": "67.8",
        "soluble": "0.1",
        "fiber": "11.3",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "SORGHUM FLOUR",
        "carbonFootprint": "1.33",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1929.6",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "375",
        "proteins": "8.3",
        "fat": "3.6",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "77.4",
        "soluble": "11.1",
        "fiber": "8.2",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "SPELT FLOUR",
        "carbonFootprint": "0.88",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1929.6",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "325",
        "proteins": "14.6",
        "fat": "2.4",
        "saturatedFat": "0.4",
        "monounsaturatedFat": "0.44",
        "polyunsaturatedFat": "1.26",
        "carbohydrates": "59.5",
        "soluble": "6.8",
        "fiber": "10.7",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "TAPIOCA",
        "carbonFootprint": "1.08",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1929.6",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "364",
        "proteins": "0.6",
        "fat": "0.2",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.05",
        "polyunsaturatedFat": "0.03",
        "carbohydrates": "95.8",
        "soluble": "0",
        "fiber": "0.4",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "WHEAT FLOUR",
        "carbonFootprint": "0.52",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1849",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "321",
        "proteins": "11.5",
        "fat": "1",
        "saturatedFat": "0.11",
        "monounsaturatedFat": "0.09",
        "polyunsaturatedFat": "0.38",
        "carbohydrates": "69.5",
        "soluble": "1.8",
        "fiber": "2.9",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "WHEAT PLAIN FLOUR",
        "carbonFootprint": "0.50",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1929.6",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "321",
        "proteins": "11.5",
        "fat": "1",
        "saturatedFat": "0.11",
        "monounsaturatedFat": "0.09",
        "polyunsaturatedFat": "0.38",
        "carbohydrates": "69.5",
        "soluble": "1.8",
        "fiber": "2.9",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "MAIZE STARCH",
        "carbonFootprint": "1.22",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1671",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "327",
        "proteins": "0.3",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "87",
        "soluble": "1.5",
        "fiber": "0",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "MANIOCA STARCH",
        "carbonFootprint": "1.00",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2254",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "327",
        "proteins": "0.3",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "87",
        "soluble": "1.5",
        "fiber": "0",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "OAT STARCH",
        "carbonFootprint": "0.29",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1591.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "300",
        "proteins": "11.7",
        "fat": "2.2",
        "saturatedFat": "0.64",
        "monounsaturatedFat": "0.51",
        "polyunsaturatedFat": "0.46",
        "carbohydrates": "60.9",
        "soluble": "1.5",
        "fiber": "2.4",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "POTATO STARCH",
        "carbonFootprint": "1.11",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1512",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "349",
        "proteins": "1.4",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "91.5",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Flour&Starch",
        "FOOD COMMODITY ITEM": "WHEAT STARCH",
        "carbonFootprint": "1.11",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1436",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "327",
        "proteins": "0.4",
        "fat": "0.1",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0.1",
        "carbohydrates": "85.8",
        "soluble": "0",
        "fiber": "1.2",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with medium amount of fiber, vitamins, and minerals. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "BARLEY",
        "carbonFootprint": "0.54",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "533",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "346",
        "proteins": "9.4",
        "fat": "1.5",
        "saturatedFat": "0.36",
        "monounsaturatedFat": "0.19",
        "polyunsaturatedFat": "0.85",
        "carbohydrates": "73.7",
        "soluble": "1.5",
        "fiber": "9.2",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "BUCKWHEAT",
        "carbonFootprint": "0.53",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "3142",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "329",
        "proteins": "12.4",
        "fat": "3.3",
        "saturatedFat": "0.64",
        "monounsaturatedFat": "1.1",
        "polyunsaturatedFat": "1.19",
        "carbohydrates": "61.2",
        "soluble": "0.3",
        "fiber": "10",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "BULGUR",
        "carbonFootprint": "0.61",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1183.43",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "320",
        "proteins": "12.3",
        "fat": "1.3",
        "saturatedFat": "0.23",
        "monounsaturatedFat": "0.17",
        "polyunsaturatedFat": "0.54",
        "carbohydrates": "62.4",
        "soluble": "0.4",
        "fiber": "12.5",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "CHIA",
        "carbonFootprint": "3.75",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1183.43",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "486",
        "proteins": "16.5",
        "fat": "30.7",
        "saturatedFat": "3.3",
        "monounsaturatedFat": "2.31",
        "polyunsaturatedFat": "23.7",
        "carbohydrates": "42.1",
        "soluble": "0",
        "fiber": "34.4",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "MAIZE",
        "carbonFootprint": "0.64",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "703.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "357",
        "proteins": "9.2",
        "fat": "3.8",
        "saturatedFat": "0.58",
        "monounsaturatedFat": "0.87",
        "polyunsaturatedFat": "1.67",
        "carbohydrates": "75.1",
        "soluble": "2.5",
        "fiber": "2",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "MILLET",
        "carbonFootprint": "0.98",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1434.4",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "343",
        "proteins": "11.8",
        "fat": "3.9",
        "saturatedFat": "0.49",
        "monounsaturatedFat": "0.85",
        "polyunsaturatedFat": "2.4",
        "carbohydrates": "64.9",
        "soluble": "4.7",
        "fiber": "8.5",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "OAT",
        "carbonFootprint": "0.71",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1788",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "360",
        "proteins": "16.9",
        "fat": "6.9",
        "saturatedFat": "1.42",
        "monounsaturatedFat": "2.46",
        "polyunsaturatedFat": "3",
        "carbohydrates": "55.7",
        "soluble": "0.2",
        "fiber": "10.6",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "QUINOA",
        "carbonFootprint": "0.97",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1183.43",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "376",
        "proteins": "15.4",
        "fat": "8.1",
        "saturatedFat": "0.93",
        "monounsaturatedFat": "2.14",
        "polyunsaturatedFat": "4.37",
        "carbohydrates": "57.8",
        "soluble": "5.3",
        "fiber": "12.2",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "RYE",
        "carbonFootprint": "0.43",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1554",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "314",
        "proteins": "10.3",
        "fat": "1.6",
        "saturatedFat": "0.19",
        "monounsaturatedFat": "0.21",
        "polyunsaturatedFat": "0.77",
        "carbohydrates": "60.8",
        "soluble": "1",
        "fiber": "15.1",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "SORGHUM",
        "carbonFootprint": "0.80",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1179.86",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "375",
        "proteins": "8.3",
        "fat": "3.6",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "77.4",
        "soluble": "11.1",
        "fiber": "8.2",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "SPELT",
        "carbonFootprint": "0.87",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1183.43",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "325",
        "proteins": "14.6",
        "fat": "2.4",
        "saturatedFat": "0.4",
        "monounsaturatedFat": "0.44",
        "polyunsaturatedFat": "1.26",
        "carbohydrates": "59.5",
        "soluble": "6.8",
        "fiber": "10.7",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "CEREALS",
        "FOOD COMMODITY SUB-GROUP": "Grains Whole",
        "FOOD COMMODITY ITEM": "WHEAT",
        "carbonFootprint": "0.53",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1357.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "332",
        "proteins": "13",
        "fat": "2.9",
        "saturatedFat": "0.48",
        "monounsaturatedFat": "0.36",
        "polyunsaturatedFat": "1.3",
        "carbohydrates": "62.5",
        "soluble": "3.2",
        "fiber": "9.8",
        "frequencyOfConsumption": "At least 1 time per day based on energy needed",
        "recommendation": "Choose whole grains and include >90 g/day in the diet",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of fiber, vitamins, and minerals. Very low environmental impact. Recommended frequency of consumption >90 g per day and preferred over other grains.  ."
    },
    {
        "FOOD COMMODITY GROUP": "COFFEE&COCOA&TEA&INFUSES",
        "FOOD COMMODITY SUB-GROUP": "COCOA (Powder)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "7.65",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "21711",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "411",
        "proteins": "20.4",
        "fat": "25.6",
        "saturatedFat": "12.02",
        "monounsaturatedFat": "6.8",
        "polyunsaturatedFat": "0.66",
        "carbohydrates": "11.5",
        "soluble": "0",
        "fiber": "27.7",
        "frequencyOfConsumption": "It is possible to drink all days without sugar addition",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "High caloric and fat intake. Modest amount of caffeine and antioxidant. Cocoa production has a high environmental impact and generally a fair low sustainability. Recommended to limit consumption and prefer low-fat cocoa for higher mineral and vitamin content and lower caloric and fat intake. "
    },
    {
        "FOOD COMMODITY GROUP": "COFFEE&COCOA&TEA&INFUSES",
        "FOOD COMMODITY SUB-GROUP": "COFFEE GROUND & PARCHMENT",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "4.90",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "19956",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "326",
        "proteins": "12.6",
        "fat": "3.1",
        "saturatedFat": "0.6",
        "monounsaturatedFat": "1.03",
        "polyunsaturatedFat": "1.12",
        "carbohydrates": "60.6",
        "soluble": "2.6",
        "fiber": "10",
        "frequencyOfConsumption": "It is possible to drink all days without sugar addition",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Coffee production has a generally medium to low sustainability. For this and health reason it is recommended to limit caffeine intake to 400 mg per day. "
    },
    {
        "FOOD COMMODITY GROUP": "COFFEE&COCOA&TEA&INFUSES",
        "FOOD COMMODITY SUB-GROUP": "COFFEE LIQUID",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.55",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "960",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "0",
        "proteins": "0",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "It is possible to drink all days without sugar addition",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Coffee production has a generally medium to low sustainability. For this and health reason it is recommended to limit caffeine intake to 400 mg per day. A single cup is a good compromise for sustainability and health"
    },
    {
        "FOOD COMMODITY GROUP": "COFFEE&COCOA&TEA&INFUSES",
        "FOOD COMMODITY SUB-GROUP": "TEA",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.05",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "8856",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "0",
        "proteins": "0",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "It is possible to drink all days without sugar addition",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Medium antioxidants and minerals content, with low-medium environmental impact. Suggested consumption on average 2-3 cups per day maximum. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Fresh Cheese",
        "FOOD COMMODITY ITEM": "FRESH CHEESE (Generic)",
        "carbonFootprint": "5.49",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4400",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "295.1",
        "proteins": "17.6",
        "fat": "25.4",
        "saturatedFat": "13.8",
        "monounsaturatedFat": "6.9",
        "polyunsaturatedFat": "0.8",
        "carbohydrates": "1.3",
        "soluble": "1.3",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Medium fat content and environmental impact depending on the cheese. Recommended daily portion around 100 g, within 2 times per week, prefere the cheeses with lower fat contant and impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Semi-Hard/Hard",
        "FOOD COMMODITY ITEM": "SEMI-HARD/HARD (Generic)",
        "carbonFootprint": "6.69",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "350.9",
        "proteins": "24.9",
        "fat": "27.4",
        "saturatedFat": "16.1",
        "monounsaturatedFat": "7.0",
        "polyunsaturatedFat": "0.7",
        "carbohydrates": "1.2",
        "soluble": "1.2",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Fat content and environmental impact from medium to high depending on the cheese. Recommended daily portion around 50 g, within 2 times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Fresh Cheese",
        "FOOD COMMODITY ITEM": "CHEESE BLUE-VEINED",
        "carbonFootprint": "6.70",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "321",
        "proteins": "19.3",
        "fat": "26.73",
        "saturatedFat": "15.09",
        "monounsaturatedFat": "7.31",
        "polyunsaturatedFat": "0.87",
        "carbohydrates": "1.75",
        "soluble": "1.75",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Medium fat content and environmental impact depending on the cheese. Recommended daily portion around 100 g, within 2 times per week, prefere the cheeses with lower fat contant and impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Fresh Cheese",
        "FOOD COMMODITY ITEM": "CHEESE FRESH",
        "carbonFootprint": "5.49",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5253",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "293",
        "proteins": "35.84",
        "fat": "24.8",
        "saturatedFat": "13.57",
        "monounsaturatedFat": "6.87",
        "polyunsaturatedFat": "0.78",
        "carbohydrates": "1.8",
        "soluble": "1.8",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Medium fat content and environmental impact depending on the cheese. Recommended daily portion around 100 g, within 2 times per week, prefere the cheeses with lower fat contant and impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Fresh Cheese",
        "FOOD COMMODITY ITEM": "COTTAGE CHEESE",
        "carbonFootprint": "3.47",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5253",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "115",
        "proteins": "9.7",
        "fat": "7.1",
        "saturatedFat": "4.24",
        "monounsaturatedFat": "2.25",
        "polyunsaturatedFat": "0.2",
        "carbohydrates": "3.2",
        "soluble": "3.2",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Medium fat content and environmental impact depending on the cheese. Recommended daily portion around 100 g, within 2 times per week, prefere the cheeses with lower fat contant and impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Fresh Cheese",
        "FOOD COMMODITY ITEM": "CREAM CHEESE",
        "carbonFootprint": "3.68",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "3838.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "313",
        "proteins": "8.6",
        "fat": "31",
        "saturatedFat": "18.52",
        "monounsaturatedFat": "9.87",
        "polyunsaturatedFat": "0.84",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Medium fat content and environmental impact depending on the cheese. Recommended daily portion around 100 g, within 2 times per week, prefere the cheeses with lower fat contant and impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Fresh Cheese",
        "FOOD COMMODITY ITEM": "MASCARPONE",
        "carbonFootprint": "5.70",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "6100",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "455",
        "proteins": "7.6",
        "fat": "47",
        "saturatedFat": "27.55",
        "monounsaturatedFat": "14.36",
        "polyunsaturatedFat": "1.57",
        "carbohydrates": "0.3",
        "soluble": "0.3",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Medium fat content and environmental impact depending on the cheese. Recommended daily portion around 100 g, within 2 times per week, prefere the cheeses with lower fat contant and impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Fresh Cheese",
        "FOOD COMMODITY ITEM": "MOZZARELLA",
        "carbonFootprint": "8.09",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "9410",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "288",
        "proteins": "16.7",
        "fat": "24.4",
        "saturatedFat": "4.87",
        "monounsaturatedFat": "1.89",
        "polyunsaturatedFat": "0.52",
        "carbohydrates": "0.4",
        "soluble": "0.4",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Medium fat content and environmental impact depending on the cheese. Recommended daily portion around 100 g, within 2 times per week, prefere the cheeses with lower fat contant and impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Fresh Cheese",
        "FOOD COMMODITY ITEM": "RICOTTA",
        "carbonFootprint": "3.40",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2470.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "212",
        "proteins": "10.5",
        "fat": "17.3",
        "saturatedFat": "10.82",
        "monounsaturatedFat": "4.38",
        "polyunsaturatedFat": "0.62",
        "carbohydrates": "3.7",
        "soluble": "3.7",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein, vitamins, and minerals. Lower fat concentration when compared to other fresh cheeses. Lower environmental impact compared to all the other cheese typologies"
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Fresh Cheese",
        "FOOD COMMODITY ITEM": "STRACCHINO",
        "carbonFootprint": "4.92",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5600",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "300",
        "proteins": "18.5",
        "fat": "25.1",
        "saturatedFat": "14.71",
        "monounsaturatedFat": "7.67",
        "polyunsaturatedFat": "0.84",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Medium fat content and environmental impact depending on the cheese. Recommended daily portion around 100 g, within 2 times per week, prefere the cheeses with lower fat contant and impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Fresh Cheese",
        "FOOD COMMODITY ITEM": "ASIAGO",
        "carbonFootprint": "10.10",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "359",
        "proteins": "31.4",
        "fat": "25.6",
        "saturatedFat": "15",
        "monounsaturatedFat": "7.82",
        "polyunsaturatedFat": "0.85",
        "carbohydrates": "0.8",
        "soluble": "0.8",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Medium fat content and environmental impact depending on the cheese. Recommended daily portion around 100 g, within 2 times per week, prefere the cheeses with lower fat contant and impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Semi-Hard/Hard",
        "FOOD COMMODITY ITEM": "CAMEMBERT CHEESE",
        "carbonFootprint": "5.49",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "297",
        "proteins": "20.9",
        "fat": "23.7",
        "saturatedFat": "14.91",
        "monounsaturatedFat": "6.86",
        "polyunsaturatedFat": "0.71",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Fat content and environmental impact from medium to high depending on the cheese. Recommended daily portion around 50 g, within 2 times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Semi-Hard/Hard",
        "FOOD COMMODITY ITEM": "CASIN",
        "carbonFootprint": "10.20",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "297",
        "proteins": "20.9",
        "fat": "23.7",
        "saturatedFat": "14.91",
        "monounsaturatedFat": "6.86",
        "polyunsaturatedFat": "0.71",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Fat content and environmental impact from medium to high depending on the cheese. Recommended daily portion around 50 g, within 2 times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Semi-Hard/Hard",
        "FOOD COMMODITY ITEM": "CHEDDAR CHEESE",
        "carbonFootprint": "11.37",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "381",
        "proteins": "25",
        "fat": "31",
        "saturatedFat": "18.52",
        "monounsaturatedFat": "8.52",
        "polyunsaturatedFat": "0.88",
        "carbohydrates": "0.5",
        "soluble": "0.5",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Fat content and environmental impact from medium to high depending on the cheese. Recommended daily portion around 50 g, within 2 times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Semi-Hard/Hard",
        "FOOD COMMODITY ITEM": "EMMENTAL",
        "carbonFootprint": "5.60",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "403",
        "proteins": "28.5",
        "fat": "30.6",
        "saturatedFat": "17.83",
        "monounsaturatedFat": "6.14",
        "polyunsaturatedFat": "0.83",
        "carbohydrates": "3.6",
        "soluble": "3.6",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Fat content and environmental impact from medium to high depending on the cheese. Recommended daily portion around 50 g, within 2 times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Semi-Hard/Hard",
        "FOOD COMMODITY ITEM": "FRANXON",
        "carbonFootprint": "10.20",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "403",
        "proteins": "28.5",
        "fat": "30.6",
        "saturatedFat": "17.83",
        "monounsaturatedFat": "6.14",
        "polyunsaturatedFat": "0.83",
        "carbohydrates": "3.6",
        "soluble": "3.6",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Fat content and environmental impact from medium to high depending on the cheese. Recommended daily portion around 50 g, within 2 times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Semi-Hard/Hard",
        "FOOD COMMODITY ITEM": "GOAT CHEESE",
        "carbonFootprint": "8.35",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "452",
        "proteins": "30.5",
        "fat": "35.6",
        "saturatedFat": "24.6",
        "monounsaturatedFat": "8.12",
        "polyunsaturatedFat": "0.84",
        "carbohydrates": "2.17",
        "soluble": "2.17",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Fat content and environmental impact from medium to high depending on the cheese. Recommended daily portion around 50 g, within 2 times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Semi-Hard/Hard",
        "FOOD COMMODITY ITEM": "GORGONZOLA",
        "carbonFootprint": "5.72",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "324",
        "proteins": "19.1",
        "fat": "27.1",
        "saturatedFat": "13.1",
        "monounsaturatedFat": "7.1",
        "polyunsaturatedFat": "0.73",
        "carbohydrates": "1",
        "soluble": "1",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Fat content and environmental impact from medium to high depending on the cheese. Recommended daily portion around 50 g, within 2 times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Semi-Hard/Hard",
        "FOOD COMMODITY ITEM": "GRANA PADANO",
        "carbonFootprint": "13.60",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "406",
        "proteins": "33.9",
        "fat": "28.5",
        "saturatedFat": "17.53",
        "monounsaturatedFat": "7.29",
        "polyunsaturatedFat": "0.78",
        "carbohydrates": "3.7",
        "soluble": "3.7",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Fat content and environmental impact from medium to high depending on the cheese. Recommended daily portion around 50 g, within 2 times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Semi-Hard/Hard",
        "FOOD COMMODITY ITEM": "PARMIGIANO REGGIANO",
        "carbonFootprint": "7.81",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "387",
        "proteins": "33.5",
        "fat": "28.1",
        "saturatedFat": "18.54",
        "monounsaturatedFat": "8.81",
        "polyunsaturatedFat": "0.79",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Fat content and environmental impact from medium to high depending on the cheese. Recommended daily portion around 50 g, within 2 times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Semi-Hard/Hard",
        "FOOD COMMODITY ITEM": "PECORINO",
        "carbonFootprint": "17.14",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "392",
        "proteins": "25.8",
        "fat": "32",
        "saturatedFat": "17.34",
        "monounsaturatedFat": "7.94",
        "polyunsaturatedFat": "0.6",
        "carbohydrates": "0.2",
        "soluble": "0.2",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Fat content and environmental impact from medium to high depending on the cheese. Recommended daily portion around 50 g, within 2 times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Semi-Hard/Hard",
        "FOOD COMMODITY ITEM": "SAN SIMON DA COSTA CHEESE",
        "carbonFootprint": "10.44",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5060",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "392",
        "proteins": "25.8",
        "fat": "32",
        "saturatedFat": "17.34",
        "monounsaturatedFat": "7.94",
        "polyunsaturatedFat": "0.6",
        "carbohydrates": "0.2",
        "soluble": "0.2",
        "fiber": "0",
        "frequencyOfConsumption": "Not more 3 times per week",
        "recommendation": "350-500 g/d of milk or yoghurt can be substituted for cheese (10g cheese = 100 g of the recommendation)",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Fat content and environmental impact from medium to high depending on the cheese. Recommended daily portion around 50 g, within 2 times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Milk&Yogurt",
        "FOOD COMMODITY ITEM": "BUFFALO MILK",
        "carbonFootprint": "3.14",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "1211",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "102",
        "proteins": "4.4",
        "fat": "7.3",
        "saturatedFat": "4.87",
        "monounsaturatedFat": "1.89",
        "polyunsaturatedFat": "0.15",
        "carbohydrates": "5.1",
        "soluble": "5.1",
        "fiber": "0",
        "frequencyOfConsumption": "At least 1 time per day, prefering fermented forms",
        "recommendation": "350-500 g/d of milk or yoghurt",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Intake of 350-500 g/day of low-fat dairy products  is recommended, better if fermented like yogurt. Milk and yougurt have good environmental impact. Prefer low-fat yoghurt and skimmed or semi-skimmed milk to whole milk to reduce fat intake. Use vegetable milk if you want to further reduce impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Milk&Yogurt",
        "FOOD COMMODITY ITEM": "COW MILK",
        "carbonFootprint": "1.34",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "1211",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "64",
        "proteins": "3.3",
        "fat": "3.6",
        "saturatedFat": "2.11",
        "monounsaturatedFat": "1.1",
        "polyunsaturatedFat": "0.12",
        "carbohydrates": "4.9",
        "soluble": "4.9",
        "fiber": "0",
        "frequencyOfConsumption": "At least 1 time per day, prefering fermented forms",
        "recommendation": "350-500 g/d of milk or yoghurt",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Intake of 350-500 g/day of low-fat dairy products  is recommended, better if fermented like yogurt. Milk and yougurt have good environmental impact. Prefer low-fat yoghurt and skimmed or semi-skimmed milk to whole milk to reduce fat intake. Use vegetable milk if you want to further reduce impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Milk&Yogurt",
        "FOOD COMMODITY ITEM": "GOAT MILK",
        "carbonFootprint": "1.63",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "1211",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "76",
        "proteins": "3.9",
        "fat": "4.8",
        "saturatedFat": "3.32",
        "monounsaturatedFat": "1.36",
        "polyunsaturatedFat": "0.16",
        "carbohydrates": "4.7",
        "soluble": "4.7",
        "fiber": "0",
        "frequencyOfConsumption": "At least 1 time per day, prefering fermented forms",
        "recommendation": "350-500 g/d of milk or yoghurt",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Intake of 350-500 g/day of low-fat dairy products  is recommended, better if fermented like yogurt. Milk and yougurt have good environmental impact. Prefer low-fat yoghurt and skimmed or semi-skimmed milk to whole milk to reduce fat intake. Use vegetable milk if you want to further reduce impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DAIRY",
        "FOOD COMMODITY SUB-GROUP": "Milk&Yogurt",
        "FOOD COMMODITY ITEM": "YOGURT",
        "carbonFootprint": "2.44",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "1390",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "66",
        "proteins": "3.8",
        "fat": "3.9",
        "saturatedFat": "2.07",
        "monounsaturatedFat": "0.84",
        "polyunsaturatedFat": "0.13",
        "carbohydrates": "4.3",
        "soluble": "4.3",
        "fiber": "0",
        "frequencyOfConsumption": "At least 1 time per day, prefering fermented forms",
        "recommendation": "350-500 g/d of milk or yoghurt",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of calcium, vitamins, and protein. Intake of 350-500 g/day of low-fat dairy products  is recommended, better if fermented like yogurt. Milk and yougurt have good environmental impact. Prefer low-fat yoghurt and skimmed or semi-skimmed milk to whole milk to reduce fat intake. Use vegetable milk if you want to further reduce impact. "
    },
    {
        "FOOD COMMODITY GROUP": "DRESSING",
        "FOOD COMMODITY SUB-GROUP": "KETCHUP",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.47",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "534",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "103",
        "proteins": "1.11",
        "fat": "0.55",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.04",
        "carbohydrates": "26.8",
        "soluble": "21.8",
        "fiber": "0.3",
        "frequencyOfConsumption": "Occasionally",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Usually they have high level of fat and sugar. It is recommended to limit intake despite the low environmental impact. Prefer fresh dressings with vegetable oils and spices"
    },
    {
        "FOOD COMMODITY GROUP": "DRESSING",
        "FOOD COMMODITY SUB-GROUP": "MAYONNAISE",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.12",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "613",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "655",
        "proteins": "4.2",
        "fat": "70",
        "saturatedFat": "8.82",
        "monounsaturatedFat": "23.7",
        "polyunsaturatedFat": "23.22",
        "carbohydrates": "2.2",
        "soluble": "2.2",
        "fiber": "0",
        "frequencyOfConsumption": "Occasionally",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Usually they have high level of fat and sugar. It is recommended to limit intake despite the low environmental impact. Prefer fresh dressings with vegetable oils and spices"
    },
    {
        "FOOD COMMODITY GROUP": "DRESSING",
        "FOOD COMMODITY SUB-GROUP": "MUSTARD",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.75",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "613",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "60",
        "proteins": "3.74",
        "fat": "3.34",
        "saturatedFat": "0.21",
        "monounsaturatedFat": "2.18",
        "polyunsaturatedFat": "0.77",
        "carbohydrates": "5.83",
        "soluble": "0.92",
        "fiber": "4",
        "frequencyOfConsumption": "Occasionally",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Usually they have high level of fat and sugar. It is recommended to limit intake despite the low environmental impact. Prefer fresh dressings with vegetable oils and spices"
    },
    {
        "FOOD COMMODITY GROUP": "DRESSING",
        "FOOD COMMODITY SUB-GROUP": "SOY SAUCE",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.98",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "46",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "60",
        "proteins": "10.5",
        "fat": "0.1",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.02",
        "polyunsaturatedFat": "0.04",
        "carbohydrates": "5.57",
        "soluble": "1.7",
        "fiber": "0.8",
        "frequencyOfConsumption": "Occasionally",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vegetable protein and modest amount of sodium. Moderate use is recommended. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "DRESSING",
        "FOOD COMMODITY SUB-GROUP": "VINEGAR",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.12",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "1612",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "4",
        "proteins": "0.4",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "0.6",
        "soluble": "0.6",
        "fiber": "0",
        "frequencyOfConsumption": "Occasionally",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Low-calorie food. Consumption of apple cider vinegar is recommended for its higher intake of vitamins and minerals. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "EGGS",
        "FOOD COMMODITY SUB-GROUP": "EGGS",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "3.19",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2920",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "128",
        "proteins": "12.4",
        "fat": "8.7",
        "saturatedFat": "3.17",
        "monounsaturatedFat": "2.58",
        "polyunsaturatedFat": "1.26",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Less than 1 time per day",
        "recommendation": "Up to 7/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. High in saturated fat and cholesterol. Recommended to consume up to a maximum of 7 eggs per week. Very good environmental score . Opt for organic or free-range eggs whenever possible.\n"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "FISH (generic)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "3.29",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1684.15",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "MODERATE/ LIMIT",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "123.1",
        "proteins": "18.2",
        "fat": "5.4",
        "saturatedFat": "1.8",
        "monounsaturatedFat": "1.6",
        "polyunsaturatedFat": "1.8",
        "carbohydrates": "0.3",
        "soluble": "0.3",
        "fiber": "0.0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low fat content. A total consumption of 450 g per week is recommended  in portions of at least 150 g per week, fatty fish is preferred being reach in omega 3. Alway alternate fish sources, consume local sources first and when possible buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "GLOBAL ANCHOVY (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.32",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "96",
        "proteins": "16.8",
        "fat": "2.6",
        "saturatedFat": "1.3",
        "monounsaturatedFat": "0.4",
        "polyunsaturatedFat": "0.85",
        "carbohydrates": "1.5",
        "soluble": "1.5",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "ATLANTIC ANCHOVY (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.32",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "96",
        "proteins": "16.8",
        "fat": "2.6",
        "saturatedFat": "1.3",
        "monounsaturatedFat": "0.4",
        "polyunsaturatedFat": "0.85",
        "carbohydrates": "1.5",
        "soluble": "1.5",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, always alternate fish sources, explore local biodiversity and choose certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "PACIFIC ANCHOVY (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.32",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "96",
        "proteins": "16.8",
        "fat": "2.6",
        "saturatedFat": "1.3",
        "monounsaturatedFat": "0.4",
        "polyunsaturatedFat": "0.85",
        "carbohydrates": "1.5",
        "soluble": "1.5",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, always alternate fish sources, explore local biodiversity and choose certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "MEDITERRANEAN ANCHOVY (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.32",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "96",
        "proteins": "16.8",
        "fat": "2.6",
        "saturatedFat": "1.3",
        "monounsaturatedFat": "0.4",
        "polyunsaturatedFat": "0.85",
        "carbohydrates": "1.5",
        "soluble": "1.5",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "CARP (F)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "5.26",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5706.9",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "OPTIMAL",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "140",
        "proteins": "18.9",
        "fat": "7.1",
        "saturatedFat": "1.37",
        "monounsaturatedFat": "2.95",
        "polyunsaturatedFat": "1.81",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible alternate fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "COD (F)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "3.1",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "6815.9",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "71",
        "proteins": "17",
        "fat": "0.3",
        "saturatedFat": "0.06",
        "monounsaturatedFat": "0.07",
        "polyunsaturatedFat": "0.12",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible alternate fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "COD (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.49",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "71",
        "proteins": "17",
        "fat": "0.3",
        "saturatedFat": "0.06",
        "monounsaturatedFat": "0.07",
        "polyunsaturatedFat": "0.12",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended, however, as this fish is under strong fishing pressure  consume once per week alternating with other fishes or buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "EEL (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.32",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "261",
        "proteins": "11.8",
        "fat": "23.7",
        "saturatedFat": "6.37",
        "monounsaturatedFat": "10.38",
        "polyunsaturatedFat": "5.51",
        "carbohydrates": "0.1",
        "soluble": "0.1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended, however, as this fish is under strong fishing pressure  consume once per week alternating with other fishes or buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "HADDOCK (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "3.26",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "74",
        "proteins": "16.3",
        "fat": "0.45",
        "saturatedFat": "0.09",
        "monounsaturatedFat": "0.06",
        "polyunsaturatedFat": "0.02",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible alternate fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "HAKE (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "8.23",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "71",
        "proteins": "17",
        "fat": "0.3",
        "saturatedFat": "0.7",
        "monounsaturatedFat": "0.08",
        "polyunsaturatedFat": "0.14",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended, however, as this fish is under strong fishing pressure  consume once per week alternating with other fishes or buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "HERRING (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.68",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "216",
        "proteins": "16.5",
        "fat": "16.7",
        "saturatedFat": "3.33",
        "monounsaturatedFat": "8.63",
        "polyunsaturatedFat": "2.61",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, always alternate fish sources, explore local biodiversity and choose certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "LING (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "3.0",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "87",
        "proteins": "19",
        "fat": "0.64",
        "saturatedFat": "0.12",
        "monounsaturatedFat": "0.09",
        "polyunsaturatedFat": "0.22",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible alternate fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "MACKEREL (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.7",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "170",
        "proteins": "17",
        "fat": "11.1",
        "saturatedFat": "2.61",
        "monounsaturatedFat": "4.13",
        "polyunsaturatedFat": "2.46",
        "carbohydrates": "0.5",
        "soluble": "0.5",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, always alternate fish sources, explore local biodiversity and choose certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "MEGRIM (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "21.67",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Red",
        "carbonFootprintBandingImpactDescription": "VERY HIGH",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "81",
        "proteins": "16.3",
        "fat": "1.3",
        "saturatedFat": "0.17",
        "monounsaturatedFat": "0.25",
        "polyunsaturatedFat": "0.73",
        "carbohydrates": "1.2",
        "soluble": "1.2",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended, however, as this fish is under strong fishing pressure  consume once per week alternating with other fishes or buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "PANGASIUS (F)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "8.93",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5021.985",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "OPTIMAL",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "71",
        "proteins": "17",
        "fat": "0.3",
        "saturatedFat": "0.7",
        "monounsaturatedFat": "0.08",
        "polyunsaturatedFat": "0.14",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended, however, as this fish is under strong fishing pressure  consume once per week alternating with other fishes or buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "POLLOCK (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.29",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "76",
        "proteins": "17.2",
        "fat": "0.82",
        "saturatedFat": "0.15",
        "monounsaturatedFat": "0.09",
        "polyunsaturatedFat": "0.26",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended, however, as this fish is under strong fishing pressure  consume once per week alternating with other fishes or buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "SAITHE (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.96",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "71",
        "proteins": "17",
        "fat": "0.3",
        "saturatedFat": "0.7",
        "monounsaturatedFat": "0.08",
        "polyunsaturatedFat": "0.14",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible alternate fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "SALMON (F)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "3.29",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4122",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "185",
        "proteins": "18.4",
        "fat": "12",
        "saturatedFat": "2.97",
        "monounsaturatedFat": "4.6",
        "polyunsaturatedFat": "3.05",
        "carbohydrates": "1",
        "soluble": "1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "SALMON (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "4.06",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "185",
        "proteins": "18.4",
        "fat": "12",
        "saturatedFat": "2.97",
        "monounsaturatedFat": "4.6",
        "polyunsaturatedFat": "3.05",
        "carbohydrates": "1",
        "soluble": "1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, always alternate fish sources, explore local biodiversity and choose certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "GLOBAL SARDINE (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.81",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "129",
        "proteins": "20.8",
        "fat": "4.5",
        "saturatedFat": "1.02",
        "monounsaturatedFat": "1.2",
        "polyunsaturatedFat": "1.77",
        "carbohydrates": "1.5",
        "soluble": "1.5",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "MEDITERRANEAN SARDINE (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.81",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "129",
        "proteins": "20.8",
        "fat": "4.5",
        "saturatedFat": "1.02",
        "monounsaturatedFat": "1.2",
        "polyunsaturatedFat": "1.77",
        "carbohydrates": "1.5",
        "soluble": "1.5",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "ATLANTIC SARDINE (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.81",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "129",
        "proteins": "20.8",
        "fat": "4.5",
        "saturatedFat": "1.02",
        "monounsaturatedFat": "1.2",
        "polyunsaturatedFat": "1.77",
        "carbohydrates": "1.5",
        "soluble": "1.5",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "PACIFIC SARDINE (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.81",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "129",
        "proteins": "20.8",
        "fat": "4.5",
        "saturatedFat": "1.02",
        "monounsaturatedFat": "1.2",
        "polyunsaturatedFat": "1.77",
        "carbohydrates": "1.5",
        "soluble": "1.5",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "SEABASS (F)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "5.68",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "17678.64",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "82",
        "proteins": "16.5",
        "fat": "1.5",
        "saturatedFat": "0.35",
        "monounsaturatedFat": "0.3",
        "polyunsaturatedFat": "0.4",
        "carbohydrates": "0.6",
        "soluble": "0.6",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible alternate fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "SEABREAM (F)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "5.89",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "20393.03",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "87",
        "proteins": "18.3",
        "fat": "1.2",
        "saturatedFat": "0.37",
        "monounsaturatedFat": "0.56",
        "polyunsaturatedFat": "0.26",
        "carbohydrates": "0.8",
        "soluble": "0.8",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended, however, as this fish is under strong fishing pressure  consume once per week alternating with other fishes or buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "SHARK (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "11.79",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "E",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "130",
        "proteins": "21",
        "fat": "4.51",
        "saturatedFat": "0.92",
        "monounsaturatedFat": "1.81",
        "polyunsaturatedFat": "1.2",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fish is an excellent source of protein and vitamins, with low fat content. However, shark fishing is quite impactful on the environment so we suggest to eat it with less frequency (less than once per week) or as a treat. "
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "SOLE (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "18.79",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "86",
        "proteins": "16.9",
        "fat": "1.7",
        "saturatedFat": "0.22",
        "monounsaturatedFat": "0.33",
        "polyunsaturatedFat": "0.96",
        "carbohydrates": "0.8",
        "soluble": "0.8",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible alternate fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "SPRAT (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.16",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "71",
        "proteins": "17",
        "fat": "0.3",
        "saturatedFat": "0.7",
        "monounsaturatedFat": "0.08",
        "polyunsaturatedFat": "0.14",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein, vitamins and omega 3.  A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "SWORDFISH (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "5.5",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "109",
        "proteins": "16.9",
        "fat": "4.2",
        "saturatedFat": "1.15",
        "monounsaturatedFat": "1.62",
        "polyunsaturatedFat": "0.96",
        "carbohydrates": "1",
        "soluble": "1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "TILAPIA (F)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "3.3",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "6014.81",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "96",
        "proteins": "20.1",
        "fat": "1.7",
        "saturatedFat": "0.58",
        "monounsaturatedFat": "0.5",
        "polyunsaturatedFat": "0.36",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible alternate fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "TROUT (F)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "4.18",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2964",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "86",
        "proteins": "14.7",
        "fat": "3",
        "saturatedFat": "0.7",
        "monounsaturatedFat": "0.77",
        "polyunsaturatedFat": "1.17",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein, vitamins and omega 3.  A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "TROUT (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "5.19",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "86",
        "proteins": "14.7",
        "fat": "3",
        "saturatedFat": "0.7",
        "monounsaturatedFat": "0.77",
        "polyunsaturatedFat": "1.17",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein, vitamins and omega 3.  A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "TUNA, ALBACORE (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "5.73",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "159",
        "proteins": "21.5",
        "fat": "8.1",
        "saturatedFat": "3.35",
        "monounsaturatedFat": "1.51",
        "polyunsaturatedFat": "3.2",
        "carbohydrates": "0.1",
        "soluble": "0.1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein, vitamins and omega 3.  A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "TUNA, BIGEYE (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.64",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "159",
        "proteins": "21.5",
        "fat": "8.1",
        "saturatedFat": "3.35",
        "monounsaturatedFat": "1.51",
        "polyunsaturatedFat": "3.2",
        "carbohydrates": "0.1",
        "soluble": "0.1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "GLOBAL TUNA, BLUEFIN (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.09",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "159",
        "proteins": "21.5",
        "fat": "8.1",
        "saturatedFat": "3.35",
        "monounsaturatedFat": "1.51",
        "polyunsaturatedFat": "3.2",
        "carbohydrates": "0.1",
        "soluble": "0.1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "ATLANTIC TUNA, BLUEFIN (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.09",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "159",
        "proteins": "21.5",
        "fat": "8.1",
        "saturatedFat": "3.35",
        "monounsaturatedFat": "1.51",
        "polyunsaturatedFat": "3.2",
        "carbohydrates": "0.1",
        "soluble": "0.1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "INDIAN TUNA, BLUEFIN (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.09",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "159",
        "proteins": "21.5",
        "fat": "8.1",
        "saturatedFat": "3.35",
        "monounsaturatedFat": "1.51",
        "polyunsaturatedFat": "3.2",
        "carbohydrates": "0.1",
        "soluble": "0.1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "MEDITERRANEAN TUNA, BLUEFIN (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.09",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "159",
        "proteins": "21.5",
        "fat": "8.1",
        "saturatedFat": "3.35",
        "monounsaturatedFat": "1.51",
        "polyunsaturatedFat": "3.2",
        "carbohydrates": "0.1",
        "soluble": "0.1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "PACIFIC TUNA, BLUEFIN (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.09",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "159",
        "proteins": "21.5",
        "fat": "8.1",
        "saturatedFat": "3.35",
        "monounsaturatedFat": "1.51",
        "polyunsaturatedFat": "3.2",
        "carbohydrates": "0.1",
        "soluble": "0.1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "TUNA, OTHER (F)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "12.6",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "6014.81",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "E",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "159",
        "proteins": "21.5",
        "fat": "8.1",
        "saturatedFat": "3.35",
        "monounsaturatedFat": "1.51",
        "polyunsaturatedFat": "3.2",
        "carbohydrates": "0.1",
        "soluble": "0.1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  Due to environmental pressures recommended frequency of consumption is once or less per week, alternating with other fish and buying certified fish when possible (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS). "
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "TUNA, OTHER (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "159",
        "proteins": "21.5",
        "fat": "8.1",
        "saturatedFat": "3.35",
        "monounsaturatedFat": "1.51",
        "polyunsaturatedFat": "3.2",
        "carbohydrates": "0.1",
        "soluble": "0.1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "TUNA, SKIPJACK (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.92",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "159",
        "proteins": "21.5",
        "fat": "8.1",
        "saturatedFat": "3.35",
        "monounsaturatedFat": "1.51",
        "polyunsaturatedFat": "3.2",
        "carbohydrates": "0.1",
        "soluble": "0.1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "TUNA, YELLOWFIN (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "3.35",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "FATTY",
        "energy": "159",
        "proteins": "21.5",
        "fat": "8.1",
        "saturatedFat": "3.35",
        "monounsaturatedFat": "1.51",
        "polyunsaturatedFat": "3.2",
        "carbohydrates": "0.1",
        "soluble": "0.1",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "This fatty fish is an excellent source of protein and vitamins and omega 3.  A consumption of 150 g, 2-3 times per week would be ideal. However, as this fish is under strong fishing pressure we encourage to alternate with other fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)."
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "TURBOT (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "19.4",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "81",
        "proteins": "16.3",
        "fat": "1.3",
        "saturatedFat": "0.17",
        "monounsaturatedFat": "0.25",
        "polyunsaturatedFat": "0.73",
        "carbohydrates": "1.2",
        "soluble": "1.2",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible alternate fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "WHITING (W)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.26",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "72",
        "proteins": "17.4",
        "fat": "0.3",
        "saturatedFat": "0.06",
        "monounsaturatedFat": "0.07",
        "polyunsaturatedFat": "0.12",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low fat content. A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. However, as this fish is under strong fishing pressure we encourage to consume it once per week alternating with other fishes and to buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FISH",
        "FOOD COMMODITY SUB-GROUP": "FRESHWATER CATFISH (F)",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.61",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "6014.81",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "LEAN",
        "energy": "92",
        "proteins": "15.5",
        "fat": "3.3",
        "saturatedFat": "0.84",
        "monounsaturatedFat": "0.98",
        "polyunsaturatedFat": "1.02",
        "carbohydrates": "0.2",
        "soluble": "0.2",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and vitamins. Low  fat content. A total consumption of 450 g per week is recommended  in portions of at least 150 g per week. When possible alternate fish sources and buy certified fish (like Marine Stewardship Council - MSC logo or Aquaculture Stewardship Council’s - ASC logo, Good Fish Guide by MCS)"
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Berries",
        "FOOD COMMODITY ITEM": "Berries (Generic)",
        "carbonFootprint": "0.84",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "413",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "45.1",
        "proteins": "1.0",
        "fat": "0.2",
        "saturatedFat": "0.0",
        "monounsaturatedFat": "0.0",
        "polyunsaturatedFat": "0.1",
        "carbohydrates": "8.3",
        "soluble": "8.3",
        "fiber": "4.0",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of group vitamins A, B1, B2 and C and antioxidants. Good source of fiber. It is recommended to consume a variety of different types of fruit in the total amount of 400 g per day. Prefer fresh fruit, in season, possibly local and with minimum packaging. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Citrus",
        "FOOD COMMODITY ITEM": "Citrus (Generic)",
        "carbonFootprint": "0.36",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "382.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "40.0",
        "proteins": "0.7",
        "fat": "0.1",
        "saturatedFat": "0.0",
        "monounsaturatedFat": "0.0",
        "polyunsaturatedFat": "0.0",
        "carbohydrates": "9.1",
        "soluble": "9.1",
        "fiber": "1.7",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score. Prefer fresh foot, in season, possibly local and with minimum packaging.  "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "Other Fruits (Generic)",
        "carbonFootprint": "0.36",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "421.9",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "86.7",
        "proteins": "0.8",
        "fat": "3.9",
        "saturatedFat": "0.5",
        "monounsaturatedFat": "1.0",
        "polyunsaturatedFat": "0.2",
        "carbohydrates": "9.4",
        "soluble": "9.4",
        "fiber": "2.4",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Tropical Fruits",
        "FOOD COMMODITY ITEM": "Tropical Fruits (Generic)",
        "carbonFootprint": "0.69",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "472.125",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "67.7",
        "proteins": "1.6",
        "fat": "2.3",
        "saturatedFat": "0.3",
        "monounsaturatedFat": "1.7",
        "polyunsaturatedFat": "0.2",
        "carbohydrates": "10.4",
        "soluble": "9.9",
        "fiber": "3.4",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "It contains an high amount of calories and sugars. Should be consumed with moderation."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Berries",
        "FOOD COMMODITY ITEM": "BLACKBERRY",
        "carbonFootprint": "0.52",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "413",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "42",
        "proteins": "1.3",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "8.1",
        "soluble": "8.1",
        "fiber": "3.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of group vitamins A, B1, B2 and C and antioxidants. Good source of fiber. It is recommended to consume a variety of different types of fruit in the total amount of 400 g per day. Prefer fresh fruit, in season, possibly local and with minimum packaging. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Berries",
        "FOOD COMMODITY ITEM": "BLUEBERRY",
        "carbonFootprint": "0.71",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "845",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "31",
        "proteins": "0.9",
        "fat": "0.2",
        "saturatedFat": "0.02",
        "monounsaturatedFat": "0.03",
        "polyunsaturatedFat": "0.09",
        "carbohydrates": "5.1",
        "soluble": "5.1",
        "fiber": "3.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of group vitamins A, B1, B2 and C and antioxidants. Good source of fiber. It is recommended to consume a variety of different types of fruit in the total amount of 400 g per day. Prefer fresh fruit, in season, possibly local and with minimum packaging. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Berries",
        "FOOD COMMODITY ITEM": "CRANBERRY",
        "carbonFootprint": "0.92",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "276",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "46",
        "proteins": "0.46",
        "fat": "0.13",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.02",
        "polyunsaturatedFat": "0.06",
        "carbohydrates": "12",
        "soluble": "4.27",
        "fiber": "3.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of group vitamins A, B1, B2 and C and antioxidants. Good source of fiber. It is recommended to consume a variety of different types of fruit in the total amount of 400 g per day. Prefer fresh fruit, in season, possibly local and with minimum packaging. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Berries",
        "FOOD COMMODITY ITEM": "CURRANT",
        "carbonFootprint": "0.84",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "499",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "44",
        "proteins": "0.9",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "6.6",
        "soluble": "6.6",
        "fiber": "7.8",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of group vitamins A, B1, B2 and C and antioxidants. Good source of fiber. It is recommended to consume a variety of different types of fruit in the total amount of 400 g per day. Prefer fresh fruit, in season, possibly local and with minimum packaging. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Berries",
        "FOOD COMMODITY ITEM": "GOOSEBERRY",
        "carbonFootprint": "1.19",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "526",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "44",
        "proteins": "0.88",
        "fat": "0.58",
        "saturatedFat": "0.04",
        "monounsaturatedFat": "0.05",
        "polyunsaturatedFat": "0.03",
        "carbohydrates": "10.2",
        "soluble": "10.2",
        "fiber": "4.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of group vitamins A, B1, B2 and C and antioxidants. Good source of fiber. It is recommended to consume a variety of different types of fruit in the total amount of 400 g per day. Prefer fresh fruit, in season, possibly local and with minimum packaging. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Berries",
        "FOOD COMMODITY ITEM": "LOGANBERRY",
        "carbonFootprint": "0.84",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "413",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "55",
        "proteins": "1.52",
        "fat": "0.31",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.03",
        "polyunsaturatedFat": "0.02",
        "carbohydrates": "13",
        "soluble": "7.7",
        "fiber": "5.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of group vitamins A, B1, B2 and C and antioxidants. Good source of fiber. It is recommended to consume a variety of different types of fruit in the total amount of 400 g per day. Prefer fresh fruit, in season, possibly local and with minimum packaging. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Berries",
        "FOOD COMMODITY ITEM": "RED BERRIES",
        "carbonFootprint": "0.90",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "413",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "36",
        "proteins": "1.1",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "4.4",
        "soluble": "4.4",
        "fiber": "7.4",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of group vitamins A, B1, B2 and C and antioxidants. Good source of fiber. It is recommended to consume a variety of different types of fruit in the total amount of 400 g per day. Prefer fresh fruit, in season, possibly local and with minimum packaging. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Berries",
        "FOOD COMMODITY ITEM": "MULBERRY",
        "carbonFootprint": "0.84",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "413",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "39",
        "proteins": "1.3",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "8.1",
        "soluble": "8.1",
        "fiber": "1.7",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of group vitamins A, B1, B2 and C and antioxidants. Good source of fiber. It is recommended to consume a variety of different types of fruit in the total amount of 400 g per day. Prefer fresh fruit, in season, possibly local and with minimum packaging. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Berries",
        "FOOD COMMODITY ITEM": "RASPBERRY",
        "carbonFootprint": "0.84",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "90",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "49",
        "proteins": "1",
        "fat": "0.6",
        "saturatedFat": "0.02",
        "monounsaturatedFat": "0.06",
        "polyunsaturatedFat": "0.35",
        "carbohydrates": "6.5",
        "soluble": "6.5",
        "fiber": "7.4",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of group vitamins A, B1, B2 and C and antioxidants. Good source of fiber. It is recommended to consume a variety of different types of fruit in the total amount of 400 g per day. Prefer fresh fruit, in season, possibly local and with minimum packaging. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Berries",
        "FOOD COMMODITY ITEM": "STRAWBERRY",
        "carbonFootprint": "0.67",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "170",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "30",
        "proteins": "0.9",
        "fat": "0.4",
        "saturatedFat": "0.02",
        "monounsaturatedFat": "0.06",
        "polyunsaturatedFat": "0.21",
        "carbohydrates": "5.3",
        "soluble": "5.3",
        "fiber": "1.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of group vitamins A, B1, B2 and C and antioxidants. Good source of fiber. It is recommended to consume a variety of different types of fruit in the total amount of 400 g per day. Prefer fresh fruit, in season, possibly local and with minimum packaging. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Citrus",
        "FOOD COMMODITY ITEM": "CITRUS",
        "carbonFootprint": "3.83",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "457.05",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "57",
        "proteins": "0.8",
        "fat": "0.2",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.04",
        "carbohydrates": "12.8",
        "soluble": "12.8",
        "fiber": "2.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score. Prefer fresh foot, in season, possibly local and with minimum packaging.  "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Citrus",
        "FOOD COMMODITY ITEM": "CLEMENTINE",
        "carbonFootprint": "0.46",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "748",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "57",
        "proteins": "0.8",
        "fat": "0.2",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.04",
        "carbohydrates": "12.8",
        "soluble": "12.8",
        "fiber": "2.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score. Prefer fresh foot, in season, possibly local and with minimum packaging.  "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Citrus",
        "FOOD COMMODITY ITEM": "GRAPEFRUIT",
        "carbonFootprint": "0.63",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "342",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "29",
        "proteins": "0.6",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "6.2",
        "soluble": "6.2",
        "fiber": "1.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score. Prefer fresh foot, in season, possibly local and with minimum packaging.  "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Citrus",
        "FOOD COMMODITY ITEM": "LEMON",
        "carbonFootprint": "0.58",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "15",
        "proteins": "0.6",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "2.3",
        "soluble": "2.3",
        "fiber": "1.9",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score. Prefer fresh foot, in season, possibly local and with minimum packaging.  "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Citrus",
        "FOOD COMMODITY ITEM": "MANDARIN",
        "carbonFootprint": "0.45",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "263",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "76",
        "proteins": "0.9",
        "fat": "0.3",
        "saturatedFat": "0.04",
        "monounsaturatedFat": "0.06",
        "polyunsaturatedFat": "0.06",
        "carbohydrates": "17.6",
        "soluble": "17.6",
        "fiber": "1.7",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score. Prefer fresh foot, in season, possibly local and with minimum packaging.  "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Citrus",
        "FOOD COMMODITY ITEM": "ORANGE",
        "carbonFootprint": "0.30",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "445",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "37",
        "proteins": "0.7",
        "fat": "0.2",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.04",
        "carbohydrates": "7.8",
        "soluble": "7.8",
        "fiber": "1.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score. Prefer fresh foot, in season, possibly local and with minimum packaging.  "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Citrus",
        "FOOD COMMODITY ITEM": "LIME",
        "carbonFootprint": "0.26",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "382.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "45",
        "proteins": "0.7",
        "fat": "0.3",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.03",
        "polyunsaturatedFat": "0.08",
        "carbohydrates": "8.8",
        "soluble": "8.8",
        "fiber": "3.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score. Prefer fresh foot, in season, possibly local and with minimum packaging.  "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Citrus",
        "FOOD COMMODITY ITEM": "POMELO",
        "carbonFootprint": "0.55",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "382.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "38",
        "proteins": "0.7",
        "fat": "0.04",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "9.6",
        "soluble": "9.6",
        "fiber": "1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score. Prefer fresh foot, in season, possibly local and with minimum packaging.  "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Citrus",
        "FOOD COMMODITY ITEM": "TAMARIND",
        "carbonFootprint": "0.46",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "382.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "239",
        "proteins": "2.8",
        "fat": "0.6",
        "saturatedFat": "0.04",
        "monounsaturatedFat": "0.06",
        "polyunsaturatedFat": "0.06",
        "carbohydrates": "62.5",
        "soluble": "38.8",
        "fiber": "5.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score. Prefer fresh foot, in season, possibly local and with minimum packaging.  "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Citrus",
        "FOOD COMMODITY ITEM": "TANGERIN",
        "carbonFootprint": "0.39",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "382.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "57",
        "proteins": "0.8",
        "fat": "0.2",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.04",
        "carbohydrates": "12.8",
        "soluble": "12.8",
        "fiber": "2.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score. Prefer fresh foot, in season, possibly local and with minimum packaging.  "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Dried Fruits",
        "FOOD COMMODITY ITEM": "DATE",
        "carbonFootprint": "0.60",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "3400",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "128",
        "proteins": "1.5",
        "fat": "0.1",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.01",
        "carbohydrates": "31.3",
        "soluble": "31.3",
        "fiber": "1.8",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in energy and sugar. Consume in moderation. Very good environmental score. Prefer fresh fruit, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Dried Fruits",
        "FOOD COMMODITY ITEM": "FIG",
        "carbonFootprint": "1.69",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2916.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "51",
        "proteins": "0.9",
        "fat": "0.2",
        "saturatedFat": "0.04",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.1",
        "carbohydrates": "11.2",
        "soluble": "11.2",
        "fiber": "2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in energy and sugar. Consume in moderation. Very good environmental score. Prefer fresh fruit, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Dried Fruits",
        "FOOD COMMODITY ITEM": "RAISIN",
        "carbonFootprint": "0.90",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2433",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "293",
        "proteins": "1.9",
        "fat": "0.6",
        "saturatedFat": "0.08",
        "monounsaturatedFat": "0.07",
        "polyunsaturatedFat": "0.05",
        "carbohydrates": "72",
        "soluble": "72",
        "fiber": "5.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in energy and sugar. Consume in moderation. Very good environmental score. Prefer fresh fruit, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "APPLE",
        "carbonFootprint": "0.28",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "232.45",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "44",
        "proteins": "0.2",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "10",
        "soluble": "10",
        "fiber": "2.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "APRICOT",
        "carbonFootprint": "0.55",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "976.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "31",
        "proteins": "0.4",
        "fat": "0.1",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.02",
        "carbohydrates": "6.8",
        "soluble": "6.8",
        "fiber": "1.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "CHERRY",
        "carbonFootprint": "0.52",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1507.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "40",
        "proteins": "0.8",
        "fat": "0.1",
        "saturatedFat": "0.02",
        "monounsaturatedFat": "0.02",
        "polyunsaturatedFat": "0.03",
        "carbohydrates": "9",
        "soluble": "9",
        "fiber": "1.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "COCONUT",
        "carbonFootprint": "2.94",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2687",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "358",
        "proteins": "3.5",
        "fat": "35",
        "saturatedFat": "30.93",
        "monounsaturatedFat": "2.38",
        "polyunsaturatedFat": "0.61",
        "carbohydrates": "4.4",
        "soluble": "4.4",
        "fiber": "6.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "FIG",
        "carbonFootprint": "0.28",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "3350",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "51",
        "proteins": "0.9",
        "fat": "0.2",
        "saturatedFat": "0.04",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.1",
        "carbohydrates": "11.2",
        "soluble": "11.2",
        "fiber": "2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "GRAPES",
        "carbonFootprint": "0.32",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "608",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "64",
        "proteins": "0.5",
        "fat": "0.1",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0.03",
        "carbohydrates": "15.6",
        "soluble": "15.6",
        "fiber": "1.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "MELON",
        "carbonFootprint": "0.71",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "186",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "34",
        "proteins": "0.8",
        "fat": "0.02",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0.09",
        "carbohydrates": "7.4",
        "soluble": "7.4",
        "fiber": "0.7",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "OLIVES",
        "carbonFootprint": "0.63",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "480.46",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "151",
        "proteins": "0.8",
        "fat": "0.2",
        "saturatedFat": "2.1",
        "monounsaturatedFat": "10.47",
        "polyunsaturatedFat": "1.68",
        "carbohydrates": "1",
        "soluble": "1",
        "fiber": "4.4",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "PEACH",
        "carbonFootprint": "0.50",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "398.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "30",
        "proteins": "0.8",
        "fat": "0.1",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.03",
        "polyunsaturatedFat": "0.03",
        "carbohydrates": "6.1",
        "soluble": "6.1",
        "fiber": "1.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "PEAR",
        "carbonFootprint": "0.26",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "473.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "43",
        "proteins": "0.3",
        "fat": "0.1",
        "saturatedFat": "0.02",
        "monounsaturatedFat": "0.06",
        "polyunsaturatedFat": "0.07",
        "carbohydrates": "8.8",
        "soluble": "8.8",
        "fiber": "3.8",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "PLUM",
        "carbonFootprint": "0.36",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1243",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "45",
        "proteins": "0.5",
        "fat": "0.1",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.05",
        "polyunsaturatedFat": "0.02",
        "carbohydrates": "10.5",
        "soluble": "10.5",
        "fiber": "1.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "POMEGRANATE",
        "carbonFootprint": "0.43",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "322",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "68",
        "proteins": "0.5",
        "fat": "0.2",
        "saturatedFat": "0.02",
        "monounsaturatedFat": "0.02",
        "polyunsaturatedFat": "0.01",
        "carbohydrates": "15.9",
        "soluble": "15.9",
        "fiber": "2.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "PERSIMMON",
        "carbonFootprint": "0.90",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "421.9",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "70",
        "proteins": "0.6",
        "fat": "0.3",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.06",
        "polyunsaturatedFat": "0.07",
        "carbohydrates": "16",
        "soluble": "16",
        "fiber": "2.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Low in fat and calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day.  Generally very low or low environmental impact. Prefer fresh foot, in season, possibly local and with minimum packaging. "
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Tropical Fruits",
        "FOOD COMMODITY ITEM": "AVOCADO",
        "carbonFootprint": "0.84",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1437",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "238",
        "proteins": "4.4",
        "fat": "23",
        "saturatedFat": "2.48",
        "monounsaturatedFat": "18.33",
        "polyunsaturatedFat": "1.45",
        "carbohydrates": "1.8",
        "soluble": "1.8",
        "fiber": "3.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of monounsaturated fat but high in calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Good environmental scores."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Tropical Fruits",
        "FOOD COMMODITY ITEM": "BANANA",
        "carbonFootprint": "0.70",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "359.24",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "69",
        "proteins": "1.2",
        "fat": "0.3",
        "saturatedFat": "0.11",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.09",
        "carbohydrates": "15.4",
        "soluble": "12.8",
        "fiber": "1.8",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Usually a reasonable amount of calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Tropical Fruits",
        "FOOD COMMODITY ITEM": "DATE",
        "carbonFootprint": "3.94",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2618.3",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "128",
        "proteins": "1.5",
        "fat": "0.1",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.01",
        "carbohydrates": "31.3",
        "soluble": "31.3",
        "fiber": "1.8",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "It contains an high amount of calories and sugars. Should be consumed with moderation."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Tropical Fruits",
        "FOOD COMMODITY ITEM": "GUAVA",
        "carbonFootprint": "0.72",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1800",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "31",
        "proteins": "0.7",
        "fat": "0.5",
        "saturatedFat": "0.14",
        "monounsaturatedFat": "0.05",
        "polyunsaturatedFat": "0.21",
        "carbohydrates": "3.5",
        "soluble": "3.5",
        "fiber": "5.4",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Usually a reasonable amount of calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Other Fruits",
        "FOOD COMMODITY ITEM": "KIWI",
        "carbonFootprint": "0.55",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "514",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "48",
        "proteins": "1.2",
        "fat": "0.6",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.05",
        "polyunsaturatedFat": "0.33",
        "carbohydrates": "9",
        "soluble": "9",
        "fiber": "2.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Usually a reasonable amount of calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Tropical Fruits",
        "FOOD COMMODITY ITEM": "BAMBOO",
        "carbonFootprint": "1.83",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "472.125",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "27",
        "proteins": "2.6",
        "fat": "0.3",
        "saturatedFat": "0.07",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.13",
        "carbohydrates": "5.2",
        "soluble": "3",
        "fiber": "2.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Usually a reasonable amount of calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Tropical Fruits",
        "FOOD COMMODITY ITEM": "MANGO",
        "carbonFootprint": "0.35",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1800",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "56",
        "proteins": "1",
        "fat": "0.2",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.07",
        "polyunsaturatedFat": "0.04",
        "carbohydrates": "12.6",
        "soluble": "12.6",
        "fiber": "1.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Usually a reasonable amount of calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Tropical Fruits",
        "FOOD COMMODITY ITEM": "PAPAYA",
        "carbonFootprint": "0.86",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "436.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "33",
        "proteins": "0.4",
        "fat": "0.1",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.03",
        "polyunsaturatedFat": "0.02",
        "carbohydrates": "6.9",
        "soluble": "6.9",
        "fiber": "2.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Usually a reasonable amount of calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Tropical Fruits",
        "FOOD COMMODITY ITEM": "PINEAPPLE",
        "carbonFootprint": "0.67",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "195.45",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "42",
        "proteins": "0.5",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "10",
        "soluble": "10",
        "fiber": "1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Usually a reasonable amount of calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Tropical Fruits",
        "FOOD COMMODITY ITEM": "PLANTAIN BANANA",
        "carbonFootprint": "1.33",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1602",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "69",
        "proteins": "1.2",
        "fat": "0.3",
        "saturatedFat": "0.11",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.09",
        "carbohydrates": "15.4",
        "soluble": "12.8",
        "fiber": "1.8",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Usually a reasonable amount of calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "FRUIT",
        "FOOD COMMODITY SUB-GROUP": "Tropical Fruits",
        "FOOD COMMODITY ITEM": "PASSION FRUIT",
        "carbonFootprint": "0.91",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "472.125",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "64",
        "proteins": "3",
        "fat": "0.3",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.18",
        "carbohydrates": "5.7",
        "soluble": "5.7",
        "fiber": "13.9",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "200-400 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vitamins and minerals. Usually a reasonable amount of calories. It is recommended to consume a variety of different types of fruits in the amount of 400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes dry",
        "FOOD COMMODITY ITEM": "Legumes dry (generic)",
        "carbonFootprint": "0.45",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4055",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "338.7",
        "proteins": "22.2",
        "fat": "4.5",
        "saturatedFat": "0.6",
        "monounsaturatedFat": "0.9",
        "polyunsaturatedFat": "0.8",
        "carbohydrates": "47.9",
        "soluble": "11.1",
        "fiber": "15.0",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for dry legumes around 50 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes fresh",
        "FOOD COMMODITY ITEM": "Legumes fresh (generic)",
        "carbonFootprint": "0.58",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2077.25",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "51.9",
        "proteins": "8.5",
        "fat": "0.9",
        "saturatedFat": "0.3",
        "monounsaturatedFat": "0.2",
        "polyunsaturatedFat": "0.1",
        "carbohydrates": "18.1",
        "soluble": "1.6",
        "fiber": "9.0",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for fresh legumes around 125 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes frozen",
        "FOOD COMMODITY ITEM": "Legumes frozen (generic)",
        "carbonFootprint": "0.90",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "850",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "69.3",
        "proteins": "5.2",
        "fat": "0.7",
        "saturatedFat": "0.2",
        "monounsaturatedFat": "0.2",
        "polyunsaturatedFat": "0.1",
        "carbohydrates": "6.6",
        "soluble": "1.7",
        "fiber": "8.7",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for fresh legumes around 125 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes Flour",
        "FOOD COMMODITY ITEM": "AZUKI BEAN FLOUR",
        "carbonFootprint": "1.32",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2523",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "345",
        "proteins": "23.6",
        "fat": "2.5",
        "saturatedFat": "0.5",
        "monounsaturatedFat": "0.19",
        "polyunsaturatedFat": "1.2",
        "carbohydrates": "51.7",
        "soluble": "4",
        "fiber": "17",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for dry legumes around 50 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes Flour",
        "FOOD COMMODITY ITEM": "CHICKPEA FLOUR",
        "carbonFootprint": "0.80",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2523",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "331",
        "proteins": "11.4",
        "fat": "3",
        "saturatedFat": "0.38",
        "monounsaturatedFat": "0.29",
        "polyunsaturatedFat": "1.21",
        "carbohydrates": "64",
        "soluble": "1.3",
        "fiber": "9.3",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for dry legumes around 50 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes Flour",
        "FOOD COMMODITY ITEM": "SOY BEAN FLOUR",
        "carbonFootprint": "4.47",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2523",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "364",
        "proteins": "0.6",
        "fat": "0.2",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.05",
        "polyunsaturatedFat": "0",
        "carbohydrates": "95.8",
        "soluble": "0",
        "fiber": "0.4",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for dry legumes around 50 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes dry",
        "FOOD COMMODITY ITEM": "BEAN (d)",
        "carbonFootprint": "0.42",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5053",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "345",
        "proteins": "23.6",
        "fat": "2.5",
        "saturatedFat": "0.5",
        "monounsaturatedFat": "0.19",
        "polyunsaturatedFat": "1.2",
        "carbohydrates": "51.7",
        "soluble": "4",
        "fiber": "17",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for dry legumes around 50 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes fresh",
        "FOOD COMMODITY ITEM": "BEAN (f)",
        "carbonFootprint": "1.48",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1748.54",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "125",
        "proteins": "6.4",
        "fat": "0.6",
        "saturatedFat": "0.12",
        "monounsaturatedFat": "0.05",
        "polyunsaturatedFat": "0.29",
        "carbohydrates": "19.4",
        "soluble": "1.3",
        "fiber": "10.6",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for fresh legumes around 125 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes dry",
        "FOOD COMMODITY ITEM": "CAROB (d)",
        "carbonFootprint": "1.10",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5594",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "253",
        "proteins": "3.3",
        "fat": "0.7",
        "saturatedFat": "0.1",
        "monounsaturatedFat": "0.21",
        "polyunsaturatedFat": "0.23",
        "carbohydrates": "49.9",
        "soluble": "48.9",
        "fiber": "23.1",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for dry legumes around 50 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes dry",
        "FOOD COMMODITY ITEM": "CHICKPEA (d)",
        "carbonFootprint": "0.58",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4177",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "363",
        "proteins": "21.8",
        "fat": "4.9",
        "saturatedFat": "0.63",
        "monounsaturatedFat": "1.36",
        "polyunsaturatedFat": "2.69",
        "carbohydrates": "54.3",
        "soluble": "3.7",
        "fiber": "13.8",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for dry legumes around 50 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes dry",
        "FOOD COMMODITY ITEM": "COWPEA (d)",
        "carbonFootprint": "0.45",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "6906",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "318",
        "proteins": "22.4",
        "fat": "1.4",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "50.9",
        "soluble": "4",
        "fiber": "12.7",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for dry legumes around 50 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes fresh",
        "FOOD COMMODITY ITEM": "FABA BEAN (f)",
        "carbonFootprint": "0.62",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2077.25",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "58",
        "proteins": "4.1",
        "fat": "0.5",
        "saturatedFat": "0.1",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0",
        "carbohydrates": "6.3",
        "soluble": "1.1",
        "fiber": "6.8",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for fresh legumes around 125 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes dry",
        "FOOD COMMODITY ITEM": "LENTIL (d)",
        "carbonFootprint": "0.32",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "5874",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "352",
        "proteins": "25",
        "fat": "2.5",
        "saturatedFat": "0.24",
        "monounsaturatedFat": "0.42",
        "polyunsaturatedFat": "1.17",
        "carbohydrates": "54",
        "soluble": "2.4",
        "fiber": "13.7",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for dry legumes around 50 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes dry",
        "FOOD COMMODITY ITEM": "PEA (d)",
        "carbonFootprint": "0.47",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1979",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "337",
        "proteins": "21.7",
        "fat": "2",
        "saturatedFat": "0.74",
        "monounsaturatedFat": "0.63",
        "polyunsaturatedFat": "0.22",
        "carbohydrates": "53.6",
        "soluble": "2.9",
        "fiber": "15.7",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for dry legumes around 50 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes fresh",
        "FOOD COMMODITY ITEM": "PEA (f)",
        "carbonFootprint": "0.39",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "595",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "87",
        "proteins": "7",
        "fat": "0.2",
        "saturatedFat": "0.08",
        "monounsaturatedFat": "0.06",
        "polyunsaturatedFat": "0.02",
        "carbohydrates": "12.4",
        "soluble": "4.4",
        "fiber": "5.2",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for fresh legumes around 125 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes frozen",
        "FOOD COMMODITY ITEM": "BEAN (F)",
        "carbonFootprint": "1.00",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1748.54",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "75",
        "proteins": "5.8",
        "fat": "0.8",
        "saturatedFat": "0.29",
        "monounsaturatedFat": "0.24",
        "polyunsaturatedFat": "0.08",
        "carbohydrates": "6.8",
        "soluble": "2",
        "fiber": "9.7",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for fresh legumes around 125 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes frozen",
        "FOOD COMMODITY ITEM": "PEA (F)",
        "carbonFootprint": "0.95",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "595",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "87",
        "proteins": "7",
        "fat": "0.2",
        "saturatedFat": "0.08",
        "monounsaturatedFat": "0.06",
        "polyunsaturatedFat": "0.02",
        "carbohydrates": "12.4",
        "soluble": "4.4",
        "fiber": "5.2",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for fresh legumes around 125 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes dry",
        "FOOD COMMODITY ITEM": "SOYBEAN (d)",
        "carbonFootprint": "0.38",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1816.4",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "422",
        "proteins": "36.9",
        "fat": "18.1",
        "saturatedFat": "2.54",
        "monounsaturatedFat": "4.12",
        "polyunsaturatedFat": "0",
        "carbohydrates": "23.3",
        "soluble": "11",
        "fiber": "11.9",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for dry legumes around 50 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes frozen",
        "FOOD COMMODITY ITEM": "FABA BEAN (F)",
        "carbonFootprint": "0.48",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2077.25",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "58",
        "proteins": "4.1",
        "fat": "0.5",
        "saturatedFat": "0.1",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0",
        "carbohydrates": "6.3",
        "soluble": "1.1",
        "fiber": "6.8",
        "frequencyOfConsumption": "At least 3 times per week",
        "recommendation": "150-400 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber, low in fat content. Togheter with other high protein foods (fish and poultry), they represent an excellent alternative to red meat due to a much lower environmental impact. Suggested portion for fresh legumes around 125 g, can be consumed many times per week. "
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "BEEF ",
        "carbonFootprint": "24.05",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "14040",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Red",
        "carbonFootprintBandingImpactDescription": "VERY HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "E",
        "FATTY_or_LEAN_fish": "",
        "energy": "140",
        "proteins": "21.3",
        "fat": "6.1",
        "saturatedFat": "2.36",
        "monounsaturatedFat": "2.31",
        "polyunsaturatedFat": "1.41",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein and iron. It is recommended to limit red meat consumption < 150 g per week due to scientific evidence on higher risk of cancer and cardiovascular disease and high to very high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "VEAL ",
        "carbonFootprint": "19.37",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "14040",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "144",
        "proteins": "20.3",
        "fat": "7",
        "saturatedFat": "2.96",
        "monounsaturatedFat": "3.34",
        "polyunsaturatedFat": "0.29",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein and iron. It is recommended to limit red meat consumption < 150 g per week due to scientific evidence on higher risk of cancer and cardiovascular disease and high to very high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "BUFFALO",
        "carbonFootprint": "78.80",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "14040",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Red",
        "carbonFootprintBandingImpactDescription": "VERY HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "E",
        "FATTY_or_LEAN_fish": "",
        "energy": "98",
        "proteins": "22",
        "fat": "1.1",
        "saturatedFat": "0.4",
        "monounsaturatedFat": "0.34",
        "polyunsaturatedFat": "0.36",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein and iron. It is recommended to limit red meat consumption < 150 g per week due to scientific evidence on higher risk of cancer and cardiovascular disease and high to very high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "ASS ",
        "carbonFootprint": "17.67",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "50837.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "106",
        "proteins": "23.5",
        "fat": "1",
        "saturatedFat": "0.34",
        "monounsaturatedFat": "0.15",
        "polyunsaturatedFat": "0.51",
        "carbohydrates": "0.7",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein and iron. It is recommended to limit red meat consumption < 150 g per week due to scientific evidence on higher risk of cancer and cardiovascular disease and high to very high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "HINNY ",
        "carbonFootprint": "17.67",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "50837.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "143",
        "proteins": "19.8",
        "fat": "6.8",
        "saturatedFat": "2.37",
        "monounsaturatedFat": "3.18",
        "polyunsaturatedFat": "1.27",
        "carbohydrates": "0.6",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein and iron. It is recommended to limit red meat consumption < 150 g per week due to scientific evidence on higher risk of cancer and cardiovascular disease and high to very high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "HORSE",
        "carbonFootprint": "17.67",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "41804",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "145",
        "proteins": "19.8",
        "fat": "6.6",
        "saturatedFat": "2.01",
        "monounsaturatedFat": "2.68",
        "polyunsaturatedFat": "1.09",
        "carbohydrates": "1.7",
        "soluble": "1.7",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein and iron. It is recommended to limit red meat consumption < 150 g per week due to scientific evidence on higher risk of cancer and cardiovascular disease and high to very high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "MULE ",
        "carbonFootprint": "17.67",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "50837.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "143",
        "proteins": "19.8",
        "fat": "6.8",
        "saturatedFat": "2.37",
        "monounsaturatedFat": "3.18",
        "polyunsaturatedFat": "1.27",
        "carbohydrates": "0.6",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein and iron. It is recommended to limit red meat consumption < 150 g per week due to scientific evidence on higher risk of cancer and cardiovascular disease and high to very high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "GOAT ",
        "carbonFootprint": "49.58",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4160",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Red",
        "carbonFootprintBandingImpactDescription": "VERY HIGH",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "E",
        "FATTY_or_LEAN_fish": "",
        "energy": "109",
        "proteins": "20.6",
        "fat": "2.31",
        "saturatedFat": "0.71",
        "monounsaturatedFat": "1.03",
        "polyunsaturatedFat": "0.17",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein and iron. It is recommended to limit red meat consumption < 150 g per week due to scientific evidence on higher risk of cancer and cardiovascular disease and high to very high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "LAMB",
        "carbonFootprint": "25.88",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "10412",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Red",
        "carbonFootprintBandingImpactDescription": "VERY HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "E",
        "FATTY_or_LEAN_fish": "",
        "energy": "159",
        "proteins": "20",
        "fat": "8.8",
        "saturatedFat": "4.28",
        "monounsaturatedFat": "3.33",
        "polyunsaturatedFat": "0.42",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein and iron. It is recommended to limit red meat consumption < 150 g per week due to scientific evidence on higher risk of cancer and cardiovascular disease and high to very high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "PORK ",
        "carbonFootprint": "5.55",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "6253",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "141",
        "proteins": "19.9",
        "fat": "6.8",
        "saturatedFat": "2.17",
        "monounsaturatedFat": "2.31",
        "polyunsaturatedFat": "1.77",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein and iron. It is recommended to limit pork meat consumption to <300 g per week due to the saturated fat and cholesterol content. Medium environmental impact comparable to many fishes."
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "BACON",
        "carbonFootprint": "5.70",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "7669",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "276",
        "proteins": "15.8",
        "fat": "23.6",
        "saturatedFat": "7.97",
        "monounsaturatedFat": "9.82",
        "polyunsaturatedFat": "3.42",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein. It is recommended to limit cured meat to less than 100g per week due to the saturated fat and cholesterol content, high salt and high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "PORK CURED MEAT",
        "carbonFootprint": "8.26",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "7669",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "475",
        "proteins": "22.5",
        "fat": "42.8",
        "saturatedFat": "13.6",
        "monounsaturatedFat": "18.22",
        "polyunsaturatedFat": "7.66",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein. It is recommended to limit cured meat to less than 100g per week due to the saturated fat and cholesterol content, high salt and high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Poultry&Rabbit",
        "FOOD COMMODITY ITEM": "CHICKEN ",
        "carbonFootprint": "3.60",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "3650",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "110",
        "proteins": "19.4",
        "fat": "3.6",
        "saturatedFat": "1.23",
        "monounsaturatedFat": "1.08",
        "polyunsaturatedFat": "0.81",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "2-3 times per week",
        "recommendation": "200-300 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "This source of animal protein has a lower enviromental impact and reduced fat content than red meat; it is a good source of  iron. Recommended daily portion 150g, 2-3 times per week. To increase the sustainability of the dish, opt for organic or free range chicken whenever possible."
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Poultry&Rabbit",
        "FOOD COMMODITY ITEM": "DUCK ",
        "carbonFootprint": "4.10",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4325",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "159",
        "proteins": "21.4",
        "fat": "8.2",
        "saturatedFat": "2.54",
        "monounsaturatedFat": "3.92",
        "polyunsaturatedFat": "1.21",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "2-3 times per week",
        "recommendation": "200-300 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "This source of animal protein has a lower enviromental impact and reduced fat content than red meat; it is a good source of  iron. Recommended daily portion 150g, 2-3 times per week. To increase the sustainability of the dish, opt for organic or free range chicken whenever possible."
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Poultry&Rabbit",
        "FOOD COMMODITY ITEM": "GOOSE ",
        "carbonFootprint": "4.84",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4325",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "373",
        "proteins": "15.8",
        "fat": "34.4",
        "saturatedFat": "9.39",
        "monounsaturatedFat": "18.6",
        "polyunsaturatedFat": "4.12",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "2-3 times per week",
        "recommendation": "200-300 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "This source of animal protein has a lower enviromental impact and reduced fat content than red meat; it is a good source of  iron. Recommended daily portion 150g, 2-3 times per week. To increase the sustainability of the dish, opt for organic or free range chicken whenever possible."
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Poultry&Rabbit",
        "FOOD COMMODITY ITEM": "POULTRY ",
        "carbonFootprint": "7.31",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4207",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "110",
        "proteins": "19.4",
        "fat": "3.6",
        "saturatedFat": "1.23",
        "monounsaturatedFat": "1.08",
        "polyunsaturatedFat": "0.81",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "2-3 times per week",
        "recommendation": "200-300 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "This source of animal protein has a lower enviromental impact and reduced fat content than red meat; it is a good source of  iron. Recommended daily portion 150g, 2-3 times per week. To increase the sustainability of the dish, opt for organic or free range chicken whenever possible."
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Poultry&Rabbit",
        "FOOD COMMODITY ITEM": "TURKEY ",
        "carbonFootprint": "4.45",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4325",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "109",
        "proteins": "21.9",
        "fat": "2.4",
        "saturatedFat": "0.9",
        "monounsaturatedFat": "0.62",
        "polyunsaturatedFat": "0.6",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "2-3 times per week",
        "recommendation": "200-300 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "This source of animal protein has a lower enviromental impact and reduced fat content than red meat; it is a good source of  iron. Recommended daily portion 150g, 2-3 times per week. To increase the sustainability of the dish, opt for organic or free range chicken whenever possible."
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Poultry&Rabbit",
        "FOOD COMMODITY ITEM": "GUINEA FOWL ",
        "carbonFootprint": "5.68",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4325",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "152",
        "proteins": "23.4",
        "fat": "6.5",
        "saturatedFat": "1.77",
        "monounsaturatedFat": "2.43",
        "polyunsaturatedFat": "1.41",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "2-3 times per week",
        "recommendation": "200-300 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "This source of animal protein has a lower enviromental impact and reduced fat content than red meat; it is a good source of  iron. Recommended daily portion 150g, 2-3 times per week. To increase the sustainability of the dish, opt for organic or free range chicken whenever possible."
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "SHEEP ",
        "carbonFootprint": "14.79",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "9155.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "293",
        "proteins": "17",
        "fat": "25",
        "saturatedFat": "8.87",
        "monounsaturatedFat": "9.03",
        "polyunsaturatedFat": "2.34",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein and iron. It is recommended to limit red meat consumption < 150 g per week due to scientific evidence on higher risk of cancer and cardiovascular disease and high to very high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "SNAILS",
        "FOOD COMMODITY ITEM": "SNAILS",
        "carbonFootprint": "0.73",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "67",
        "proteins": "12.9",
        "fat": "1.7",
        "saturatedFat": "0.13",
        "monounsaturatedFat": "0.12",
        "polyunsaturatedFat": "0.1",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "2-3 times per week",
        "recommendation": "200-300 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Medium source of protein and low-fat intake. Good supply of omega-3 fatty acids. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Red meat",
        "FOOD COMMODITY ITEM": "Red meat (General)",
        "carbonFootprint": "17.67",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "14816.17",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "190.3",
        "proteins": "20.1",
        "fat": "12.1",
        "saturatedFat": "4.4",
        "monounsaturatedFat": "5.0",
        "polyunsaturatedFat": "1.8",
        "carbohydrates": "0.2",
        "soluble": "0.0",
        "fiber": "0.0",
        "frequencyOfConsumption": "1-2 times per week",
        "recommendation": "100-200 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein and iron. It is recommended to limit red meat consumption < 150 g per week due to scientific evidence on higher risk of cancer and cardiovascular disease and high to very high environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "MEAT",
        "FOOD COMMODITY SUB-GROUP": "Poultry&Rabbit",
        "FOOD COMMODITY ITEM": "Poultry&Rabbit (General)",
        "carbonFootprint": "3.65",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4207",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "181.8",
        "proteins": "20.3",
        "fat": "11.2",
        "saturatedFat": "3.2",
        "monounsaturatedFat": "5.5",
        "polyunsaturatedFat": "1.6",
        "carbohydrates": "0.0",
        "soluble": "0.0",
        "fiber": "0.0",
        "frequencyOfConsumption": "2-3 times per week",
        "recommendation": "200-300 g/w",
        "Recommendation_on_Sustainability_and_Nutritional": "This source of animal protein has a lower enviromental impact and reduced fat content than red meat; it is a good source of  iron. Recommended daily portion 150g, 2-3 times per week. To increase the sustainability of the dish, opt for organic or free range chicken whenever possible."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "NUTS (Generic)",
        "carbonFootprint": "1.88",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1870",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "608.7",
        "proteins": "16.6",
        "fat": "54.3",
        "saturatedFat": "6.1",
        "monounsaturatedFat": "26.5",
        "polyunsaturatedFat": "16.0",
        "carbohydrates": "10.3",
        "soluble": "4.5",
        "fiber": "7.3",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Seeds",
        "FOOD COMMODITY ITEM": "SEEDS (Generic)",
        "carbonFootprint": "2.98",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "3087.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "553.8",
        "proteins": "19.3",
        "fat": "46.9",
        "saturatedFat": "5.9",
        "monounsaturatedFat": "16.5",
        "polyunsaturatedFat": "21.9",
        "carbohydrates": "18.9",
        "soluble": "0.6",
        "fiber": "15.1",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. It is recommended to consume 30 g/day. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "ALMOND",
        "carbonFootprint": "2.20",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "7069",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "628",
        "proteins": "22",
        "fat": "55.3",
        "saturatedFat": "4.59",
        "monounsaturatedFat": "39.44",
        "polyunsaturatedFat": "10.85",
        "carbohydrates": "4.6",
        "soluble": "3.7",
        "fiber": "12.7",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Medium environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "BRAZIL NUT",
        "carbonFootprint": "7.70",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1870",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "702",
        "proteins": "14.3",
        "fat": "68.1",
        "saturatedFat": "5.57",
        "monounsaturatedFat": "9.54",
        "polyunsaturatedFat": "40.66",
        "carbohydrates": "5.1",
        "soluble": "3.1",
        "fiber": "6.2",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Medium environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "CASHEW NUT",
        "carbonFootprint": "1.44",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1870",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "550",
        "proteins": "15",
        "fat": "46",
        "saturatedFat": "8.16",
        "monounsaturatedFat": "24.96",
        "polyunsaturatedFat": "8.23",
        "carbohydrates": "18.8",
        "soluble": "5.6",
        "fiber": "3",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "CHESTNUT",
        "carbonFootprint": "1.48",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2750",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "174",
        "proteins": "2.9",
        "fat": "1.7",
        "saturatedFat": "0.3",
        "monounsaturatedFat": "0.64",
        "polyunsaturatedFat": "0.68",
        "carbohydrates": "36.7",
        "soluble": "8.9",
        "fiber": "4.7",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "GROUNDNUT",
        "carbonFootprint": "1.88",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1870",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "585",
        "proteins": "26",
        "fat": "47.2",
        "saturatedFat": "8.89",
        "monounsaturatedFat": "22.61",
        "polyunsaturatedFat": "13.44",
        "carbohydrates": "11.02",
        "soluble": "4.5",
        "fiber": "7.3",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "HAZELNUT",
        "carbonFootprint": "1.35",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1870",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "671",
        "proteins": "13.8",
        "fat": "64.1",
        "saturatedFat": "4.16",
        "monounsaturatedFat": "38.6",
        "polyunsaturatedFat": "5.2",
        "carbohydrates": "6.1",
        "soluble": "4.1",
        "fiber": "8.1",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "MACADAMIA",
        "carbonFootprint": "3.08",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1870",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "751",
        "proteins": "8",
        "fat": "76",
        "saturatedFat": "12.1",
        "monounsaturatedFat": "59.06",
        "polyunsaturatedFat": "1.51",
        "carbohydrates": "5.1",
        "soluble": "4",
        "fiber": "8",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "PEANUT",
        "carbonFootprint": "0.99",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1621",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "585",
        "proteins": "26",
        "fat": "47.2",
        "saturatedFat": "8.89",
        "monounsaturatedFat": "22.61",
        "polyunsaturatedFat": "13.44",
        "carbohydrates": "11.2",
        "soluble": "4.5",
        "fiber": "7.3",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "PECAN NUT",
        "carbonFootprint": "3.04",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1870",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "723",
        "proteins": "7.2",
        "fat": "71.8",
        "saturatedFat": "6.17",
        "monounsaturatedFat": "40.7",
        "polyunsaturatedFat": "21.56",
        "carbohydrates": "7.9",
        "soluble": "4.3",
        "fiber": "9.4",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "PINE NUT",
        "carbonFootprint": "4.68",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1870",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "604",
        "proteins": "31.9",
        "fat": "50.3",
        "saturatedFat": "3.6",
        "monounsaturatedFat": "13.8",
        "polyunsaturatedFat": "25.07",
        "carbohydrates": "4",
        "soluble": "3.9",
        "fiber": "4.5",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Medium environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "PISTACHIO",
        "carbonFootprint": "2.25",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1870",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "629",
        "proteins": "18.1",
        "fat": "56.1",
        "saturatedFat": "5.61",
        "monounsaturatedFat": "36.47",
        "polyunsaturatedFat": "10.66",
        "carbohydrates": "8.1",
        "soluble": "4.5",
        "fiber": "10.6",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Nuts",
        "FOOD COMMODITY ITEM": "WALNUT",
        "carbonFootprint": "2.13",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4535.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "702",
        "proteins": "14.3",
        "fat": "68.1",
        "saturatedFat": "5.57",
        "monounsaturatedFat": "9.54",
        "polyunsaturatedFat": "40.66",
        "carbohydrates": "5.1",
        "soluble": "3.1",
        "fiber": "6.2",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Seeds",
        "FOOD COMMODITY ITEM": "LINSEED",
        "carbonFootprint": "2.98",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "3087.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "447",
        "proteins": "24.5",
        "fat": "31",
        "saturatedFat": "3",
        "monounsaturatedFat": "5.3",
        "polyunsaturatedFat": "21.2",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "34.8",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. It is recommended to consume 30 g/day. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Seeds",
        "FOOD COMMODITY ITEM": "MUSTARD SEEDS",
        "carbonFootprint": "2.98",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "3087.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "573",
        "proteins": "17.7",
        "fat": "49.7",
        "saturatedFat": "7",
        "monounsaturatedFat": "18.8",
        "polyunsaturatedFat": "21.8",
        "carbohydrates": "23.4",
        "soluble": "0.3",
        "fiber": "11.8",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. It is recommended to consume 30 g/day. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Seeds",
        "FOOD COMMODITY ITEM": "POPPY SEED",
        "carbonFootprint": "1.94",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "3087.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "573",
        "proteins": "17.7",
        "fat": "49.7",
        "saturatedFat": "7",
        "monounsaturatedFat": "18.8",
        "polyunsaturatedFat": "21.8",
        "carbohydrates": "23.4",
        "soluble": "0.3",
        "fiber": "11.8",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. It is recommended to consume 30 g/day. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Seeds",
        "FOOD COMMODITY ITEM": "RAPESEED",
        "carbonFootprint": "1.05",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2271",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "573",
        "proteins": "17.7",
        "fat": "49.7",
        "saturatedFat": "7",
        "monounsaturatedFat": "18.8",
        "polyunsaturatedFat": "21.8",
        "carbohydrates": "23.4",
        "soluble": "0.3",
        "fiber": "11.8",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. It is recommended to consume 30 g/day. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Seeds",
        "FOOD COMMODITY ITEM": "SESAME SEED",
        "carbonFootprint": "6.06",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "9371",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "573",
        "proteins": "17.7",
        "fat": "49.7",
        "saturatedFat": "7",
        "monounsaturatedFat": "18.8",
        "polyunsaturatedFat": "21.8",
        "carbohydrates": "23.4",
        "soluble": "0.3",
        "fiber": "11.8",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. Recommended to consume 30 g/day of both nuts and seeds. Medium environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "NUTS&SEEDS",
        "FOOD COMMODITY SUB-GROUP": "Seeds",
        "FOOD COMMODITY ITEM": "SUNFLOWER SEED",
        "carbonFootprint": "2.19",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "3366",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "584",
        "proteins": "20.78",
        "fat": "51.46",
        "saturatedFat": "4.45",
        "monounsaturatedFat": "18.53",
        "polyunsaturatedFat": "23.14",
        "carbohydrates": "20",
        "soluble": "2.62",
        "fiber": "8.6",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of unsaturated fatty acids, fiber, vitamins, and minerals. Good source of vegetable protein. It is recommended to consume 30 g/day. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "OILS&VEGETABLE FAT",
        "FOOD COMMODITY SUB-GROUP": "MARGARINE",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.54",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1324",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "760",
        "proteins": "0.7",
        "fat": "84",
        "saturatedFat": "35.57",
        "monounsaturatedFat": "31.51",
        "polyunsaturatedFat": "12.73",
        "carbohydrates": "0.4",
        "soluble": "0.4",
        "fiber": "0",
        "frequencyOfConsumption": "More times during the day",
        "recommendation": "25-30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fats and vitamins. It is recommended to consume 25-30 g per day. Very good environmental score. In this recommendation, they are not included palm and coconut oils."
    },
    {
        "FOOD COMMODITY GROUP": "OILS&VEGETABLE FAT",
        "FOOD COMMODITY SUB-GROUP": "NUTS AND SEEDS OIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.03",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "806.6",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "900",
        "proteins": "0",
        "fat": "100",
        "saturatedFat": "10.89",
        "monounsaturatedFat": "39.67",
        "polyunsaturatedFat": "45.92",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "More times during the day",
        "recommendation": "25-30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fats and vitamins. It is recommended to consume 25-30 g per day. Very good environmental score. In this recommendation, they are not included palm and coconut oils."
    },
    {
        "FOOD COMMODITY GROUP": "OILS&VEGETABLE FAT",
        "FOOD COMMODITY SUB-GROUP": "EXTRAVIRGIN OLIVE OIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.20",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "14431",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "899",
        "proteins": "0",
        "fat": "99.9",
        "saturatedFat": "14.46",
        "monounsaturatedFat": "72.95",
        "polyunsaturatedFat": "7.52",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "More times during the day",
        "recommendation": "25-30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of monounsaturated fat and antioxidant. Medium enviromental score."
    },
    {
        "FOOD COMMODITY GROUP": "OILS&VEGETABLE FAT",
        "FOOD COMMODITY SUB-GROUP": "LINSEED OIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.29",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "9415",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "828",
        "proteins": "0",
        "fat": "92",
        "saturatedFat": "8",
        "monounsaturatedFat": "16",
        "polyunsaturatedFat": "63",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "More times during the day",
        "recommendation": "25-30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fats and vitamins. It is recommended to consume 25-30 g per day. Very good environmental score. In this recommendation, they are not included palm and coconut oils."
    },
    {
        "FOOD COMMODITY GROUP": "OILS&VEGETABLE FAT",
        "FOOD COMMODITY SUB-GROUP": "MAIZE OIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "3.32",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "2575",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "899",
        "proteins": "0",
        "fat": "99.9",
        "saturatedFat": "14.96",
        "monounsaturatedFat": "30.66",
        "polyunsaturatedFat": "50.43",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "More times during the day",
        "recommendation": "25-30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fats and vitamins. It is recommended to consume 25-30 g per day. Very good environmental score. In this recommendation, they are not included palm and coconut oils."
    },
    {
        "FOOD COMMODITY GROUP": "OILS&VEGETABLE FAT",
        "FOOD COMMODITY SUB-GROUP": "OLIVE OIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "3.37",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "14431",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "900",
        "proteins": "0",
        "fat": "100",
        "saturatedFat": "16.16",
        "monounsaturatedFat": "74.45",
        "polyunsaturatedFat": "8.84",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "More times during the day",
        "recommendation": "25-30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fats and vitamins. It is recommended to consume 25-30 g per day. Very good environmental score. In this recommendation, they are not included palm and coconut oils."
    },
    {
        "FOOD COMMODITY GROUP": "OILS&VEGETABLE FAT",
        "FOOD COMMODITY SUB-GROUP": "PALM OIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.58",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "4970.5",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "899",
        "proteins": "0",
        "fat": "99.9",
        "saturatedFat": "47.1",
        "monounsaturatedFat": "38.92",
        "polyunsaturatedFat": "12.58",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "More times during the day",
        "recommendation": "25-30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in saturated fat. Medium environmental score. Use  certificated RSPO and no deforestation. "
    },
    {
        "FOOD COMMODITY GROUP": "OILS&VEGETABLE FAT",
        "FOOD COMMODITY SUB-GROUP": "PEANUT OIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "3.04",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "806.6",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "900",
        "proteins": "0",
        "fat": "100",
        "saturatedFat": "19.39",
        "monounsaturatedFat": "52.52",
        "polyunsaturatedFat": "27.87",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "More times during the day",
        "recommendation": "25-30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fats and vitamins. It is recommended to consume 25-30 g per day. Very good environmental score. In this recommendation, they are not included palm and coconut oils."
    },
    {
        "FOOD COMMODITY GROUP": "OILS&VEGETABLE FAT",
        "FOOD COMMODITY SUB-GROUP": "RAPESEED OIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.71",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "4301",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "899",
        "proteins": "0",
        "fat": "99.9",
        "saturatedFat": "6.31",
        "monounsaturatedFat": "61.52",
        "polyunsaturatedFat": "29.62",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "More times during the day",
        "recommendation": "25-30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fats and vitamins. It is recommended to consume 25-30 g per day. Very good environmental score. In this recommendation, they are not included palm and coconut oils."
    },
    {
        "FOOD COMMODITY GROUP": "OILS&VEGETABLE FAT",
        "FOOD COMMODITY SUB-GROUP": "SESAME OIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.03",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "21793",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "898",
        "proteins": "0.2",
        "fat": "99.7",
        "saturatedFat": "13.15",
        "monounsaturatedFat": "40.1",
        "polyunsaturatedFat": "42.2",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "More times during the day",
        "recommendation": "25-30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fats and vitamins. It is recommended to consume 25-30 g per day. Very good environmental score. In this recommendation, they are not included palm and coconut oils."
    },
    {
        "FOOD COMMODITY GROUP": "OILS&VEGETABLE FAT",
        "FOOD COMMODITY SUB-GROUP": "SOYBEAN OIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.89",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "4190",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "899",
        "proteins": "0",
        "fat": "99.9",
        "saturatedFat": "14.02",
        "monounsaturatedFat": "22.76",
        "polyunsaturatedFat": "58.96",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "More times during the day",
        "recommendation": "25-30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fats and vitamins. It is recommended to consume 25-30 g per day. Very good environmental score. In this recommendation, they are not included palm and coconut oils."
    },
    {
        "FOOD COMMODITY GROUP": "OILS&VEGETABLE FAT",
        "FOOD COMMODITY SUB-GROUP": "SUNFLOWER OIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.00",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "6792",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "899",
        "proteins": "0",
        "fat": "99.9",
        "saturatedFat": "11.24",
        "monounsaturatedFat": "33.37",
        "polyunsaturatedFat": "50.22",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "More times during the day",
        "recommendation": "25-30 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of unsaturated fats and vitamins. It is recommended to consume 25-30 g per day. Very good environmental score. In this recommendation, they are not included palm and coconut oils."
    },
    {
        "FOOD COMMODITY GROUP": "PASTA&RICE",
        "FOOD COMMODITY SUB-GROUP": "EGG PASTA",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "3.15",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4636.8",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "300",
        "proteins": "11.7",
        "fat": "2.2",
        "saturatedFat": "0.64",
        "monounsaturatedFat": "0.51",
        "polyunsaturatedFat": "0.46",
        "carbohydrates": "60.9",
        "soluble": "1.5",
        "fiber": "2.4",
        "frequencyOfConsumption": "Can be eaten every day ",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of protein. High cholesterol content and high caloric intake. Medium environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "PASTA&RICE",
        "FOOD COMMODITY SUB-GROUP": "COUSCOUS",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.94",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1508.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "361",
        "proteins": "13.7",
        "fat": "1.1",
        "saturatedFat": "0.22",
        "monounsaturatedFat": "0.11",
        "polyunsaturatedFat": "0.44",
        "carbohydrates": "76.5",
        "soluble": "2.7",
        "fiber": "4.5",
        "frequencyOfConsumption": "Can be eaten every day ",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with reasonable content of proteins. Recommended frequency of consumption according to one’s nutritional and energy needs. Consumption of wholegrain pasta and rice is recommended for higher fiber intake. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "PASTA&RICE",
        "FOOD COMMODITY SUB-GROUP": "PASTA",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.30",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1508.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "341",
        "proteins": "13.5",
        "fat": "1.2",
        "saturatedFat": "0.19",
        "monounsaturatedFat": "0.14",
        "polyunsaturatedFat": "0.61",
        "carbohydrates": "72.7",
        "soluble": "2.2",
        "fiber": "1.7",
        "frequencyOfConsumption": "Can be eaten every day ",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with reasonable content of proteins. Recommended frequency of consumption according to one’s nutritional and energy needs. Consumption of wholegrain pasta and rice is recommended for higher fiber intake. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "PASTA&RICE",
        "FOOD COMMODITY SUB-GROUP": "RICE",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.96",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1455",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "338",
        "proteins": "7.4",
        "fat": "0.3",
        "saturatedFat": "0.08",
        "monounsaturatedFat": "0.1",
        "polyunsaturatedFat": "0.14",
        "carbohydrates": "81.3",
        "soluble": "0.3",
        "fiber": "0.5",
        "frequencyOfConsumption": "Can be eaten every day ",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of carbohydrates with reasonable content of proteins. Recommended frequency of consumption according to one’s nutritional and energy needs. Consumption of wholegrain pasta and rice is recommended for higher fiber intake. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "PLANT BASED MILK&PROTEINS",
        "FOOD COMMODITY SUB-GROUP": "Plant based milk",
        "FOOD COMMODITY ITEM": "PLANT BASED MILK (Generic)",
        "carbonFootprint": "0.66",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2029.95",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "48.8",
        "proteins": "2.3",
        "fat": "0.7",
        "saturatedFat": "0.1",
        "monounsaturatedFat": "0.1",
        "polyunsaturatedFat": "0.2",
        "carbohydrates": "7.3",
        "soluble": "3.3",
        "fiber": "2.6",
        "frequencyOfConsumption": "Can be eaten every day",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vegetable protein, vitamins, minerals, and omega-3 fatty acids. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "PLANT BASED MILK&PROTEINS",
        "FOOD COMMODITY SUB-GROUP": "Plant based protein",
        "FOOD COMMODITY ITEM": "PLANT BASED PROTEIN (Generic)",
        "carbonFootprint": "1.48",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2138.14",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "41.0",
        "proteins": "0.4",
        "fat": "2.2",
        "saturatedFat": "0.7",
        "monounsaturatedFat": "0.4",
        "polyunsaturatedFat": "0.0",
        "carbohydrates": "4.5",
        "soluble": "1.5",
        "fiber": "0.6",
        "frequencyOfConsumption": "Can be eaten every day",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vegetable protein, vitamins, minerals, and omega-3 fatty acids. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "PLANT BASED MILK&PROTEINS",
        "FOOD COMMODITY SUB-GROUP": "Plant based milk",
        "FOOD COMMODITY ITEM": "ALMOND MILK",
        "carbonFootprint": "0.45",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "2029.5",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "57",
        "proteins": "1.3",
        "fat": "3.3",
        "saturatedFat": "0.28",
        "monounsaturatedFat": "2.37",
        "polyunsaturatedFat": "0.65",
        "carbohydrates": "5.5",
        "soluble": "5.4",
        "fiber": "0.8",
        "frequencyOfConsumption": "Can be eaten every day",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vegetable protein, vitamins, minerals, and omega-3 fatty acids. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "PLANT BASED MILK&PROTEINS",
        "FOOD COMMODITY SUB-GROUP": "Plant based milk",
        "FOOD COMMODITY ITEM": "COCONUT MILK",
        "carbonFootprint": "0.43",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "2029.5",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "240",
        "proteins": "2.3",
        "fat": "23.8",
        "saturatedFat": "21.14",
        "monounsaturatedFat": "1.01",
        "polyunsaturatedFat": "0.26",
        "carbohydrates": "3.3",
        "soluble": "3.3",
        "fiber": "2.2",
        "frequencyOfConsumption": "Can be eaten every day",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vegetable protein, vitamins, minerals, and omega-3 fatty acids. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "PLANT BASED MILK&PROTEINS",
        "FOOD COMMODITY SUB-GROUP": "Plant based milk",
        "FOOD COMMODITY ITEM": "OAT MILK",
        "carbonFootprint": "0.30",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "2029.5",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "75",
        "proteins": "5.8",
        "fat": "0.8",
        "saturatedFat": "0.29",
        "monounsaturatedFat": "0.24",
        "polyunsaturatedFat": "0.08",
        "carbohydrates": "6.8",
        "soluble": "2",
        "fiber": "9.7",
        "frequencyOfConsumption": "Can be eaten every day",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vegetable protein, vitamins, minerals, and omega-3 fatty acids. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "PLANT BASED MILK&PROTEINS",
        "FOOD COMMODITY SUB-GROUP": "Plant based milk",
        "FOOD COMMODITY ITEM": "RICE MILK",
        "carbonFootprint": "0.70",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "2029.5",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "22",
        "proteins": "1.2",
        "fat": "0.5",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "3",
        "soluble": "3",
        "fiber": "0.9",
        "frequencyOfConsumption": "Can be eaten every day",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vegetable protein, vitamins, minerals, and omega-3 fatty acids. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "PLANT BASED MILK&PROTEINS",
        "FOOD COMMODITY SUB-GROUP": "Plant based milk",
        "FOOD COMMODITY ITEM": "SOY MILK",
        "carbonFootprint": "0.71",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "2029.5",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "32",
        "proteins": "2.9",
        "fat": "1.9",
        "saturatedFat": "0.21",
        "monounsaturatedFat": "0.33",
        "polyunsaturatedFat": "0.83",
        "carbohydrates": "0.8",
        "soluble": "0.8",
        "fiber": "0",
        "frequencyOfConsumption": "Can be eaten every day",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vegetable protein, vitamins, minerals, and omega-3 fatty acids. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "PLANT BASED MILK&PROTEINS",
        "FOOD COMMODITY SUB-GROUP": "Plant based protein",
        "FOOD COMMODITY ITEM": "SOY BURGER",
        "carbonFootprint": "1.48",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1052.7",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "20",
        "proteins": "0.1",
        "fat": "0.9",
        "saturatedFat": "0.9",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "2.7",
        "soluble": "1.9",
        "fiber": "0.1",
        "frequencyOfConsumption": "Can be eaten every day",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vegetable protein, vitamins, minerals, and omega-3 fatty acids. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "PLANT BASED MILK&PROTEINS",
        "FOOD COMMODITY SUB-GROUP": "Plant based protein",
        "FOOD COMMODITY ITEM": "TOFU",
        "carbonFootprint": "2.33",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1724.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "81",
        "proteins": "8.1",
        "fat": "4.8",
        "saturatedFat": "0.07",
        "monounsaturatedFat": "1.06",
        "polyunsaturatedFat": "2.7",
        "carbohydrates": "0.7",
        "soluble": "0.7",
        "fiber": "1.2",
        "frequencyOfConsumption": "Can be eaten every day",
        "recommendation": "Based on energy nedeed",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of vegetable protein, vitamins, minerals, and omega-3 fatty acids. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Cephalopods",
        "FOOD COMMODITY ITEM": "CEPHALOPODS (generic)",
        "carbonFootprint": "6.35",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "72.0",
        "proteins": "14.0",
        "fat": "1.5",
        "saturatedFat": "0.4",
        "monounsaturatedFat": "0.2",
        "polyunsaturatedFat": "0.4",
        "carbohydrates": "0.7",
        "soluble": "0.7",
        "fiber": "0.0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol. This blue protein source has a significant environmental impact. It should be consumed less then once per week and possibly from certified sources. The suggested portion is 150g of live weight. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Crustaceans",
        "FOOD COMMODITY ITEM": "CRUSTACEANS (generic)",
        "carbonFootprint": "10.43",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "951.49",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "82.7",
        "proteins": "16.1",
        "fat": "1.6",
        "saturatedFat": "0.3",
        "monounsaturatedFat": "0.3",
        "polyunsaturatedFat": "0.5",
        "carbohydrates": "1.1",
        "soluble": "1.1",
        "fiber": "0.0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol. This blue protein source has a significant environmental impact. It should be consumed less then once per week and possibly from certified sources. The suggested portion is 150g of live weight. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Bivalves",
        "FOOD COMMODITY ITEM": "BIVALVES (generic)",
        "carbonFootprint": "0.91",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "OPTIMAL",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "77.3",
        "proteins": "11.0",
        "fat": "2.2",
        "saturatedFat": "0.4",
        "monounsaturatedFat": "0.4",
        "polyunsaturatedFat": "0.5",
        "carbohydrates": "3.6",
        "soluble": "0.8",
        "fiber": "0.0",
        "frequencyOfConsumption": " 3 times per week or more ",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol. This blue protein source has very low environmental impact. It can be consumed together  up to 3 times per week in portions of 150g of live weight. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Bivalves",
        "FOOD COMMODITY ITEM": "SCALLOP (F)",
        "carbonFootprint": "20.59",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Red*",
        "carbonFootprintBandingImpactDescription": "VERY HIGH",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "OPTIMAL",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "83",
        "proteins": "16.8",
        "fat": "0.8",
        "saturatedFat": "0.08",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.26",
        "carbohydrates": "2.4",
        "soluble": "2.4",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol.  It can be consumed 2-3 times per week in portions of 150g of live weight. Better alternate fish sources and buy certified fish. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Bivalves",
        "FOOD COMMODITY ITEM": "CLAM (F)",
        "carbonFootprint": "9.4",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "OPTIMAL",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "72",
        "proteins": "10.2",
        "fat": "2.5",
        "saturatedFat": "0.24",
        "monounsaturatedFat": "0.21",
        "polyunsaturatedFat": "0.72",
        "carbohydrates": "2.2",
        "soluble": "2.2",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol.  It can be consumed 2-3 times per week in portions of 150g of live weight. Better alternate fish sources and buy certified fish. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Crustaceans",
        "FOOD COMMODITY ITEM": "CRAB (W)",
        "carbonFootprint": "10.43",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "E",
        "FATTY_or_LEAN_fish": "",
        "energy": "82",
        "proteins": "18.1",
        "fat": "1.1",
        "saturatedFat": "0.22",
        "monounsaturatedFat": "0.19",
        "polyunsaturatedFat": "0.39",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol. This blue protein source has a significant environmental impact. It should be consumed less then once per week and possibly from certified sources. The suggested portion is 150g of live weight. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Cephalopods",
        "FOOD COMMODITY ITEM": "CUTTELFISH (W)",
        "carbonFootprint": "5.05",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "72",
        "proteins": "14",
        "fat": "1.5",
        "saturatedFat": "0.43",
        "monounsaturatedFat": "0.21",
        "polyunsaturatedFat": "0.43",
        "carbohydrates": "0.7",
        "soluble": "0.7",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol. This blue protein source has a significant environmental impact. It should be consumed less then once per week and possibly from certified sources. The suggested portion is 150g of live weight. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Crustaceans",
        "FOOD COMMODITY ITEM": "FRESHWATER CRAYFISH (F)",
        "carbonFootprint": "9.16",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2147.83",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "E",
        "FATTY_or_LEAN_fish": "",
        "energy": "105",
        "proteins": "17",
        "fat": "4.1",
        "saturatedFat": "0.69",
        "monounsaturatedFat": "0.73",
        "polyunsaturatedFat": "1.25",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol. This blue protein source has a significant environmental impact. It should be consumed less then once per week and possibly from certified sources. The suggested portion is 150g of live weight. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Crustaceans",
        "FOOD COMMODITY ITEM": "LOBSTER (W)",
        "carbonFootprint": "21.98",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Red",
        "carbonFootprintBandingImpactDescription": "VERY HIGH",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "E",
        "FATTY_or_LEAN_fish": "",
        "energy": "85",
        "proteins": "18.8",
        "fat": "0.9",
        "saturatedFat": "0.11",
        "monounsaturatedFat": "0.17",
        "polyunsaturatedFat": "0.23",
        "carbohydrates": "0.5",
        "soluble": "0.5",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol. This blue protein source has a significant environmental impact. It should be consumed less then once per week and possibly from certified sources. The suggested portion is 150g of live weight. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Bivalves",
        "FOOD COMMODITY ITEM": "MUSSEL (F)",
        "carbonFootprint": "0.69",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "OPTIMAL",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "84",
        "proteins": "11.7",
        "fat": "2.7",
        "saturatedFat": "0.52",
        "monounsaturatedFat": "0.63",
        "polyunsaturatedFat": "0.58",
        "carbohydrates": "3.4",
        "soluble": "0.3",
        "fiber": "0",
        "frequencyOfConsumption": " 3 times per week or more ",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol. This blue protein source has very low environmental impact. It can be consumed together  up to 3 times per week in portions of 150g of live weight. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Bivalves",
        "FOOD COMMODITY ITEM": "MUSSEL (W)",
        "carbonFootprint": "0.92",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "MODERATE",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "84",
        "proteins": "11.7",
        "fat": "2.7",
        "saturatedFat": "0.52",
        "monounsaturatedFat": "0.63",
        "polyunsaturatedFat": "0.58",
        "carbohydrates": "3.4",
        "soluble": "0.3",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol.  It can be consumed 2-3 times per week in portions of 150g of live weight. Better alternate fish sources and buy certified fish. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Cephalopods",
        "FOOD COMMODITY ITEM": "OCTOPUS (W)",
        "carbonFootprint": "5.8",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "57",
        "proteins": "10.6",
        "fat": "1",
        "saturatedFat": "0.42",
        "monounsaturatedFat": "0.2",
        "polyunsaturatedFat": "0.4",
        "carbohydrates": "1.4",
        "soluble": "1.4",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol. This blue protein source has a significant environmental impact. It should be consumed less then once per week and possibly from certified sources. The suggested portion is 150g of live weight. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Bivalves",
        "FOOD COMMODITY ITEM": "OYSTER (F)",
        "carbonFootprint": "38.87",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Red*",
        "carbonFootprintBandingImpactDescription": "VERY HIGH",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "OPTIMAL",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "69",
        "proteins": "10.2",
        "fat": "0.9",
        "saturatedFat": "0.17",
        "monounsaturatedFat": "0.21",
        "polyunsaturatedFat": "0.19",
        "carbohydrates": "5.4",
        "soluble": "0.5",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol.  It can be consumed 2-3 times per week in portions of 150g of live weight. Better alternate fish sources and buy certified fish. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Crustaceans",
        "FOOD COMMODITY ITEM": "SHRIMP (F)",
        "carbonFootprint": "9.16",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2147.83",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "E",
        "FATTY_or_LEAN_fish": "",
        "energy": "71",
        "proteins": "13.6",
        "fat": "0.6",
        "saturatedFat": "0.09",
        "monounsaturatedFat": "0.13",
        "polyunsaturatedFat": "0.19",
        "carbohydrates": "2.9",
        "soluble": "2.9",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol. This blue protein source has a significant environmental impact. It should be consumed less then once per week and possibly from certified sources. The suggested portion is 150g of live weight. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Crustaceans",
        "FOOD COMMODITY ITEM": "SHRIMP (W)",
        "carbonFootprint": "19.84",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "E",
        "FATTY_or_LEAN_fish": "",
        "energy": "71",
        "proteins": "13.6",
        "fat": "0.6",
        "saturatedFat": "0.09",
        "monounsaturatedFat": "0.13",
        "polyunsaturatedFat": "0.19",
        "carbohydrates": "2.9",
        "soluble": "2.9",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol. This blue protein source has a significant environmental impact. It should be consumed less then once per week and possibly from certified sources. The suggested portion is 150g of live weight. "
    },
    {
        "FOOD COMMODITY GROUP": "SHELLFISH",
        "FOOD COMMODITY SUB-GROUP": "Cephalopods",
        "FOOD COMMODITY ITEM": "SQUID (W)",
        "carbonFootprint": "6.91",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Yellow",
        "carbonFootprintBandingImpactDescription": "MEDIUM",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "LIMIT",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "68",
        "proteins": "12.6",
        "fat": "1.7",
        "saturatedFat": "0.85",
        "monounsaturatedFat": "0.45",
        "polyunsaturatedFat": "0.4",
        "carbohydrates": "0.6",
        "soluble": "0.6",
        "fiber": "0",
        "frequencyOfConsumption": "Generally 3 times per week, for this fish see the recommended limitations",
        "recommendation": ">300 g/week of fatty fish and >150 g/week of lean fish",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, iron and minerals. Modest amount of cholesterol. This blue protein source has a significant environmental impact. It should be consumed less then once per week and possibly from certified sources. The suggested portion is 150g of live weight. "
    },
    {
        "FOOD COMMODITY GROUP": "SOFT DRINKS",
        "FOOD COMMODITY SUB-GROUP": "FRUIT JUICE",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.80",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "904.28",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "56",
        "proteins": "0.3",
        "fat": "0.1",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.02",
        "carbohydrates": "14.5",
        "soluble": "14.5",
        "fiber": "0",
        "frequencyOfConsumption": "Limit the intake",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of carbohydrates, vitamins, and minerals. Sugar-free products are recommended to limit calorie intake. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "SOFT DRINKS",
        "FOOD COMMODITY SUB-GROUP": "TROPICAL FRUIT JUICE",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.84",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1273",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "45",
        "proteins": "0.2",
        "fat": "0.1",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.03",
        "carbohydrates": "11.5",
        "soluble": "11.5",
        "fiber": "0.1",
        "frequencyOfConsumption": "Limit the intake",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of carbohydrates, vitamins, and minerals. Sugar-free products are recommended to limit calorie intake. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "ANISE",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.95",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "8280",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "49",
        "proteins": "3.1",
        "fat": "0.8",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.12",
        "polyunsaturatedFat": "0.51",
        "carbohydrates": "5.1",
        "soluble": "5.1",
        "fiber": "5.2",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "BADIAN",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.95",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "8280",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "49",
        "proteins": "3.1",
        "fat": "0.8",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.12",
        "polyunsaturatedFat": "0.51",
        "carbohydrates": "5.1",
        "soluble": "5.1",
        "fiber": "5.2",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "BASIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.98",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "49",
        "proteins": "3.1",
        "fat": "0.8",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.12",
        "polyunsaturatedFat": "0.51",
        "carbohydrates": "5.1",
        "soluble": "5.1",
        "fiber": "5.2",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "BAY, DRIED",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.95",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "341",
        "proteins": "7.6",
        "fat": "8.4",
        "saturatedFat": "2.29",
        "monounsaturatedFat": "1.65",
        "polyunsaturatedFat": "2.3",
        "carbohydrates": "48.6",
        "soluble": "48.6",
        "fiber": "26.3",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "CARDAMOM",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.47",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "34319",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "49",
        "proteins": "3.1",
        "fat": "0.8",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.12",
        "polyunsaturatedFat": "0.51",
        "carbohydrates": "5.1",
        "soluble": "5.1",
        "fiber": "5.2",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "CHEVRIL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.95",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "237",
        "proteins": "23",
        "fat": "3.9",
        "saturatedFat": "0.2",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "49",
        "soluble": "49",
        "fiber": "11",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "CHILI PEPPER",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "2.30",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "3872",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "30",
        "proteins": "1.8",
        "fat": "0.5",
        "saturatedFat": "0.09",
        "monounsaturatedFat": "0.03",
        "polyunsaturatedFat": "0.27",
        "carbohydrates": "3.8",
        "soluble": "1.5",
        "fiber": "1.9",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "CHIVE",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.69",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "27",
        "proteins": "2.8",
        "fat": "0.6",
        "saturatedFat": "0.12",
        "monounsaturatedFat": "0.08",
        "polyunsaturatedFat": "0.22",
        "carbohydrates": "1.7",
        "soluble": "1.7",
        "fiber": "1.9",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "CINNAMON",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.68",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "15526",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "301",
        "proteins": "3.9",
        "fat": "3.2",
        "saturatedFat": "0.65",
        "monounsaturatedFat": "0.48",
        "polyunsaturatedFat": "0.53",
        "carbohydrates": "55.5",
        "soluble": "55.4",
        "fiber": "24.4",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "CLOVES",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.66",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "61205",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "274",
        "proteins": "6",
        "fat": "13",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "65",
        "soluble": "2.4",
        "fiber": "34",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "CORIANDER",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.19",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "8280",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "49",
        "proteins": "3.1",
        "fat": "0.8",
        "saturatedFat": "0",
        "monounsaturatedFat": "0.12",
        "polyunsaturatedFat": "0.51",
        "carbohydrates": "5.1",
        "soluble": "5.1",
        "fiber": "5.2",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "CUMIN",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.45",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "16259",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "419",
        "proteins": "17.8",
        "fat": "22.3",
        "saturatedFat": "0.72",
        "monounsaturatedFat": "14.86",
        "polyunsaturatedFat": "2.53",
        "carbohydrates": "33.7",
        "soluble": "33.7",
        "fiber": "10.5",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "CURRY",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.46",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "16259",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "325",
        "proteins": "14",
        "fat": "14",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "55",
        "soluble": "2",
        "fiber": "53",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "DILL",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.95",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "43",
        "proteins": "3.5",
        "fat": "1.1",
        "saturatedFat": "0.1",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "7",
        "soluble": "7",
        "fiber": "2.1",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "FENNEL SEED",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.17",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "8280",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "324",
        "proteins": "15.8",
        "fat": "14.9",
        "saturatedFat": "0.48",
        "monounsaturatedFat": "9.91",
        "polyunsaturatedFat": "1.69",
        "carbohydrates": "12.5",
        "soluble": "12.5",
        "fiber": "39.8",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "GINGER",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.87",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1657",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "80",
        "proteins": "1.8",
        "fat": "0.8",
        "saturatedFat": "0.2",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "18",
        "soluble": "18",
        "fiber": "2",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "MACE",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.30",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "34319",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "375",
        "proteins": "6.7",
        "fat": "32.38",
        "saturatedFat": "9.51",
        "monounsaturatedFat": "11.17",
        "polyunsaturatedFat": "4.39",
        "carbohydrates": "50.5",
        "soluble": "50.5",
        "fiber": "20.2",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "MARJORAM",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.01",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "309",
        "proteins": "12.7",
        "fat": "7",
        "saturatedFat": "1.17",
        "monounsaturatedFat": "2.61",
        "polyunsaturatedFat": "1.1",
        "carbohydrates": "42.5",
        "soluble": "42.5",
        "fiber": "18.1",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "MINT",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.95",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "288",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "53",
        "proteins": "3.8",
        "fat": "0.7",
        "saturatedFat": "0.12",
        "monounsaturatedFat": "0.27",
        "polyunsaturatedFat": "0.11",
        "carbohydrates": "5.3",
        "soluble": "5.3",
        "fiber": "5.6",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "NUTMEG",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.30",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "34319",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "442",
        "proteins": "8.8",
        "fat": "29",
        "saturatedFat": "20.72",
        "monounsaturatedFat": "2.57",
        "polyunsaturatedFat": "0.28",
        "carbohydrates": "26.3",
        "soluble": "26.3",
        "fiber": "23.7",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "OREGANO",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.01",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "16259",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "80",
        "proteins": "2.2",
        "fat": "2",
        "saturatedFat": "0.52",
        "monounsaturatedFat": "0.13",
        "polyunsaturatedFat": "1.02",
        "carbohydrates": "9.7",
        "soluble": "9.7",
        "fiber": "8.4",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "PAPRIKA",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.15",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "16259",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "348",
        "proteins": "14.8",
        "fat": "13",
        "saturatedFat": "2.11",
        "monounsaturatedFat": "1.23",
        "polyunsaturatedFat": "8.35",
        "carbohydrates": "34.8",
        "soluble": "34.8",
        "fiber": "20.9",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "PARSLEY",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.98",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "30",
        "proteins": "3.7",
        "fat": "0.6",
        "saturatedFat": "0.1",
        "monounsaturatedFat": "0.22",
        "polyunsaturatedFat": "0.1",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "5",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "PEPPER",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.15",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "7611",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Orange",
        "waterFootprintBandingImpactDescription": "HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "26",
        "proteins": "0.9",
        "fat": "0.3",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.02",
        "polyunsaturatedFat": "0.16",
        "carbohydrates": "4.2",
        "soluble": "4.2",
        "fiber": "1.9",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "PEPPERMINT",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.95",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "288",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "70",
        "proteins": "3.8",
        "fat": "0.9",
        "saturatedFat": "0.2",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "15",
        "soluble": "15",
        "fiber": "8",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "ROSEMARY",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.98",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "111",
        "proteins": "1.4",
        "fat": "4.4",
        "saturatedFat": "1.14",
        "monounsaturatedFat": "0.29",
        "polyunsaturatedFat": "2.25",
        "carbohydrates": "13.5",
        "soluble": "13.5",
        "fiber": "7.7",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "SAFFRON",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.45",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "731000",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "337",
        "proteins": "11.4",
        "fat": "5.9",
        "saturatedFat": "1.59",
        "monounsaturatedFat": "0.43",
        "polyunsaturatedFat": "2.07",
        "carbohydrates": "61.5",
        "soluble": "42.4",
        "fiber": "3.9",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "SAGE",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.98",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "145",
        "proteins": "3.9",
        "fat": "4.6",
        "saturatedFat": "2.54",
        "monounsaturatedFat": "0.68",
        "polyunsaturatedFat": "0.64",
        "carbohydrates": "15.6",
        "soluble": "15.6",
        "fiber": "14.7",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "SAVORY",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.01",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "70",
        "proteins": "3.8",
        "fat": "0.9",
        "saturatedFat": "0.2",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "15",
        "soluble": "15",
        "fiber": "8",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "TARRAGON",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.95",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "70",
        "proteins": "3.8",
        "fat": "0.9",
        "saturatedFat": "0.2",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "15",
        "soluble": "15",
        "fiber": "8",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "THYME",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.98",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "116",
        "proteins": "3",
        "fat": "2.5",
        "saturatedFat": "0.69",
        "monounsaturatedFat": "0.12",
        "polyunsaturatedFat": "0.79",
        "carbohydrates": "15.1",
        "soluble": "15.1",
        "fiber": "12.3",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "TURMERIC",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.47",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "16259",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "19",
        "proteins": "0.6",
        "fat": "0.2",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "4",
        "soluble": "0.2",
        "fiber": "1.4",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SPICES",
        "FOOD COMMODITY SUB-GROUP": "VANILLA",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.33",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "141046",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "C",
        "FATTY_or_LEAN_fish": "",
        "energy": "12",
        "proteins": "0",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "0.5",
        "soluble": "0.5",
        "fiber": "0",
        "frequencyOfConsumption": "Prefere spices to salt ",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "Source of fiber, minerals, and vitamins. Very good climatic impact, quite water consuming. Spices can be used to give taste to food recipes  to limit salt and dressings"
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "filled cookies",
        "FOOD COMMODITY ITEM": "CHOCOLATE OR CREAM FILLED COOKIES",
        "carbonFootprint": "3.40",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2884.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "445",
        "proteins": "5.2",
        "fat": "21.3",
        "saturatedFat": "10.55",
        "monounsaturatedFat": "6.18",
        "polyunsaturatedFat": "3.41",
        "carbohydrates": "60.6",
        "soluble": "40.5",
        "fiber": "2.5",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High saturated fat content and high caloric intake. It is recommended to limit consumption and prefer plain or whole-grain cookies for higher fiber content and reduced fat intake. Low environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "simple cookies",
        "FOOD COMMODITY ITEM": "SIMPLE COOKIES",
        "carbonFootprint": "2.20",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1703",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "341",
        "proteins": "13.5",
        "fat": "1.2",
        "saturatedFat": "0.19",
        "monounsaturatedFat": "0.14",
        "polyunsaturatedFat": "0.61",
        "carbohydrates": "72.7",
        "soluble": "2.2",
        "fiber": "1.7",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High calorie intake. Source of carbohydrates. Lower fat intake than filled biscuits. We recommend limiting consumption and preferring wholemeal products for their higher fibre content and lower fat intake. Low environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "Chocolate",
        "FOOD COMMODITY ITEM": "CHOCOLATE",
        "carbonFootprint": "13.16",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "17196",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "327",
        "proteins": "0.3",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "87",
        "soluble": "1.5",
        "fiber": "0",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High calorie and fat intake. Moderate amount of caffeine and antioxidants. It is recommended to limit consumption and to prefer low-fat cocoa for its higher mineral and vitamin content and lower calorie and fat intake. Medium environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "Chocolate",
        "FOOD COMMODITY ITEM": "DARK CHOCOLATE",
        "carbonFootprint": "18.58",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "17196",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "531",
        "proteins": "6.6",
        "fat": "33.6",
        "saturatedFat": "20.59",
        "monounsaturatedFat": "11.08",
        "polyunsaturatedFat": "1.41",
        "carbohydrates": "49.7",
        "soluble": "49.7",
        "fiber": "8",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High calorie and fat intake. Moderate amount of caffeine and antioxidants. It is recommended to limit consumption and to prefer low-fat cocoa for its higher mineral and vitamin content and lower calorie and fat intake. Medium environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "Chocolate",
        "FOOD COMMODITY ITEM": "MILK CHOCOLATE",
        "carbonFootprint": "17.57",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "17196",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "552",
        "proteins": "7.3",
        "fat": "36.3",
        "saturatedFat": "21.86",
        "monounsaturatedFat": "11.49",
        "polyunsaturatedFat": "1.28",
        "carbohydrates": "50.5",
        "soluble": "50.5",
        "fiber": "3.2",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High calorie and fat intake. Moderate amount of caffeine and antioxidants. It is recommended to limit consumption and to prefer low-fat cocoa for its higher mineral and vitamin content and lower calorie and fat intake. Medium environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "Chocolate",
        "FOOD COMMODITY ITEM": "WHITE CHOCOLATE",
        "carbonFootprint": "18.55",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "17196",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Orange",
        "carbonFootprintBandingImpactDescription": "HIGH",
        "waterFootprintBanding": "Red",
        "waterFootprintBandingImpactDescription": "VERY HIGH",
        "blueSustainabilityIndex": "",
        "environmentalScore": "D",
        "FATTY_or_LEAN_fish": "",
        "energy": "534",
        "proteins": "5.9",
        "fat": "32.1",
        "saturatedFat": "19.41",
        "monounsaturatedFat": "9.1",
        "polyunsaturatedFat": "1.01",
        "carbohydrates": "59",
        "soluble": "59",
        "fiber": "0.2",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High calorie and fat intake. Moderate amount of caffeine and antioxidants. It is recommended to limit consumption and to prefer low-fat cocoa for its higher mineral and vitamin content and lower calorie and fat intake. Medium environmental impact"
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "Sugar",
        "FOOD COMMODITY ITEM": "BEET SUGAR",
        "carbonFootprint": "0.94",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "667",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "534",
        "proteins": "5.9",
        "fat": "32.1",
        "saturatedFat": "19.41",
        "monounsaturatedFat": "9.1",
        "polyunsaturatedFat": "1.01",
        "carbohydrates": "59",
        "soluble": "59",
        "fiber": "0.2",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High carbohydrate content. It is recommended to limit intake of added sugars  < 50 g/day. Low environmental impact. Prefer raw and minimally processed sugar, or honey, better if certified from sustainable or organic farming. "
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "Sugar",
        "FOOD COMMODITY ITEM": "BROWN SUGAR",
        "carbonFootprint": "1.06",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "847",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "380",
        "proteins": "0.1",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "101.3",
        "soluble": "101.3",
        "fiber": "0",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High carbohydrate content. It is recommended to limit intake of added sugars  < 50 g/day. Low environmental impact. Prefer raw and minimally processed sugar, or honey, better if certified from sustainable or organic farming. "
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "Sugar",
        "FOOD COMMODITY ITEM": "CANE SUGAR",
        "carbonFootprint": "0.62",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1724",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "380",
        "proteins": "0.1",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "101.3",
        "soluble": "101.3",
        "fiber": "0",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High carbohydrate content. It is recommended to limit intake of added sugars  < 50 g/day. Low environmental impact. Prefer raw and minimally processed sugar, or honey, better if certified from sustainable or organic farming. "
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "Sugar",
        "FOOD COMMODITY ITEM": "FRUCTOSE",
        "carbonFootprint": "1.54",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "1782",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "375",
        "proteins": "0",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "100",
        "soluble": "100",
        "fiber": "0",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High carbohydrate content. It is recommended to limit intake of added sugars  < 50 g/day. Low environmental impact. Prefer raw and minimally processed sugar, or honey, better if certified from sustainable or organic farming. "
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "Sugar",
        "FOOD COMMODITY ITEM": "MOLASSES",
        "carbonFootprint": "1.15",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "527",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "392",
        "proteins": "0",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "98",
        "soluble": "97",
        "fiber": "0",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High carbohydrate content. It is recommended to limit intake of added sugars  < 50 g/day. Low environmental impact. Prefer raw and minimally processed sugar, or honey, better if certified from sustainable or organic farming. "
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "Sugar",
        "FOOD COMMODITY ITEM": "WHITE SUGAR",
        "carbonFootprint": "3.00",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "847",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "392",
        "proteins": "0",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "104.5",
        "soluble": "104.5",
        "fiber": "0",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High carbohydrate content. It is recommended to limit intake of added sugars  < 50 g/day. Low environmental impact. Prefer raw and minimally processed sugar, or honey, better if certified from sustainable or organic farming. "
    },
    {
        "FOOD COMMODITY GROUP": "SUGAR&HONEY&SWEETS",
        "FOOD COMMODITY SUB-GROUP": "Honey",
        "FOOD COMMODITY ITEM": "HONEY",
        "carbonFootprint": "1.15",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "304",
        "proteins": "0.6",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "80.3",
        "soluble": "80.3",
        "fiber": "0",
        "frequencyOfConsumption": "Less than 1 per day",
        "recommendation": "Less than 50 g/d",
        "Recommendation_on_Sustainability_and_Nutritional": "High carbohydrate content. It is recommended to limit intake of added sugars  < 50 g/day. Low environmental impact. Prefer raw and minimally processed sugar, or honey, better if certified from sustainable or organic farming. "
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Leafy Vegetables ",
        "FOOD COMMODITY ITEM": "LEAFY VEGETABLES (Generic)",
        "carbonFootprint": "0.48",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "25.5",
        "proteins": "2.0",
        "fat": "0.3",
        "saturatedFat": "0.1",
        "monounsaturatedFat": "0.0",
        "polyunsaturatedFat": "0.2",
        "carbohydrates": "2.6",
        "soluble": "2.2",
        "fiber": "2.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "OTHER VEGETABLES (Generic)",
        "carbonFootprint": "0.54",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "284",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "39.0",
        "proteins": "1.9",
        "fat": "0.4",
        "saturatedFat": "0.1",
        "monounsaturatedFat": "0.1",
        "polyunsaturatedFat": "0.2",
        "carbohydrates": "6.2",
        "soluble": "5.9",
        "fiber": "2.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Roots and tubers",
        "FOOD COMMODITY ITEM": "ROOTS AND TUBERS (Generic)",
        "carbonFootprint": "0.31",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "231",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "39.0",
        "proteins": "1.1",
        "fat": "0.0",
        "saturatedFat": "0.0",
        "monounsaturatedFat": "0.0",
        "polyunsaturatedFat": "0.0",
        "carbohydrates": "7.6",
        "soluble": "7.6",
        "fiber": "3.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Starchy tubers",
        "FOOD COMMODITY ITEM": "STARCHY TUBERS (Generic)",
        "carbonFootprint": "0.34",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "345.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "126.0",
        "proteins": "1.5",
        "fat": "0.2",
        "saturatedFat": "0.0",
        "monounsaturatedFat": "0.0",
        "polyunsaturatedFat": "0.0",
        "carbohydrates": "28.6",
        "soluble": "1.6",
        "fiber": "3.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich of carbohydrates. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Vegetables canned",
        "FOOD COMMODITY ITEM": "TOMATO PASTE",
        "carbonFootprint": "1.30",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "855",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "55",
        "proteins": "0.9",
        "fat": "0.4",
        "saturatedFat": "0.06",
        "monounsaturatedFat": "0.06",
        "polyunsaturatedFat": "0.16",
        "carbohydrates": "11.4",
        "soluble": "11.4",
        "fiber": "2.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food but often contains added sugar. Recommended frequency of consumption >400 g per day. Prefer fresh to canned food. Good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Vegetables canned",
        "FOOD COMMODITY ITEM": "TOMATO CHOPPED",
        "carbonFootprint": "1.41",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "267",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "29",
        "proteins": "1.2",
        "fat": "0.3",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "4.9",
        "soluble": "3.9",
        "fiber": "1.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food but often contains added sugar. Recommended frequency of consumption >400 g per day. Prefer fresh to canned food. Good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Vegetables canned",
        "FOOD COMMODITY ITEM": "TOMATO PEELED",
        "carbonFootprint": "1.27",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "267",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "22",
        "proteins": "1.2",
        "fat": "0.5",
        "saturatedFat": "0.07",
        "monounsaturatedFat": "0.08",
        "polyunsaturatedFat": "0.21",
        "carbohydrates": "3",
        "soluble": "3",
        "fiber": "0.9",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food but often contains added sugar. Recommended frequency of consumption >400 g per day. Prefer fresh to canned food. Good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Vegetables canned",
        "FOOD COMMODITY ITEM": "TOMATO PUREE",
        "carbonFootprint": "1.17",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "713",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "100",
        "proteins": "3.9",
        "fat": "0.4",
        "saturatedFat": "0.06",
        "monounsaturatedFat": "0.06",
        "polyunsaturatedFat": "0",
        "carbohydrates": "20.4",
        "soluble": "20.4",
        "fiber": "2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food but often contains added sugar. Recommended frequency of consumption >400 g per day. Prefer fresh to canned food. Good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Vegetables canned",
        "FOOD COMMODITY ITEM": "TOMATO & BASIL",
        "carbonFootprint": "1.14",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "147.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "68",
        "proteins": "1.3",
        "fat": "4.1",
        "saturatedFat": "1",
        "monounsaturatedFat": "1.2",
        "polyunsaturatedFat": "1.1",
        "carbohydrates": "5.7",
        "soluble": "5.5",
        "fiber": "1.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food but often contains added sugar. Recommended frequency of consumption >400 g per day. Prefer fresh to canned food. Good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Vegetables canned",
        "FOOD COMMODITY ITEM": "TOMATO ARRABBIATA",
        "carbonFootprint": "1.24",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "155",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "67",
        "proteins": "1.6",
        "fat": "3.2",
        "saturatedFat": "0.3",
        "monounsaturatedFat": "0.5",
        "polyunsaturatedFat": "0.7",
        "carbohydrates": "7.4",
        "soluble": "5",
        "fiber": "1.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food but often contains added sugar. Recommended frequency of consumption >400 g per day. Prefer fresh to canned food. Good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Vegetables canned",
        "FOOD COMMODITY ITEM": "TOMATO SAUCE",
        "carbonFootprint": "1.03",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "855",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "16",
        "proteins": "0.8",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "3",
        "soluble": "3",
        "fiber": "0.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food but often contains added sugar. Recommended frequency of consumption >400 g per day. Prefer fresh to canned food. Good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "CORN",
        "carbonFootprint": "1.24",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "986",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "357",
        "proteins": "9.2",
        "fat": "3.8",
        "saturatedFat": "0.58",
        "monounsaturatedFat": "0.87",
        "polyunsaturatedFat": "1.67",
        "carbohydrates": "75.1",
        "soluble": "2.5",
        "fiber": "2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in carbohydrates. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Leafy Vegetables ",
        "FOOD COMMODITY ITEM": "SWISS CHARD",
        "carbonFootprint": "0.84",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "19",
        "proteins": "1.3",
        "fat": "0.1",
        "saturatedFat": "0.02",
        "monounsaturatedFat": "0.02",
        "polyunsaturatedFat": "0.01",
        "carbohydrates": "2.8",
        "soluble": "2.8",
        "fiber": "1.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Leafy Vegetables ",
        "FOOD COMMODITY ITEM": "CABBAGE",
        "carbonFootprint": "0.67",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "325",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "24",
        "proteins": "2.1",
        "fat": "0.1",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0",
        "carbohydrates": "2.5",
        "soluble": "2.5",
        "fiber": "2.9",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Leafy Vegetables ",
        "FOOD COMMODITY ITEM": "CHICORY",
        "carbonFootprint": "0.56",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "17",
        "proteins": "1.4",
        "fat": "0.2",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0.01",
        "carbohydrates": "0.7",
        "soluble": "0.7",
        "fiber": "3.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Leafy Vegetables ",
        "FOOD COMMODITY ITEM": "CHINESE KALE",
        "carbonFootprint": "0.30",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "24",
        "proteins": "2.1",
        "fat": "0.1",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0",
        "carbohydrates": "2.5",
        "soluble": "2.5",
        "fiber": "2.9",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Leafy Vegetables ",
        "FOOD COMMODITY ITEM": "ENDIVE",
        "carbonFootprint": "0.88",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "20",
        "proteins": "0.9",
        "fat": "0.3",
        "saturatedFat": "0.07",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.13",
        "carbohydrates": "2.7",
        "soluble": "2.7",
        "fiber": "1.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Leafy Vegetables ",
        "FOOD COMMODITY ITEM": "ESCAROLES",
        "carbonFootprint": "1.11",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "18",
        "proteins": "1.6",
        "fat": "0.2",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0.09",
        "carbohydrates": "1.7",
        "soluble": "1.7",
        "fiber": "1.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in fiber. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Leafy Vegetables ",
        "FOOD COMMODITY ITEM": "LETTUCE",
        "carbonFootprint": "0.45",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "237",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "22",
        "proteins": "1.8",
        "fat": "0.4",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.21",
        "carbohydrates": "2.2",
        "soluble": "2.2",
        "fiber": "1.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Leafy Vegetables ",
        "FOOD COMMODITY ITEM": "ROCKET",
        "carbonFootprint": "0.88",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "28",
        "proteins": "2.6",
        "fat": "0.7",
        "saturatedFat": "0.09",
        "monounsaturatedFat": "0.05",
        "polyunsaturatedFat": "0.32",
        "carbohydrates": "2.1",
        "soluble": "2.1",
        "fiber": "1.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in antioxidants. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Leafy Vegetables ",
        "FOOD COMMODITY ITEM": "ROMAIN LETTUCE",
        "carbonFootprint": "1.92",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "18",
        "proteins": "1.6",
        "fat": "0.2",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.11",
        "carbohydrates": "1.7",
        "soluble": "1.7",
        "fiber": "1.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Leafy Vegetables ",
        "FOOD COMMODITY ITEM": "SPINACH",
        "carbonFootprint": "0.90",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "292",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "35",
        "proteins": "3.4",
        "fat": "0.7",
        "saturatedFat": "0.07",
        "monounsaturatedFat": "0.05",
        "polyunsaturatedFat": "0.42",
        "carbohydrates": "3",
        "soluble": "0.4",
        "fiber": "1.9",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in fiber and antioxidants. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "ARTICHOKE",
        "carbonFootprint": "0.86",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "818",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "33",
        "proteins": "2.7",
        "fat": "0.2",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.08",
        "carbohydrates": "2.5",
        "soluble": "1.9",
        "fiber": "5.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in fiber. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "ASPARAGUS",
        "carbonFootprint": "0.88",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "3531",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "40",
        "proteins": "4.6",
        "fat": "0.2",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.09",
        "carbohydrates": "4",
        "soluble": "4",
        "fiber": "2.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Roots and tubers",
        "FOOD COMMODITY ITEM": "BEETROOT",
        "carbonFootprint": "0.31",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "231",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "25",
        "proteins": "1.1",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "4",
        "soluble": "4",
        "fiber": "2.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "BRUSSELS SPROUT",
        "carbonFootprint": "0.99",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "48",
        "proteins": "4.2",
        "fat": "0.5",
        "saturatedFat": "0.1",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.26",
        "carbohydrates": "4.3",
        "soluble": "3.3",
        "fiber": "5.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "CARDOON",
        "carbonFootprint": "0.69",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "284",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "13",
        "proteins": "0.6",
        "fat": "0.1",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0.02",
        "polyunsaturatedFat": "0.04",
        "carbohydrates": "1.7",
        "soluble": "1.5",
        "fiber": "1.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "CAULIFLOWER",
        "carbonFootprint": "0.33",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "285",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "30",
        "proteins": "3.2",
        "fat": "0.2",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.08",
        "carbohydrates": "2.7",
        "soluble": "2.4",
        "fiber": "2.4",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in fiber. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Roots and tubers",
        "FOOD COMMODITY ITEM": "CELERIAC",
        "carbonFootprint": "0.38",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "284",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "33",
        "proteins": "1.9",
        "fat": "0.1",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.02",
        "polyunsaturatedFat": "0.05",
        "carbohydrates": "3.8",
        "soluble": "3.8",
        "fiber": "5.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "CELERY",
        "carbonFootprint": "0.49",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "284",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "23",
        "proteins": "2.3",
        "fat": "0.2",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.04",
        "polyunsaturatedFat": "0.1",
        "carbohydrates": "2.4",
        "soluble": "2.2",
        "fiber": "1.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "CUCUMBER",
        "carbonFootprint": "0.29",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "416",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "15",
        "proteins": "0.7",
        "fat": "0.5",
        "saturatedFat": "0.17",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.23",
        "carbohydrates": "1.8",
        "soluble": "1.8",
        "fiber": "0.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "FENNEL",
        "carbonFootprint": "0.97",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "284",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "13",
        "proteins": "1.2",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "1",
        "soluble": "1",
        "fiber": "2.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in antioxidants. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "GARLIC",
        "carbonFootprint": "0.70",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "589",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "45",
        "proteins": "0.9",
        "fat": "0.6",
        "saturatedFat": "0.11",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.3",
        "carbohydrates": "8.4",
        "soluble": "8.4",
        "fiber": "2.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "GHERKIN",
        "carbonFootprint": "0.21",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "416",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "17",
        "proteins": "0.9",
        "fat": "0.1",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0.04",
        "carbohydrates": "2.6",
        "soluble": "2.4",
        "fiber": "1.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "GREEN BEAN",
        "carbonFootprint": "0.75",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "531.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "24",
        "proteins": "2.1",
        "fat": "0.1",
        "saturatedFat": "0.02",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0.06",
        "carbohydrates": "2.4",
        "soluble": "2.4",
        "fiber": "2.9",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "GOURDS",
        "carbonFootprint": "0.52",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "368",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "19",
        "proteins": "1.1",
        "fat": "0.1",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.01",
        "carbohydrates": "3.5",
        "soluble": "2.5",
        "fiber": "0.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "MUSHROOM",
        "carbonFootprint": "1.85",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "0",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "32",
        "proteins": "3.9",
        "fat": "0.7",
        "saturatedFat": "0.15",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0.38",
        "carbohydrates": "1.4",
        "soluble": "1.2",
        "fiber": "2.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in fiber. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "OKRA",
        "carbonFootprint": "0.54",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "576",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "32",
        "proteins": "3.9",
        "fat": "0.7",
        "saturatedFat": "0.15",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0.38",
        "carbohydrates": "1.4",
        "soluble": "1.2",
        "fiber": "2.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "ONION",
        "carbonFootprint": "0.26",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "243",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "28",
        "proteins": "1",
        "fat": "0.1",
        "saturatedFat": "0.02",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.03",
        "carbohydrates": "5.7",
        "soluble": "5.7",
        "fiber": "1.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in antioxidants. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "PEPPER",
        "carbonFootprint": "0.89",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "379",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "26",
        "proteins": "0.9",
        "fat": "0.3",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.02",
        "polyunsaturatedFat": "0.16",
        "carbohydrates": "4.2",
        "soluble": "4.2",
        "fiber": "1.9",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in antioxidants. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "PUMPKIN",
        "carbonFootprint": "0.52",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "368",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "19",
        "proteins": "1.1",
        "fat": "0.1",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.01",
        "carbohydrates": "3.5",
        "soluble": "2.5",
        "fiber": "0.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in fiber and antioxidants. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Roots and tubers",
        "FOOD COMMODITY ITEM": "SALSIFY",
        "carbonFootprint": "1.04",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "284",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "53",
        "proteins": "1.3",
        "fat": "0.3",
        "saturatedFat": "0.07",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.13",
        "carbohydrates": "10.2",
        "soluble": "1.5",
        "fiber": "3.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "SQUASH",
        "carbonFootprint": "0.52",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "336",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "19",
        "proteins": "1.1",
        "fat": "0.1",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.01",
        "carbohydrates": "3.5",
        "soluble": "2.5",
        "fiber": "0.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Roots and tubers",
        "FOOD COMMODITY ITEM": "SUGAR BEET",
        "carbonFootprint": "0.54",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "132",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "534",
        "proteins": "5.9",
        "fat": "32.1",
        "saturatedFat": "19.41",
        "monounsaturatedFat": "9.1",
        "polyunsaturatedFat": "1.01",
        "carbohydrates": "59",
        "soluble": "59",
        "fiber": "0.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "SUGAR CANE",
        "carbonFootprint": "0.15",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "214.5",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "380",
        "proteins": "0.1",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "101.3",
        "soluble": "101.3",
        "fiber": "0",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "TOMATO",
        "carbonFootprint": "0.47",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "68.8",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "19",
        "proteins": "1.2",
        "fat": "0.2",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.03",
        "polyunsaturatedFat": "0.08",
        "carbohydrates": "2.8",
        "soluble": "2.8",
        "fiber": "1.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "TOMATO (DRIED)",
        "carbonFootprint": "2.82",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "4276",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "271",
        "proteins": "14.1",
        "fat": "3",
        "saturatedFat": "0.43",
        "monounsaturatedFat": "0.49",
        "polyunsaturatedFat": "1.12",
        "carbohydrates": "43.5",
        "soluble": "43.5",
        "fiber": "12.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "ZUCCHINI",
        "carbonFootprint": "0.91",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "284",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "14",
        "proteins": "1.3",
        "fat": "0.1",
        "saturatedFat": "0.02",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.04",
        "carbohydrates": "1.4",
        "soluble": "1.3",
        "fiber": "1.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "LEEK",
        "carbonFootprint": "1.07",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "284",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "35",
        "proteins": "2.1",
        "fat": "0.1",
        "saturatedFat": "0.01",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0.05",
        "carbohydrates": "5.2",
        "soluble": "5.2",
        "fiber": "2.9",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "EGGPLANT (AUBERGIN)",
        "carbonFootprint": "1.30",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "365",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "20",
        "proteins": "1.1",
        "fat": "0.1",
        "saturatedFat": "0.02",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.04",
        "carbohydrates": "2.6",
        "soluble": "2.6",
        "fiber": "2.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in fiber. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "TOMATO (G)",
        "carbonFootprint": "2.47",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "68.8",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "19",
        "proteins": "1.2",
        "fat": "0.2",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.03",
        "polyunsaturatedFat": "0.08",
        "carbohydrates": "2.8",
        "soluble": "2.8",
        "fiber": "1.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Roots and tubers",
        "FOOD COMMODITY ITEM": "HORSERADISH",
        "carbonFootprint": "0.70",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "231",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "13",
        "proteins": "0.8",
        "fat": "0.1",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.02",
        "polyunsaturatedFat": "0.05",
        "carbohydrates": "1.8",
        "soluble": "1.8",
        "fiber": "1.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "BROCCOLI",
        "carbonFootprint": "0.62",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "319",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "30",
        "proteins": "3.4",
        "fat": "0.3",
        "saturatedFat": "0.05",
        "monounsaturatedFat": "0.02",
        "polyunsaturatedFat": "0.14",
        "carbohydrates": "2",
        "soluble": "2",
        "fiber": "3.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in fiber. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Roots and tubers",
        "FOOD COMMODITY ITEM": "CARROT",
        "carbonFootprint": "0.25",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "200",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "39",
        "proteins": "1.1",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "7.6",
        "soluble": "7.6",
        "fiber": "3.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich in fiber and antioxidants. Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Roots and tubers",
        "FOOD COMMODITY ITEM": "PARSNIP",
        "carbonFootprint": "0.71",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "231",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "19",
        "proteins": "1.2",
        "fat": "0.2",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.03",
        "polyunsaturatedFat": "0.08",
        "carbohydrates": "2.8",
        "soluble": "2.8",
        "fiber": "1.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Roots and tubers",
        "FOOD COMMODITY ITEM": "RADISH",
        "carbonFootprint": "0.24",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "329",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "13",
        "proteins": "0.8",
        "fat": "0.1",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.02",
        "polyunsaturatedFat": "0.05",
        "carbohydrates": "1.8",
        "soluble": "1.8",
        "fiber": "1.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Roots and tubers",
        "FOOD COMMODITY ITEM": "RHUBARB",
        "carbonFootprint": "0.96",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "231",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "19",
        "proteins": "1.2",
        "fat": "0.2",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.03",
        "polyunsaturatedFat": "0.08",
        "carbohydrates": "2.8",
        "soluble": "2.8",
        "fiber": "1.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Roots and tubers",
        "FOOD COMMODITY ITEM": "RUTABAGA",
        "carbonFootprint": "0.71",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "231",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "19",
        "proteins": "1.2",
        "fat": "0.2",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.03",
        "polyunsaturatedFat": "0.08",
        "carbohydrates": "2.8",
        "soluble": "2.8",
        "fiber": "1.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Other vegetable",
        "FOOD COMMODITY ITEM": "SHALLOT",
        "carbonFootprint": "0.75",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "272",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "23",
        "proteins": "1.5",
        "fat": "0.2",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.03",
        "polyunsaturatedFat": "0.08",
        "carbohydrates": "3.3",
        "soluble": "3.3",
        "fiber": "1.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Roots and tubers",
        "FOOD COMMODITY ITEM": "TURNIP",
        "carbonFootprint": "0.38",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "195",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "23",
        "proteins": "1",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "3.8",
        "soluble": "3.8",
        "fiber": "2.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food. Recommended frequency of consumption >400 g per day. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Starchy tubers",
        "FOOD COMMODITY ITEM": "MANIOCA",
        "carbonFootprint": "1.17",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "564",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "159",
        "proteins": "1.4",
        "fat": "0.3",
        "saturatedFat": "0.1",
        "monounsaturatedFat": "0.1",
        "polyunsaturatedFat": "0.1",
        "carbohydrates": "38",
        "soluble": "1.7",
        "fiber": "1.8",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich of carbohydrates. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Starchy tubers",
        "FOOD COMMODITY ITEM": "POTATO",
        "carbonFootprint": "0.73",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "281",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "80",
        "proteins": "2.1",
        "fat": "0.1",
        "saturatedFat": "0.03",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.01",
        "carbohydrates": "18",
        "soluble": "0.4",
        "fiber": "1.6",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich of carbohydrates. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Starchy tubers",
        "FOOD COMMODITY ITEM": "SWEET POTATO",
        "carbonFootprint": "0.45",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "606",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "92",
        "proteins": "1.2",
        "fat": "0.3",
        "saturatedFat": "0.06",
        "monounsaturatedFat": "0.01",
        "polyunsaturatedFat": "0.13",
        "carbohydrates": "21.3",
        "soluble": "5.7",
        "fiber": "2.3",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich of carbohydrates. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Starchy tubers",
        "FOOD COMMODITY ITEM": "TARO",
        "carbonFootprint": "1.22",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "606",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "187",
        "proteins": "1",
        "fat": "0.1",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "39",
        "soluble": "1",
        "fiber": "7",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich of carbohydrates. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Starchy tubers",
        "FOOD COMMODITY ITEM": "YAM",
        "carbonFootprint": "1.22",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "343",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "118",
        "proteins": "1.5",
        "fat": "0.2",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "28",
        "soluble": "0.5",
        "fiber": "4.1",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Rich of carbohydrates. Recommended frequency of consumption according to one's nutritional and energy needs. Very good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES",
        "FOOD COMMODITY SUB-GROUP": "Vegetables canned",
        "FOOD COMMODITY ITEM": "PESTO",
        "carbonFootprint": "2.86",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2099",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Light green",
        "waterFootprintBandingImpactDescription": "LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "312",
        "proteins": "47",
        "fat": "1.2",
        "saturatedFat": "0.14",
        "monounsaturatedFat": "0.21",
        "polyunsaturatedFat": "0.53",
        "carbohydrates": "20.9",
        "soluble": "9.9",
        "fiber": "17.5",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food but often contains added sugar. Recommended frequency of consumption >400 g per day. Prefer fresh to canned food. Good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES ",
        "FOOD COMMODITY SUB-GROUP": "Vegetables canned",
        "FOOD COMMODITY ITEM": "CORN CANNED",
        "carbonFootprint": "1.29",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "806.6",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "105",
        "proteins": "3.4",
        "fat": "1.3",
        "saturatedFat": "0.2",
        "monounsaturatedFat": "0.3",
        "polyunsaturatedFat": "0.57",
        "carbohydrates": "20.5",
        "soluble": "5.3",
        "fiber": "1.4",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food but often contains added sugar. Recommended frequency of consumption >400 g per day. Prefer fresh to canned food. Good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "LEGUMES",
        "FOOD COMMODITY SUB-GROUP": "Legumes canned",
        "FOOD COMMODITY ITEM": "PEAS CANNED",
        "carbonFootprint": "1.27",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "806.6",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "75",
        "proteins": "5.8",
        "fat": "0.8",
        "saturatedFat": "0.29",
        "monounsaturatedFat": "0.24",
        "polyunsaturatedFat": "0.08",
        "carbohydrates": "6.8",
        "soluble": "2",
        "fiber": "9.7",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Excellent source of protein and fiber. Low fat content. Recommended to consume portions >of 150 g per week. Prefer fresh or dry to canned to save environmental impact. Medium/low environmental impact."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES ",
        "FOOD COMMODITY SUB-GROUP": "Vegetables canned",
        "FOOD COMMODITY ITEM": "TOMATO CANNED",
        "carbonFootprint": "0.89",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "806.6",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "22",
        "proteins": "1.2",
        "fat": "0.5",
        "saturatedFat": "0.07",
        "monounsaturatedFat": "0.08",
        "polyunsaturatedFat": "0.21",
        "carbohydrates": "3",
        "soluble": "3",
        "fiber": "0.9",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food but often contains added sugar. Recommended frequency of consumption >400 g per day. Prefer fresh to canned food. Good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "VEGETABLES ",
        "FOOD COMMODITY SUB-GROUP": "Vegetables canned",
        "FOOD COMMODITY ITEM": "PESTO WITHOUT GARLIC",
        "carbonFootprint": "2.71",
        "unitsCarbonFootprint": "kg CO2e/kg",
        "waterFootprint": "2249",
        "unitsWaterfootprint": "liters water/kg",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Yellow",
        "waterFootprintBandingImpactDescription": "MEDIUM",
        "blueSustainabilityIndex": "",
        "environmentalScore": "B",
        "FATTY_or_LEAN_fish": "",
        "energy": "312",
        "proteins": "47",
        "fat": "1.2",
        "saturatedFat": "0.14",
        "monounsaturatedFat": "0.21",
        "polyunsaturatedFat": "0.53",
        "carbohydrates": "20.9",
        "soluble": "0",
        "fiber": "1.2",
        "frequencyOfConsumption": "1-2 times per day",
        "recommendation": "300-400 g",
        "Recommendation_on_Sustainability_and_Nutritional": "Good source of vitamins, minerals, fiber, and water. Low-calorie food but often contains added sugar. Recommended frequency of consumption >400 g per day. Prefer fresh to canned food. Good environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "WINE&BEER",
        "FOOD COMMODITY SUB-GROUP": "WINE",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "1.13",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "579",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Light green",
        "carbonFootprintBandingImpactDescription": "LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "68",
        "proteins": "0.1",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "0.2",
        "soluble": "0.2",
        "fiber": "0",
        "frequencyOfConsumption": "",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "It is recommended to limit intake to one glass per day of both wine and beer. Excellent environmental score."
    },
    {
        "FOOD COMMODITY GROUP": "WINE&BEER",
        "FOOD COMMODITY SUB-GROUP": "BEER",
        "FOOD COMMODITY ITEM": "",
        "carbonFootprint": "0.98",
        "unitsCarbonFootprint": "kg CO2e/ liter",
        "waterFootprint": "155",
        "unitsWaterfootprint": "liters water/liter",
        "carbonFootprintBanding": "Dark green",
        "carbonFootprintBandingImpactDescription": "VERY LOW",
        "waterFootprintBanding": "Dark green",
        "waterFootprintBandingImpactDescription": "VERY LOW",
        "blueSustainabilityIndex": "",
        "environmentalScore": "A",
        "FATTY_or_LEAN_fish": "",
        "energy": "27",
        "proteins": "0.3",
        "fat": "0",
        "saturatedFat": "0",
        "monounsaturatedFat": "0",
        "polyunsaturatedFat": "0",
        "carbohydrates": "0",
        "soluble": "0",
        "fiber": "0",
        "frequencyOfConsumption": "",
        "recommendation": "",
        "Recommendation_on_Sustainability_and_Nutritional": "It is recommended to limit intake to one glass per day of both wine and beer. Excellent environmental score."
    }
];
