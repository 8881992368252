const chatgptUrl = "https://api.openai.com/v1/";

export class ChatGPT {
	public threadId;
	public latestRun;

	startThread = async () => {
		if (this.threadId) return this.threadId;
		let body = {
			// messages: [
			// 	{
			// 		role: "user",
			// 		content: threadContent,
			// 		// file_ids: threadFileIds
			// 	}
			// ]
		};
		let response = await fetch(`${chatgptUrl}threads`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer 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",
				"OpenAI-Beta": "assistants=v1"
			},
			body: JSON.stringify(body)
		});
		if (response.status === 500) {
			//console.log("Error");	
		} else if (response.status === 400) {
			const data = await response.json();
			//console.log(data.error.message);
		} else {
			const data = await response.json();
			this.threadId = data.id;
			//console.log(data);
			return this.threadId;
		}
	}

	addMessageToThread = async (message) => {
		let body = {
			role: "user",
			content: message
		};
		let response = await fetch(`${chatgptUrl}threads/${this.threadId}/messages`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer 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",
				"OpenAI-Beta": "assistants=v1"
			},
			body: JSON.stringify(body)
		});
		if (response.status === 500) {
			//console.log("Error");	
		} else if (response.status === 400) {
			const data = await response.json();
			//console.log(data.error.message);
		} else {
			const data = await response.json();
			//console.log(data);
			return data.id;
		}
	}

	runThread = async (assistant_id) => {
		let body = {
			assistant_id: assistant_id
			// model: "gpt-4",
			// model: "gpt-4-turbo-preview"
			
			// model: "gpt-4-1106-preview"
		};
		let response = await fetch(`${chatgptUrl}threads/${this.threadId}/runs`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer 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",
				"OpenAI-Beta": "assistants=v1"
			},
			body: JSON.stringify(body)
		});
		if (response.status === 500) {
			//console.log("Error");	
		} else if (response.status === 400) {
			const data = await response.json();
			//console.log(data.error.message);
		} else {
			const data = await response.json();
			if (data && data.id) {
				this.latestRun = data.id;
			}
			//console.log("data: " + data);
				
			return data;
		}
	}

	retriveRunStatus = async () => {
		let response = await fetch(`${chatgptUrl}threads/${this.threadId}/runs/${this.latestRun}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer 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",
				"OpenAI-Beta": "assistants=v1"
			}
		});
		if (response.status === 500) {
			//console.log("Error");	
		} else if (response.status === 400) {
			const data = await response.json();
			//console.log(data.error.message);
		} else {
			const data = await response.json();
			// console.log("data: " + data);
			if (data && data.status) {
				return data.status;
			}
			return "";
		}
	}

	getMessages = async () => {
		let response = await fetch(`${chatgptUrl}threads/${this.threadId}/messages`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer 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",
				"OpenAI-Beta": "assistants=v1"
			}
		});
		if (response.status === 500) {
			//console.log("Error");	
		} else if (response.status === 400) {
			const data = await response.json();
			// console.log(data.error.message);
		} else {
			const data = await response.json();
			// console.log("data: " + data);
			return data;
		}
	
	}

	cleanText = async (text) => {
		text = ""+text;
		// console.log("to replace: " + text);
		text = text.replace(/\*/g, '').replace(/【.*】/g,'').replace(/à/g,'a\'').replace(/á/g,'a\'');
		// console.log("new text: " + text);
		return text
		
	}

	convertAudioIntoText = async (media) => {
		const formData = new FormData();
		formData.append('file', media);
		formData.append('model', "whisper-1");
		let response = await fetch(`https://api.openai.com/v1/audio/transcriptions`, {
			method: "POST",
			headers: {
			//   "X-Api-Key": API_KEY,
			  "Authorization": "Bearer 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"
			},
			body: formData
		});
		if (response.status === 500) {
			//console.log("Error");	
		} else if (response.status === 400) {
			const data = await response.json();
			//console.log(data);
		} else {
			const data = await response.json();
			//console.log(data);
			return data;
		}
	}
	
}
