import { Company, EntityDefinition, Product } from "app/models/entities.model";
import { environment } from "environments/environment";

export class URLUtils {
	public static extract = ([beg, end]) => {
		const matcher = new RegExp(`${beg}(.*?)${end}`,'gm');
		const normalise = (str) => str.slice(beg.length,end.length*-1);
		return function(str) {
			return str.match(matcher).map(normalise);
		}
	}

	public static getDefaultCertPagePatternURL = ():string => {
		return environment.domainConfig.defaultCertPagePatternURL;
	}

	public static getDefaultProductLandingPagePatternURL = ():string => {
		return environment.domainConfig.defaultProductLandingPagePatternURL;
	}
	public static getDefaultEntityLandingPagePatternURL = ():string => {
		return environment.domainConfig.defaultEntityLandingPagePatternURL;
	}

	public static getCertPageURL = (company: Company, product: Product, entityDef?: EntityDefinition, entity?: any) : string => {
		let url = "";
		let certVerifyerUrl = (entityDef && entityDef.certVerifyerUrl)? entityDef.certVerifyerUrl: product.certVerifyerUrl;
		if (certVerifyerUrl) {
			url = certVerifyerUrl.replaceAll("{companyId}", company.companyId);
			url = url.replaceAll("{productId}", product.productId);
			if (entityDef) url = url.replaceAll("{entityId}", entityDef.entityId);
			if (entity)
				url = url.replaceAll("{id}", entity.id);
			else 
				url = url.replaceAll("{id}", product.id);

			if (url.indexOf("{") >= 0 && url.indexOf("}") > 0) {
				let fields: string[] = URLUtils.extract(['{','}']) (url);
				if (fields) {
					fields.forEach((s) => {
						if (entity && entity[s]) url = url.replaceAll("{"+s+"}", entity[s]);
						else if (product[s]) url = url.replaceAll("{"+s+"}", product[s]);
					})
				}
				
			}
			
		}
		return url;
	}
	public static getProductLandingPageURL = (company: Company, product: Product): string => {
		let url = "";
		if (product.landingPageUrl) {
			url = product.landingPageUrl.replaceAll("{productId}", product.productId);
			url = url.replaceAll("{companyId}", company.companyId);
			
			if (url.indexOf("{") >= 0 && url.indexOf("}") > 0) {
				let fields: string[] = URLUtils.extract(['{','}']) (url);
				if (fields) {
					fields.forEach((s) => {
						url = url.replaceAll("{"+s+"}", product[s]);
					})
				}
				
			}
			
		}
		return url;
	}
	public static getEntityLandingPageURL = (company: Company, product: Product, entityDef: EntityDefinition, entity: any): string => {
		let url = "";
		let landingPageUrl = (entityDef.landingPageUrl)? entityDef.landingPageUrl: product.landingPageUrl;
		if (landingPageUrl) {
			url = landingPageUrl.replaceAll("{productId}", product.productId);
			url = url.replaceAll("{companyId}", company.companyId);
			url = url.replaceAll("{entityId}", entityDef.entityId);
			url = url.replaceAll("{id}", entity.id);
			if (url.indexOf("{") >= 0 && url.indexOf("}") > 0) {
				let fields: string[] = URLUtils.extract(['{','}']) (url);
				if (fields) {
					fields.forEach((s) => {
						url = url.replaceAll("{"+s+"}", entity[s]);
					})
				}
				
			}
		}
		return url;
	}


}