import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { of, timer } from 'rxjs';

import { iconCancel, iconSave, iconTrash } from "app/common/utils/icons.utils";
import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { environment } from 'environments/environment';
import { ProfileService } from 'app/services/profile.service';
import { ModalService } from 'app/common/services/modal.service';
import { Company, EntityDefinition, FieldDefinition, FieldType, GroupDefinition, Product, ProductFieldDef, ValidatorType } from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'app/services/product.service';
import { Roles, UserProfile } from 'app/models/profile.models';
import { ToastService } from 'app/common/services/toasts.service';
import { URLUtils } from 'app/services/url-utils';

import { v4 as uuidv4 } from 'uuid';
import { EntityService } from 'app/services/entity.service';
import { NumberValidators } from 'app/common/components/form/validators/number.validator';
import { BlockChainService } from 'app/services/blockchain.service';

@Component({
    selector: 'add-entity-modal',
    templateUrl: './add-entity-modal.page.html'
})
export class AddEntityModalForm implements OnInit {
	// possibileMediaTypes = [];
	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	iconCancel = iconCancel;
	iconSave = iconSave;
	iconDelete = iconTrash;
	public currentEntityDef: any = {};
	public entityForm: FormGroup = null;	
	public additionalFields: FormArray = null;
	public walletForm: FormGroup = null;
	public linkedNFT: FormGroup = null;
	data: {
		product: Product,
		entity: EntityDefinition,
		company: Company,
		userProfile: UserProfile,
		readonlyFieldName: boolean,
		entityData: any
	};

	productDataReadonly = false;

	public get title() {
		return  "Aggiungi un nuovo " + this.data.entity.name;
	}

	get formValid() {
		return this.entityForm && this.entityForm.valid;
	}

	ngOnInit() {
		this._userProfileService.getLoggedProfile().subscribe((profile) => {
			if (profile && profile.walletInfo && profile.walletInfo.pubKey) {
				this.walletForm =this._fb.group({
					secret: [null, [CommonValidators.required, CommonValidators.walletPrivateKeyValidator(profile.walletInfo.pubKey, this._blockChainService)]]
				});
			}
		});
		

		if (this.data && this.data.entityData) {
			this._entityService.get(this.data.company.companyId, this.data.product.productId, this.data.entity.entityId, this.data.entityData.id)
			.pipe(
				catchError((e,c) => {
					return of(null);
				})
			)
			.subscribe((result) => {
				if (result)
					this.data.entityData = result;
				this.initForm();
				this.entityForm.patchValue(this.data.entityData);
			})
		}
		else {
			this.initForm();
		}	
	}
	get isAdmin() {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}
    constructor(
		private activeModal: NgbActiveModal,
		private _fb: FormBuilder,
		private _productService: ProductService,
		private _modalService: ModalService,
		private _toastService: ToastService,
		private _userProfileService: ProfileService,
		private _entityService: EntityService,
		private _blockChainService: BlockChainService
		) {
			
    }

	private getGroupEntityForm = (group: GroupDefinition) => {
		let entityDef: any = {};
		group.fields.forEach((filed) => {
			entityDef[""+filed.fieldLabel] = [filed.defaultValue, CommonValidators.required];
		});
		return this._fb.group(entityDef);
	}
	
	private initForm = () => {
		let entityFormDef: any = {
			// id: [null],
			// productId: [null, CommonValidators.required],
			// name:  [null, [
			// 	CommonValidators.required,
			// 	CommonValidators.patternMatchingValidator(patter,"Il nome deve essere composto solo di lettere numeri e simboli - . , &"),
			// 	CommonValidators.noWhiteSpaceAtStardAndEnd("non deve contenere spazi all'inizio ed alla fine del nome")
			// ]],
			// image: [null],
			// image_3d: [null],
			// productFields: this.additionalFields,
			// // landingPageUrl: [(initData)?null:URLUtils.getDefaultProductLandingPagePatternURL(), CommonValidators.websiteFormatValidator],
			// // certVerifyerUrl: [(initData)?null:URLUtils.getDefaultCertPagePatternURL(), CommonValidators.websiteFormatValidator],
			// landingPageUrl: [(initData && initData.landingPageUrl)?initData.landingPageUrl:null],
			// certVerifyerUrl: [(initData)?null:URLUtils.getDefaultCertPagePatternURL()],
			// linkedEntity: [null]
		};

		this.data.entity.groups.forEach((group) => {
			entityFormDef[""+group.groupLabel] = this.getGroupEntityForm(group);
		})

		this.linkedNFT = this._fb.group({
			numbers: [0, NumberValidators.gtOrEqThan(0)],
			descrizione: []
		})
		
		this.entityForm = this._fb.group(entityFormDef);
		
		

	}


    public save(certData?: boolean) {
        if (this.entityForm.valid) {
			// let toSave: Product = new Product();
			// if (this.data.product) {
			// 	Object.assign(toSave, this.data.product);
			// 	Object.assign(toSave, this.entityForm.value);
			// 	if (this.data.product.linkedEntity && this.data.product.linkedEntity.length>0) {
			// 		let index = this.data.product.linkedEntity.findIndex((entity) => {
			// 			if (this.data.entity) {
			// 				return entity.entityId && entity.entityId.toLowerCase() == this.data.entity.entityId.toLowerCase();
			// 			}
			// 			else return entity.entityId && entity.entityId.toLowerCase() == "lotto";
			// 		})
			// 		if (index >= 0) {
			// 			this.data.product.linkedEntity[index] = this.makeLinkedEntity(this.data.product.linkedEntity[index]);
			// 			toSave.linkedEntity[index] = this.data.product.linkedEntity[index];
			// 		}
			// 		else {
			// 			this.data.product.linkedEntity.push(this.makeLinkedEntity());
			// 			toSave.linkedEntity.push(this.makeLinkedEntity());
			// 		}
			// 	}
			// 	else {
			// 		this.data.product.linkedEntity = [];
			// 		toSave.linkedEntity = [];
			// 		this.data.product.linkedEntity.push(this.makeLinkedEntity());
			// 		toSave.linkedEntity.push(this.makeLinkedEntity());
			// 	}
			// }
			// else {
			// 	Object.assign(toSave, this.entityForm.value);
			// 	if (!toSave.linkedEntity) toSave.linkedEntity = [];
			// 	toSave.linkedEntity.push(this.makeLinkedEntity());
			// }
			
            // this._productService.saveOrUpdate(this.data.company.companyId, toSave)
			// .pipe(
			// 	catchError(error => {
			// 		this._toastService.showDanger("Si è verificato un errore durante il salvataggio dei dati.");
			// 		return of(null);
			// 	})
			// )
			// .subscribe(
			// 	(result) => {
			// 		this.data.product = result;
			// 		if (certData && this.walletForm) {
			// 			this._productService.certify(result, this.data.company, this.data.userProfile, this.walletForm.value.secret)
			// 				.catch((error) => {
			// 					this._toastService.showDanger("Si è verificato un errore durante la fase di certificazione dei dati. Si prega di riprovare più tardi o contattare il servizio clienti.");
			// 				})
			// 				.then((certResult) => {
			// 					this._toastService.showSuccess("I dati del prodotto sono stati salvati ed è stata richiesta correttamente la certificazione.");
			// 					this.closeModal(result, certData);
			// 				})
			// 		}
			// 		else {
			// 			this._toastService.showSuccess("I dati del prodotto sono stati salvati.");
			// 			this.closeModal(result, certData);
			// 		}
					
			// 	},
			// 	(error) => {
			// 		this._modalService.showErrors(["Si è verificato un errore durante la fase di salvataggio dei dati. Si prega di riprovare più tardi o contattare il servizio clienti."]);

			// 	}
			// )

			console.log(this.entityForm.value);
			this.saveLinkedEntity(certData);
        }
		else {
		}
    }

	
	public get logoImage(): string {
		return environment.domainConfig.companyLogo;
	}

	public get canSaveForm(): boolean {
		return this.entityForm && this.entityForm.valid;
	}

	public get canSaveAndCertify(): boolean {
		return this.canSaveForm && this.walletForm && this.walletForm.value.secret;
	}

	public close = (): void => {
		this.activeModal.close(false);
    }


	

	showProgress: boolean = false;

	private saveLinkedEntity(certData: boolean) {
		let value: any = {};
		if (this.data.entityData && this.data.entityData.id) {
			value = this.data.entityData;
		}
		Object.assign(value, this.entityForm.value);
		// let entityDefinition: EntityDefinition = this.makeLinkedEntity();
		// let value: any = {};
		// value["Informazioni specifiche per il Lotto"] = {};
		// this.entityFields.value.forEach((field) => {
		// 	value["Informazioni specifiche per il Lotto"][field.name] = field.value;
		// })
		this._entityService.saveOrUpdate(
			value,
			this.data.entity,
			this.data.product,
			this.data.company).subscribe((_result) => {
				this._toastService.showSuccess("I dati sono stati salvati.");
				this.activeModal.close(true);
				if (certData && this.walletForm) {
					this._entityService.certify(_result, this.data.entity, this.data.product, this.data.company, this.data.userProfile, this.walletForm.value.secret).then((__result) => {
						this._toastService.showSuccess("I dati sono stati salvati certificati.");
						// let linkedNft = this.linkedNFT.value;
						// if (linkedNft.numbers > 0) {
						// 	let nft: any = {
						// 		refEntityId:_result.id,
						// 		"Informazioni specifiche del NFT": {descrizione: linkedNft.descrizione}
						// 	}
						// 	this.templateNFT.groups.push(entityDefinition.groups[0]);

						// 	for (let i=0; i < linkedNft.numbers; i++) {
						// 		console.log("Saving ", nft);
								
						// 		this._entityService.saveOrUpdate(
									
						// 			nft, this.data.company.companyId, this.data.product.productId, entityDefinition.entityId+".nft").subscribe((r) => {
						// 				if (certData) {
						// 					r["Informazioni specifiche per il Lotto"] = value["Informazioni specifiche per il Lotto"];
						// 					this._entityService.certify(r,this.templateNFT,this.data.product, this.data.company, this.data.userProfile, this.walletForm.value.secret).then((nftRes) => {});
						// 				}
						// 			})
						// 	}
						// }
						
					})
				}							
			})
		}

}


