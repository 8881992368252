import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { PipeModule } from 'app/pipes/pipe.module';
import { UnauthorizedModule } from '../unautorized-page/unautorized.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataViewPage } from './data-view/data-view.page';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { ReceiptCreationComponent } from './receipt-creation/receipt-creation.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FoodItemsPage } from './food-items/food-items.page';
import { AboutPage } from './about-page/about.page';
import { FoodItemsListComponent } from './food-items/food-items-list/food-items-list.component';
import { ItemDetailModalComponent } from './item-details/item-detail-modal/item-detail-modal.component';
import { NutriscoreDetailComponent } from './item-details/nutriscore-detail/nutriscore-detail.component';
import { EnvironmentalBandingDetailComponent } from './item-details/environmental-banding-detail/environmental-banding-detail.component';
import { IngrediantSearchModalComponent } from './receipt-creation/ingrediant-search-modal/ingrediant-search-modal.component';
import { PopupInfoComponent } from './popup-info/popup-info.component';


export const SwitchDataAnalisysRoutes: Routes = [
    { path: 'switch-home', component: DataViewPage, canActivate: []},
    { path: 'food-items', component: FoodItemsPage, canActivate: []},
    { path: 'recipe-creation', component: ReceiptCreationComponent, canActivate: []},
    { path: 'about-page', component: AboutPage, canActivate: []}
];


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule.forChild(SwitchDataAnalisysRoutes),
		NgJsonEditorModule,
		ArchwizardModule,
		UnauthorizedModule,
		PipeModule,
		NgbModule,
		NgApexchartsModule
    ],
    exports: [
		DataViewPage,
		ItemDetailsComponent,
		EnvironmentalBandingDetailComponent,
		NutriscoreDetailComponent,
		ReceiptCreationComponent,
		FoodItemsListComponent,
		ItemDetailModalComponent,
		IngrediantSearchModalComponent,
		PopupInfoComponent
	],
    declarations: [
		DataViewPage,
		ItemDetailsComponent,
		EnvironmentalBandingDetailComponent,
		NutriscoreDetailComponent,
		ReceiptCreationComponent,
		FoodItemsListComponent,
		FoodItemsPage,
		AboutPage,
		ItemDetailModalComponent,
		IngrediantSearchModalComponent,
		PopupInfoComponent
    ],
    providers: [],
	entryComponents: [
		DataViewPage,
		ItemDetailsComponent,
		EnvironmentalBandingDetailComponent,
		NutriscoreDetailComponent,
		ReceiptCreationComponent,
		FoodItemsPage,
		AboutPage,
		FoodItemsListComponent,
		ItemDetailModalComponent,
		IngrediantSearchModalComponent,
		PopupInfoComponent
	]
})
export class SwitchDataAnalisysModule { }
