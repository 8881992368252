import { Component, Input, OnInit } from '@angular/core';
import { DataTableColumn, DataTableAction, DataTableColumnAlignment, IDataTableManager } from './types';
import { filter } from 'rxjs/operators';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BaseComponent } from '../base.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ["./data-table.component.scss"]
})
export class DataTableComponent extends BaseComponent implements OnInit {
    constructor(private _sanitizer: DomSanitizer) {
        super();
    }

    private _manager: IDataTableManager<any> = null;
    private _managerSubscription: Subscription = null;
    public totalCount: number = 0;
    public items: any[] = [];
    public pageIndex: number = 1;
    public pageSize: number = 0;
    public sortColumn: string = null;
    public sortAscending?: boolean = null;

    @Input() public columns: DataTableColumn[] = []
    @Input() public actions: DataTableAction[] = []
    @Input() public allowedPageSizes: number[] = [10, 20, 50, 100];
    @Input() public get manager() {
        return this._manager;
    }
    public set manager(value: IDataTableManager<any>) {
        this.destroySubscriptions();
        this._manager = value;
        if (value) {
            //Imposto i parametri
            this.pageIndex = (this._manager.searchCriteria.pageNumber || 1);
            this.pageSize = this._manager.searchCriteria.itemsPerPage || this.allowedPageSizes[0];
            this.sortColumn = this._manager.searchCriteria.field;
            this.sortAscending = this._manager.searchCriteria.ascending;
            this._managerSubscription = this.manager.data.subscribe(results => {
                this.items = [...results.data];
                this.totalCount = results.totalCount;
            });
        }
    }

    ngOnInit(): void {
        this.pageSize = this.allowedPageSizes[0];
    }

    private search = (reloading: boolean): void => {
        this.manager.searchCallback(this.pageIndex, this.pageSize, reloading, this.sortColumn, this.sortAscending);
    }

    public changePageIndex = (pageIndex: number): void => {
        this.pageIndex = pageIndex;
        this.search(true);
    }

    public changePageSize = (pageSize: number): void => {
        this.pageIndex = 1;
        this.pageSize = pageSize;
        this.search(true);
    }

    public changeSort = (column: DataTableColumn): void => {
        let sortableColumn = this.columns.find(f => f.name == column.name && f.sortable);
        if (sortableColumn) {
            if (this.sortColumn == column.name) {
                this.sortAscending = !this.sortAscending;
            }
            else {
                this.sortColumn = column.name;
                this.sortAscending = true;
            }
            this.search(true);
        }
    }

    public getAlignment = (column: DataTableColumn, index: number): SafeStyle => {
        if (index == 0) {
            return this._sanitizer.bypassSecurityTrustStyle("justify-content:flex-start");
        }
        switch (column.alignment) {
            case DataTableColumnAlignment.CENTER:
                return this._sanitizer.bypassSecurityTrustStyle("justify-content:center");
            case DataTableColumnAlignment.RIGHT:
                return this._sanitizer.bypassSecurityTrustStyle("justify-content:flex-end");
            default:
                return this._sanitizer.bypassSecurityTrustStyle("justify-content:flex-start");
        }
    }
    protected destroy = (): void => {
        this.destroySubscriptions();
    };

    private destroySubscriptions = (): void => {
        if (this._managerSubscription) {
            this._managerSubscription.unsubscribe();
            this._managerSubscription = null;
        }

    }

	invokeAction = ($event, action: DataTableAction, item): void => {
		if (action.enableFunc(item))
			action.funcToInvoke(item)
		$event.preventDefault();
	}
}  
