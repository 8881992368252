import moment from 'moment'

// @dynamic
export class DatetimeUtils {

    public static toDate = (value: string, isUtc: boolean = true): string => {
        if (value) {
            if (isUtc) {
                return moment.utc(value).local().format('L');
            }
            else {
                return moment(value, "YYYY-MM-DD").format('L');
            }
        }
        return value;
    }

    public static toFullDate = (value: string, isUtc: boolean = true): string => {
        if (value) {
            if (isUtc) {
                return moment.utc(value).local().format('LL');
            }
            else {
                return moment(value, "YYYY-MM-DD").format('LL');
            }
        }
        return value;
    }

    public static toDateTime = (value: string): string => {
        if (value) {
            return moment.utc(value).local().format('L LTS');
        }
        return value;
    }

    public static toTime = (value: string): string => {
        if (value) {
            return moment.utc(value).local().format('LTS');
        }
        return value;
    }

    public static toSmallTime = (value: string): string => {
        if (value) {
            return moment.utc(value).local().format('LT');
        }
        return value;
    }

}