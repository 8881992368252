declare global {
    interface Array<T> {
        sortAsc(field: (field: T) => any): T[];
        sortDesc(field: (field: any) => any): T[];
        min(field: (field: T) => number): number;
        max(field: (field: T) => number): number;
        selectMany<C = any>(field: (field: T) => C[]): C[];
        distinct<C= any>(field: (field: T) => C): C[];
        groupBy<C = any>(group: (field: T) => any): Group<T, C>[]
        containsInArray<U, P>(otherArray: U[], parentField: (field: T) => P, childField: (field: U) => P): T[];
        notContainsInArray<U, P>(otherArray: U[], parentField: (field: T) => P, childField: (field: U) => P): T[];
        any(): boolean;
        first(): T;
        last(): T;
        contains(value: T): boolean;
        sum(field: (field: T) => number): number;
    }
}
Array.prototype.sortAsc = function (field: (field: any) => any) {
    this.sort((first, second) => {
        if (field(first) > field(second)) {
            return 1;
        }
        return -1;
    })
    return this;
}
Array.prototype.sortDesc = function (field: (field: any) => any) {
    this.sort((first, second) => {
        if (field(first) > field(second)) {
            return -1
        }
        return 1;
    })
    return this;
}
Array.prototype.min = function (field: (field: any) => number) {
    let ret = null;
    for (let i = 0; i < this.length; i++) {
        if (ret == null || ret > field(this[i])) {
            ret = field(this[i]);
        }
    }
    return ret;
}
Array.prototype.max = function (field: (field: any) => number) {
    let ret = null;
    for (let i = 0; i < this.length; i++) {
        if (ret == null || ret < field(this[i])) {
            ret = field(this[i]);
        }
    }
    return ret;
}
Array.prototype.selectMany = function <U>(field: (field: U[]) => U[]) {
    if (this.length == 0) {
        return [];
    };
    if (this.length == 1) {
        return field(this[0]);
    }
    return this.reduce((result, array) => {
        return result.concat(field(array));
    }, []);
}
Array.prototype.distinct = function <T, U>(field: (field: T) => U) {
    if (this.length == 0) {
        return [];
    };
    let set = new Set<U>();
    for (let i = 0; i < this.length; i++) {
        set.add(field(this[i]));
    }
    return Array.from(set);
}
Array.prototype.groupBy = function <T, U>(group: (field: T) => U) {
    if (this.length == 0) {
        return [];
    };
    let ret: Group<T, U>[] = [];
    for (let i = 0; i < this.length; i++) {
        let currentKey = group(this[i]);
        let currenJsonKey = JSON.stringify(currentKey);
        let currentGroup: Group<T, U> = null;
        for (let x = 0; x < ret.length; x++) {
            let jsonKey = JSON.stringify(ret[x].key);
            if (currenJsonKey == jsonKey) {
                currentGroup = ret[x];
                break;
            }
        }

        if (!currentGroup) {
            currentGroup = new Group(currentKey);
            ret.push(currentGroup);
        }

        currentGroup.items.push(this[i]);
    }
    return ret;
}
Array.prototype.containsInArray = function <T, U, P>(otherArray: U[], parentField: (field: T) => P, childField: (field: U) => P) {
    if (otherArray.length == 0) {
        return [];
    }
    return this.filter(f => otherArray.findIndex(fi => childField(fi) == parentField(f)) >= 0);
}
Array.prototype.notContainsInArray = function <T, U, P>(otherArray: U[], parentField: (field: T) => P, childField: (field: U) => P) {
    if (otherArray.length == 0) {
        return this;
    }
    return this.filter(f => otherArray.findIndex(fi => childField(fi) == parentField(f)) < 0);
}
Array.prototype.any = function () {
    return this.length > 0;
}
Array.prototype.first = function () {
    return this.length > 0 ? this[0] : null;
}
Array.prototype.last = function () {
    return this.length > 0 ? this[this.length - 1] : null;
}
Array.prototype.contains = function <T>(value: T) {
    return this.indexOf(value) >= 0;
}
export class Group<T, U>{
    public items: T[] = []
    constructor(public key: U) {

    }
}

export { };