import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Observable, of, timer} from 'rxjs';

import { iconCancel, iconSave, iconTrash } from "app/common/utils/icons.utils";

import { ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ModalService } from 'app/common/services/modal.service';
import { Company, EntityDefinition, FieldType, Product} from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'app/services/product.service';
import { ToastService } from 'app/common/services/toasts.service';
import { URLUtils } from 'app/services/url-utils';

import { EntityService } from 'app/services/entity.service';
import { DataTableAction, DataTableColumn, ListTableManager } from 'app/common/components/table/types';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import * as XLSX from 'xlsx';
import { CommonValidators } from 'app/common/components/form/validators/common.validator';
import { CompanyService } from 'app/services/company.service';
import { ProfileService } from 'app/services/profile.service';
import { UserProfile } from 'app/models/profile.models';
import { BlockChainService } from 'app/services/blockchain.service';

@Component({
    selector: 'excel-file-reader-modal',
    templateUrl: './excel-file-reader.modal.html'
})
export class ModalExcelFileReader extends BaseListTablePage<any> {
	dataLoaded;
	public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];

	public walletForm: FormGroup = null;
	
	private productDefinition: Product;
	private referredEntity: EntityDefinition;
	private company: Company;
	private userProfile: UserProfile;

	iconCancel = iconCancel;
	iconSave = iconSave;
	uploading: boolean = false;

	data: {
		tableColumns: DataTableColumn[];
		companyId: string;
		productId: string;
		entityType: string;
	};

	dataToImport: any = [];

	constructor(
		_activatedRoute: ActivatedRoute,
		private activeModal: NgbActiveModal,
		private _fb: FormBuilder,
		private _productService: ProductService,
		private _companyService: CompanyService,
		private _entityService: EntityService,
		private _profileService: ProfileService,
		private _blockChainService: BlockChainService
		) {
			super(_activatedRoute,false);
    }


	protected getDataTableManager = (): ListTableManager<any> => {
        return new ListTableManager(this.getEntities)
    }

	private getEntities = (): Observable<any[]> => {
		return of(this.dataToImport);
	}

	initialize = (): void => {	
		this.dataLoaded = false;
		

		

		let toCall : any = {};
		toCall["userProfile"] = this._profileService.getLoggedProfile();
		toCall["product"] = this._productService.getByField( this.data.companyId, "productId",this.data.productId);
		toCall["company"] = this._companyService.getCompanyByField("companyId", this.data.companyId);
		forkJoin(toCall).subscribe((data) => {
			this.company = data["company"];
			this.productDefinition = data["product"];
			this.userProfile = data["userProfile"];
			this.productDefinition.linkedEntity.forEach((entity) => {
				if (entity.entityId ==this.data.entityType) {
					this.referredEntity = entity;
				}
			})
			if (this.userProfile && this.userProfile.walletInfo && this.userProfile.walletInfo.pubKey) {
				this.walletForm =this._fb.group({
					secret: [null, [CommonValidators.required, CommonValidators.walletPrivateKeyValidator(this.userProfile.walletInfo.pubKey, this._blockChainService)]]
				});
			}
			
		})
		this.createColumms();
	}
	public close = (): void => {
		this.activeModal.close(true);
    }

    private createColumms = (): void => {
		this.tableColumns = this.data.tableColumns;
    }

	fileSelected: string = 'Nessun file selezionato';

	public onFileSelected(fileList: FileList): void {
		this.dataLoaded = false;
		let file = fileList[0];
		if (file) {
			if (file.name.endsWith(".xls") || file.name.endsWith(".xlsx") ) {
				this.fileSelected = file.name;
				let fileReader: FileReader = new FileReader();
				fileReader.onload = (x) => {
					var data = x.target.result;
					
					
					var workbook = XLSX.read(data);
					var jsa = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
						blankrows: false,
						raw: true
					});
					this.dataToImport = jsa;
					this.dataLoaded = true;
					this.dataTableManager.startReload();
			console.log("dataToImport",this.dataToImport);
					
				}
				fileReader.readAsArrayBuffer(file);
			}
			else {
				this.fileSelected = 'Nessun file selezionato. Selezionare un file excel';
			}
		}
		else {
			this.fileSelected = 'Nessun file selezionato. Selezionare un file excel';
		}
	}

	title =  "Caricamento massivo da excel";
	
	exportedDataCount = 0;
	dataCount = 20;
	get importLabel() {
		if (this.exportedDataCount < this.dataCount && this.uploading) return "Caricamento massivo in corso";
		return "Caricamento massivo completato";
	}
	get exportedDataCountPercent() {
		if (this.dataCount > 0) return (this.exportedDataCount/this.dataCount)*100;
		return 0;
	}

	importData = async () => {
		

		if (this.dataToImport && this.dataToImport.length > 0) {
			this.dataCount = this.dataToImport.length;
			this.uploading = true;
			let dataToSave: any[] = [];
			
			this.dataToImport.forEach((element) => {
				let newEntity: any = {};
				console.log("this.referredEntity", this.referredEntity);
				
				this.referredEntity.groups.forEach((group, index) => {
					newEntity[group.groupLabel] = {};
					group.fields.forEach((field) => {
						newEntity[group.groupLabel][""+field.fieldLabel] = element[""+field.fieldLabel];
					})
				})
				dataToSave.push(newEntity);
			});
			
			for (const row of dataToSave) {
				let result = await this._entityService.createAndWait(
					row,
					this.data.companyId, this.data.productId, this.data.entityType);
				await  this._entityService.certify(
					result,
					this.referredEntity,
					this.productDefinition,
					this.company,
					this.userProfile,
					this.walletForm.value.secret).then((r) => {})
				this.exportedDataCount++;
			}
			this.uploading = false;
			// this.activeModal.close(true);
		}
	}

	public get canSaveData() {
		return (this.dataToImport && this.dataToImport.length > 0 && this.walletForm && this.walletForm.valid && this.referredEntity && this.exportedDataCount == 0);
	}

}


