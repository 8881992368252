import { Inject, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SortCriteria } from 'app/common/models/models';
import { BaseComponent } from '../base.component';
import { ListTableManager } from '../table/types';
@Component({
    template: ''
})
export abstract class BaseListTablePage<TData> extends BaseComponent implements OnInit {
    public dataTableManager: ListTableManager<TData> = null;
	@ViewChild('listTable') listTable;

    constructor(protected activatedRoute: ActivatedRoute, @Inject(true) protected autoSearch: boolean = true) {
        super();
    }
	filterValue="";
	filterKeys;

    ngOnInit(): void {
        this.on(this.activatedRoute.queryParams.subscribe(queryParams => {
            //Leggo i parametri di ricerca dalla return Url
			if (queryParams.filterText) this.filterValue = queryParams.filterText;

            this.dataTableManager = this.getDataTableManager();
			if (queryParams.sortColumn) {
				let sort: SortCriteria = {
					ascending: (queryParams.sortAscending=='true')?true:false,
					field: queryParams.sortColumn
				}
				this.dataTableManager.sortCriteria = sort;
			}
            if (this.autoSearch) {
                this.dataTableManager.startReload();
            }
        }));
        this.initialize();
    }

    protected initialize = (): void => {

    }
    protected getDataTableManager = (): ListTableManager<TData> => {
        return null;
    }

    protected getReturnUrl = (baseUrl: string = null): string => {
        const ret = baseUrl || window.location.pathname;
        const urlParams = new URLSearchParams(window.location.search);
		let additionalParams = "";
		if (this.listTable) {
			if (this.listTable.filterText) additionalParams += "&filterText="+this.listTable.filterText;
			if (this.listTable.sortColumn) additionalParams += "&sortColumn="+this.listTable.sortColumn;
			if (this.listTable.sortColumn) additionalParams += "&sortAscending="+this.listTable.sortAscending;

		}
        return ret + '?' +  additionalParams;
        // return ret + '?' + urlParams.toString() + additionalParams;
    }
}