import { Directive, OnDestroy } from '@angular/core';
import { NumberDirective } from './number.directive';
import { CultureService } from '../services/culture.service';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[decimal]'
})
export class DecimalDirective extends NumberDirective implements OnDestroy {
    private _subscription: Subscription;
    private _separatorKeyCode: number = null;
    constructor(private _cultureService: CultureService) {
        super();
        this._subscription = this._cultureService.cultureChanged.subscribe(result => {
            this.setSeparator(result);
        });
        this.setSeparator(this._cultureService.getCurrentCulture().code);
    }
    protected additionalCheck(event: KeyboardEvent): boolean {
        return event.keyCode == this._separatorKeyCode;
    }

    private setSeparator = (cultureCode: string): void => {
        let separator = getLocaleNumberSymbol(cultureCode, NumberSymbol.Decimal)
        this._separatorKeyCode = separator == "." ? 190 : 188;
    }

    ngOnDestroy(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
            this._subscription = null;
        }
    }

}