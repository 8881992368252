import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidatorUtils } from './validator-utils';
import { CustomValidationErrors } from './models';

// @dynamic
export class NumberValidators {
    public static isInteger(c: AbstractControl): CustomValidationErrors | null {
        if (c.value != null) {
            if (isNaN(c.value)) {
                return ValidatorUtils.integer();
            }
            if (Math.trunc(c.value) != c.value) {
                return ValidatorUtils.integer();
            }
        }
        return null;
    };
    public static isDecimal = (numOfDecimals: number): ValidatorFn => {
        return (c: AbstractControl): CustomValidationErrors | null => {
            if (c.value != null) {
                if (isNaN(c.value)) {
                    return ValidatorUtils.decimal();
                }
                if (c.value) {
                    if (Math.trunc(c.value) != c.value) {
                        if (c.value.toString().split('.')[1].length > numOfDecimals) {
                            return ValidatorUtils.decimalInvalidDigits(numOfDecimals);
                        }
                    }
                }
            }
            return null;
        };
    }
	public static gtOrEqThan = (value: number): ValidatorFn => {
        return (c: AbstractControl): CustomValidationErrors | null => {
            if (c.value < value) {
                return { "inserisci un valore più grande di ": [value] }
            }
            return null;
        };
    }
}
