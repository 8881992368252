
export enum LPButtonLinkType {
	URL = "URL",
	PDF = "PDF",
	Instagram = "Instagram", 
	Facebook = "Facebook",
	shopOnline = "shopOnline"
}

export class LPSectionButtonLink {
	// label: string;
	url: string;
	type: LPButtonLinkType;
}

export class LandingPageSection {
	image: any;
	video: any;
	title: string;
	subtitle: string;
	description: string;
	buttons?: LPSectionButtonLink[] = [];
	linkedFields: string[] = [];
}

export class LandingPageDefinition {
	id: any;
	pageId: string;
	backgroudColor?: string;
	textColor?: string;
	companyLogo?: string;
	companyName?: string;
	companyAddress?: string;
	companyPlace?: string;
	phones?: string[];
	email?: string;
	vat?: string;
	landingPageTitle: string;
	sectionsLogo: any;
	avatarLogo?: any;
	
	sections: LandingPageSection[] = [];
	useAvatar: boolean = false;
}