import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { CultureService } from "app/common/services/culture.service";
import { AuthService } from "./auth.service";

@Injectable({
	providedIn: 'root',
})
export class HttpService{
    constructor(private http: HttpClient, private cultureService: CultureService, private _authService: AuthService) {
		
	}
	
	private errorHandler(error: HttpErrorResponse) {
		return throwError(error.message || "UNDEFINED_SERVER_ERROR");
    }

    public get = <T = any>(url:string, responseType?: "json" | "text" | "blob" | "arraybuffer", observe: string="body"): Observable<T> => {
		return this.http.get<T>(url,this.getHeaders(responseType,{},false, observe)).pipe(
			catchError(this.errorHandler)
		);
	}
		

    public post = <T = any>(url:string, data : any, responseType?: "json" | "text" | "blob" | "arraybuffer", customHeaders: any = {}): Observable<T> => {
        return this.http.post<T>(url,data, this.getHeaders(responseType,customHeaders,false)).pipe(
			catchError(this.errorHandler)
		);
    }

    public put = <T = any>(url:string, data : any, responseType?: "json" | "text" | "blob" | "arraybuffer"): Observable<T> => {
		return this.http.put<T>(url,data, this.getHeaders(responseType,{},false)).pipe(
			catchError(this.errorHandler)
		);
    }
    public delete = <T = any>(url:string, responseType?: "json" | "text" | "blob" | "arraybuffer"): Observable<T> => {
        return this.http.delete<T>(url,this.getHeaders(responseType,{},false)).pipe(
			catchError(this.errorHandler)
		);
    }

	private getHeaders = (responseType: "json" | "text" | "blob" | "arraybuffer", customHeaders: any = {}, reportProgress :boolean = false, observe: string="body"):
		{
        	headers?: HttpHeaders | {[header: string]: string | string[];};
			observe?: 'body';
			params?: HttpParams | {[param: string]: string | string[];};
			reportProgress?: boolean;
			responseType?: 'json';
			withCredentials?: boolean;
		} => {        
			let requestOptions: any = {};
			requestOptions.responseType = responseType || "json";
			requestOptions.observe = observe;
			requestOptions.reportProgress = reportProgress;

			let headers = new HttpHeaders();
	        for (let prop in customHeaders) {
				headers = headers.set(prop, customHeaders[prop]);
        	}
        	headers = headers.set("Accept-Language", this.cultureService.getCurrentCulture().code);
			const accessToken = this._authService.getAccessToken();
			if (accessToken) {
				headers = headers.set("Authorization", "Bearer " + accessToken);
			}

        	requestOptions.headers = headers;			
			return requestOptions;
    }
}