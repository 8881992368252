import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonLinkType, CertificatePageDefinition, SectionButtonLink } from 'app/models/entities.model';
import { CertificatePageService } from 'app/services/certificate-page.service';
// import { Roles, UserProfile } from 'app/models/profile.models';
import { forkJoin, of, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';
import moment from 'moment'
import { CertVerifyModal } from './cert-verifier-modal/cert-verifier.modal';
import { ModalService } from 'app/common/services/modal.service';
import { ModalOptions } from 'app/common/models/models';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ViewportScroller } from '@angular/common';
import { CompanyService } from 'app/services/company.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'landing-page',
	templateUrl: './landing-page.page.html',
	styleUrls: ['landing-page.scss']
})
export class LandingPage implements OnInit, AfterViewInit{
	companyId: string;
	landingPageConfig: CertificatePageDefinition;
	certificate: any;
	issue_date: any;
	error: "NOT_FOUND" | "NOT_AVAILABLE";
	pageLoaded = false;
	certSignature: any = {};
	certificateId;
	@ViewChild('dataToPrint') dataToPrint: ElementRef;
	@ViewChild('CERT_DATA') CERT_DATA: ElementRef; 
	landingPageSiteLogo = environment.domainConfig.landingPageSiteLogo;
	constructor(
		private _router: Router,
		private _viewportScroller: ViewportScroller,
		private _translate: TranslateService,
		private _modalService: ModalService,
		private _landingPageService: CertificatePageService,
		private _activatedRoute: ActivatedRoute,
		private _companyService: CompanyService
	) {

	}

	imageBaseUrl = environment.services.mediaContentsBasePath + "/";

	companyLogo;

	ngAfterViewInit(): void {
		this._activatedRoute.fragment.subscribe(fragment => { 
			if (fragment){
				timer(1000).subscribe(() => {
					const targetElement = this.CERT_DATA.nativeElement
					targetElement.scrollIntoView({behavior: "smooth"})
				})
				
			}
			
		});
	}

	ngOnInit() {
		// if (this._userProfileService.isInRole(Roles.DASHBOARD_USER)) {
		// 	this._router.navigate(["/user-profile"], { });
		// }
		this.changeLanguage(this._translate.getBrowserLang());
		this._activatedRoute.paramMap.subscribe(qp => {
			

			if (qp && qp.get("companyId")) {
				this.companyId = qp.get("companyId");
			}
			if (qp) {
				let lpId = qp.get("lpId");
				this.certificateId = qp.get("certId");
				let toCall: any = {
					certificate: this._landingPageService.getCertificate(this.companyId, this.certificateId),
					company: this._companyService.getCompanyByField("companyId", this.companyId)
				};

				if (lpId) {
					toCall["landingPageConfig"] = this._landingPageService.get(this.companyId, lpId).pipe(
						catchError(r => {
							return of(null)
						}),
					)
				}
				forkJoin(toCall).pipe(
					catchError(error => {
						return of(null);
					})
				)
				.subscribe(
					(result) => {
						
						if (result) {
							this.landingPageConfig = result["landingPageConfig"];
							if (this.landingPageConfig && this.landingPageConfig.companyLogo) {
								this.companyLogo = this.imageBaseUrl + this.landingPageConfig.companyLogo;
							}
							else if (result.company && result.company.logo) {
								this.companyLogo =  environment.services.mediaContentsBasePath + "/" + result.company.logo;
							}
							this.certificate = {};
							if (result["certificate"]) {
								if (!result["certificate"].signature) {
									this.error = 'NOT_AVAILABLE';
								}
								else if (result["certificate"].data && result["certificate"].data.data) {
									this.issue_date = moment(result["certificate"].data.issue_date, "DDMMYYYY").format("DD-MM-YYYY");
									for (let key in result["certificate"].data.data) {
										let newKey = key.split("_").join(" ");
										this.certificate[newKey] = result["certificate"].data.data[key].split("_").join(" ");
										// this.certificate.data.data[newKey] = this.certificate.data.data[key].split("_").join(" ");
									}
									this.certSignature = result["certificate"].signature;
								}
							}
							else {
								this.error = 'NOT_FOUND';
							}
						}
						else {
							this.error = 'NOT_FOUND';
						}
						this.pageLoaded = true;
					},
					(error) => {
						console.log("", error);
						this.error = 'NOT_FOUND';
						this.pageLoaded = true;
					}
				)
			}
		});
	}

	fieldIsUrs = (fieldLabel: string) => {
		return (this.certificate[""+fieldLabel] || "").startsWith("http");
	}

	isHash = (fieldLabel: string) => {
		return (""+fieldLabel).indexOf("hash") >=0;
	}

	getLabelForField = (fieldLabel: string) => {
		if (fieldLabel.split("-").length > 1)
			return fieldLabel.split("-")[1];
		return fieldLabel;
	}

	getValueForField = (fieldLabel: string) => {
		return this.certificate[""+fieldLabel] || "";
	}

	getCertFields = () => {
		let result = [];
		for (let key in this.certificate) {
			let k;
			if (key.split("-").length > 1)
				k = key.split("-")[1];
			else k=key;
			result.push({
				label: k, value: this.certificate[key]
			})
		}
		return result;
	}

	goto = (button: SectionButtonLink) => {
		if (button && button.type == ButtonLinkType.PDF)
			window.open(this.imageBaseUrl + button.url, "_blank");
		else
			window.open(button.url, "_blank");
	}

	get transactionHash() {
		return (this.certSignature && this.certSignature.anchors && this.certSignature.anchors[0])? this.certSignature.anchors[0].sourceId: "N/A";
	}

	goToPublicRegistry = () => {
		if (this.certSignature && this.certSignature.anchors && this.certSignature.anchors[0]) {
			window.open("https://polygonscan.com/tx/"+this.certSignature.anchors[0].sourceId,  "_blank");
		}
	}

	verifyCert = () => {
		let modalOption: ModalOptions<boolean> = {
			size: 'sm',
			callback: (res) => {
				
			}
		}
		let data = {
			certificate: this.certificate,
			certSignature: this.certSignature
		}
		this._modalService.show(CertVerifyModal, data, modalOption);
	}
	

	print = () => {
		// var doc = new jsPDF('portrait', 'mm', 'a4');
		// var elementHTML = document.getElementById("dataToPrint");
		// doc.html(elementHTML, {
		// 	callback: function (doc) {
		// 		// Save the PDF
		// 		doc.save('certificato-'+this.certificateId+'.pdf');
			
		// 	},
		// 	margin: [15, 15, 15, 15],
		// 	autoPaging:  'text',
		// 	x: 0,
		// 	y: 0,
		// 	width: 190, //target width in the PDF document
		// 	windowWidth: 675 //window width in CSS pixels
		// });

		
		var doc = new jsPDF('portrait', 'mm', 'a4');
		var elementHTML = document.getElementById("dataToPrint");
		doc.html(elementHTML, {
			callback: function (doc) {
				// Save the PDF
				doc.save('certificato-'+this.certificateId+'.pdf');
			
			},
			margin: [15, 15, 15, 15],
			autoPaging:  'text',
			x: 0,
			y: 0,
			width: 190, //target width in the PDF document
			windowWidth: 675 //window width in CSS pixels
		});
	}

	selectedLanguageFlag = "/assets/img/flags/flag-it.png";
	changeLanguage(language: string) {
		console.log("changing languange: " + language);
		
		this._translate.use(language);
	
		if (language === 'en') {
		  this.selectedLanguageFlag = "/assets/img/flags/flag-en.png";
		}
		else if (language === 'es') {
		  this.selectedLanguageFlag = "/assets/img/flags/flag-es.png";
		}
		else if (language === 'it') {
		  this.selectedLanguageFlag = "/assets/img/flags/flag-it.png";
		}
		else if (language === 'fr') {
		  this.selectedLanguageFlag = "/assets/img/flags/flag-fr.png";
		}
	  }
}