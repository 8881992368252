import { Injectable } from "@angular/core";
import { Company, EntityDefinition, FieldType, Product } from "app/models/entities.model";
import { UserProfile } from "app/models/profile.models";
import { environment } from "environments/environment";
import moment from "moment";
import { Observable, of} from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { BlockChainService } from "./blockchain.service";
import { HttpService } from "./http.service";
import { URLUtils } from "./url-utils";
import { SwitchDataLakeService } from "./switch-data-lake.service";


@Injectable({
	providedIn: 'root'
})
export class EntityService {
	private baseUrl = environment.services.apiBasePath;
	constructor(private _httpService: HttpService, private _chainService: BlockChainService, private _dataLake: SwitchDataLakeService) {
	}
	public getAll = (companyId: string, productId: string, entityId: string,  fieldsName?: string[]): Observable<any[]> => {
		let queryParams = ""
		console.log("fieldsName " + fieldsName.length, fieldsName);
		
		if (fieldsName && fieldsName.length > 0) {
			console.log("hhhhh");
			
			queryParams = "?";
			fieldsName.forEach(field => {
				queryParams += "fields="+field+ "&";
			})
			queryParams += "fields=certified"
		}
		console.log("queryParams",queryParams);
		
		let domain =  companyId + "/" +productId + "." + entityId;
		return this._httpService.get(this.baseUrl + "/" + domain + queryParams, "json");
	}
	public get = (companyId: string, productId: string, entityId: string, id: any): Observable<any> => {
		let domain =  companyId + "/" +productId + "." + entityId;
		let url = this.baseUrl + "/" + domain + "/" + id;
		return this._httpService.get(url);
	}
	public getByField = (companyId: string, productId: string, entityId: string, fieldId: string, value: any): Observable<any> => {
		let domain =  companyId + "/" +productId + "." + entityId;
		let url = this.baseUrl + "/" + domain + "/" + fieldId + "/" + value;
		return this._httpService.get(url);
	}
	public getAllByField = (companyId: string, productId: string, entityId: string, fieldId: string, value: any): Observable<any> => {
		let domain =  companyId + "/" +productId + "." + entityId;
		let url = this.baseUrl + "/" + domain + "/" + fieldId + "/" + value+"?asList=true";
		return this._httpService.get(url);
	}

	
	public delete = (companyId: string, productId: string, entityId: string, id: any): Observable<any> => {
		let domain =  companyId + "/" +productId + "." + entityId;
		let url = this.baseUrl + "/" + domain + "/" + id;
		return this._httpService.delete(url);
	}
	// public saveOrUpdate = (entity: any, companyId: string, productId: string, entityId: string, toNotify: boolean = false): Observable<any> => {
	public saveOrUpdate = (entity: any, entityDef: EntityDefinition, product: Product, company: Company, toNotify: boolean = false): Observable<any> => {
		let companyId = company.companyId;
		let productId = product.productId
		let entityId = entityDef.entityId;

		let domain =  companyId + "/" +productId + "." + entityId;
		let url = this.baseUrl + "/" + domain;
		entity.lastUpdateDate = moment().format("YYYY-MM-DD hh:mm:ss");
		let toCall;
		if (entity.id) {
			console.log("execute PUT");
			
			toCall = this._httpService.put(url + "/"+entity.id + "?notify="+toNotify, entity,"json");
		}
		else {
			console.log("execute POST");
			toCall = this._httpService.post(url + "?notify="+toNotify,entity,"json");
		}
		console.log("to save: ", entity);
		
		return toCall.pipe(
			map((result) => {
				if (environment.domainConfig.allowExternalProviders) {
					this._dataLake.saveEntity(entity, entityDef, product, company).pipe(
						catchError((err, caught) => {
							console.log("Exception", err);
							
							return of(null)
						})
					)
					.subscribe((r) => {
						console.log("Data saved on data lake. Result is:", r);
						
					})
				}
				return result;
			})
		)
	}

	private path = (obj,path) => {
        try{
            return eval("obj."+path);
        } catch(e) {
            return undefined;
        }
    }

	public async createAndWait (entity: any, companyId: string, productId: string, entityId: string, toNotify: boolean = false): Promise<any> {
		let domain =  companyId + "/" +productId + "." + entityId;
		let url = this.baseUrl + "/" + domain;
		entity.lastUpdateDate = moment().format("YYYY-MM-DD hh:mm:ss");
		return this._httpService.post(url + "?notify="+toNotify,entity,"json").toPromise();
	}

	public certify = async (data: any, entityDef: EntityDefinition, product: Product, company: Company, userProfile: UserProfile, secret: string): Promise<any> => {
		console.log("Data to certify ", data);
		
		let fieldsToCertify : any = {};
		fieldsToCertify["azienda"] = company.name;
		
		if (company.province) fieldsToCertify["indirizzo azienda"] = company.address + ", " + company.cap + " " + company.city+ " (" + company.province +")" ;
		else fieldsToCertify["indirizzo azienda"] = company.address + ", " + company.cap + " " + company.city ;
		fieldsToCertify["prodotto"] = product.name;
		if (product.productFields && product.productFields.length>0) {
			product.productFields.forEach((field) => {
				fieldsToCertify["Prodotto - " + field.name] = field.value;
			})
		}
		if (product.image) {
			fieldsToCertify["immagine_prodotto_url"] = environment.services.mediaContentsBasePath + "/"+ product.image; 
			await this.getImageHash(product.image).toPromise().then((imgHash) => {
				fieldsToCertify["immagine_prodotto_hash"] = imgHash;
			});
		}
		
		if (product.image_3d) {
			fieldsToCertify["immagine3d_url"] =  environment.services.mediaContentsBasePath + "/"+product.image_3d;
			await this.getImageHash(product.image_3d).toPromise().then((imgHash) => {
				fieldsToCertify["immagine3d_hash"]= imgHash;
			});
		}
		for (const group of entityDef.groups)  {
			if (group.isArray) {
				if (data[group.groupLabel] && data[group.groupLabel].length > 0) {
					let index = 0;
					for (const element of data[group.groupLabel]) {
						let values = [];
						for (const field of group.fields) {
							if (field.fieldToCertify &&  element[field.fieldLabel]) {
								if (field.fieldType == FieldType.IMAGE || field.fieldType == FieldType.FILE) {
									values.push(field.fieldLabel + "_url: " + environment.services.mediaContentsBasePath + "/"+ element[field.fieldLabel]); 
									await this.getImageHash(element[field.fieldLabel]).toPromise().then((imgHash) => {
										values.push[field.fieldLabel + "_hash: " + imgHash];
									});
								}
								else if (field.fieldType == FieldType.DATE) {
									values.push(field.fieldLabel + ": " + moment( element[field.fieldLabel]).format("DD/MM/YYYY"));
								}
								else if (field.fieldType == FieldType.MULTIPLE_SELECT &&  element[field.fieldLabel] &&  element[field.fieldLabel].join) {
									values.push(field.fieldLabel + ": " +  element[field.fieldLabel]?.join(", "));
								}
								else {
									values.push(field.fieldLabel + ": " + element[field.fieldLabel]);
								}
							}
						}
						fieldsToCertify[(++index) + ". " + group.groupLabel] = values.join(" - ");
					};
				}

			}
			else {
				for (const field of group.fields) {
					if (field.fieldToCertify && data[group.groupLabel][field.fieldLabel]) {
						if (field.fieldType == FieldType.IMAGE || field.fieldType == FieldType.FILE) {
							fieldsToCertify[""+field.fieldLabel + "_url"] =  environment.services.mediaContentsBasePath + "/"+ data[group.groupLabel][field.fieldLabel];
							await this.getImageHash(data[group.groupLabel][field.fieldLabel]).toPromise().then((imgHash) => {
								fieldsToCertify[""+field.fieldLabel + "_hash"]= imgHash;
							});
						}
						else if (field.fieldType == FieldType.DATE) {
							fieldsToCertify[""+field.fieldLabel] =  moment(data[group.groupLabel][field.fieldLabel]).format("DD/MM/YYYY");
						}
						else if (field.fieldType == FieldType.MULTIPLE_SELECT) {
							fieldsToCertify[""+field.fieldLabel] =  data[group.groupLabel][field.fieldLabel].join(", ");
						}
						// else if (field.fieldType == FieldType.DATETIME) {
						// 	fieldsToCertify[""+field.fieldLabel] =  moment(data[group.groupLabel][field.fieldLabel]).format("DD/MM/YYYY hh.mm");
						// }
						else {
							fieldsToCertify[""+field.fieldLabel] =  data[group.groupLabel][field.fieldLabel];

						}
					}
				}
			}
		}
		console.log("Data to certify ", fieldsToCertify);

		// entityDef.groups.forEach((group) => {
		// 	group.fields.forEach((field) => {
		// 		if (field.fieldToCertify) {
		// 			fieldsToCertify[field.fieldLabel] = data[""];
		// 		}
		// 	})
		// })
		
		// // for (const element of certificationFieldsSpec) {
		// // 	if (element.isArray) {
		// // 		fieldsToCertify = [];
		// // 		for (const obj of data[element.fieldId]) {
		// // 			if (!obj.removedFromBlockChain) {
		// // 				let objToCert: any = {};
		// // 				for (const spec of element.arrayFieldsSpec) {
		// // 					if (this.path(obj, spec.fieldId)) {
		// // 						objToCert[spec.fieldLabel] =  (spec.startValue) ? spec.startValue + this.path(obj, spec.fieldId): this.path(obj, spec.fieldId);
		// // 						if (spec.isImage) {
		// // 							await this._restaurantProvider.getImageHash(this.path(obj, spec.fieldId)).toPromise().then((imgHash) => {
		// // 								objToCert[spec.fieldLabel.replaceAll("_url","_hash")] = imgHash;
		// // 							});
		// // 						}
	
		// // 					}
		// // 				}
		// // 				fieldsToCertify.push(objToCert);
		// // 			}
					
		// // 		}
		// // 	}
		// // 	else {
		// // 		if (this.path(data, element.fieldId)) {
		// // 			if (element.isImage) {
		// // 				fieldsToCertify[element.fieldLabel + "_url"] =  (element.startValue) ? element.startValue + this.path(data, element.fieldId): this.path(data, element.fieldId);
		// // 				await this._restaurantProvider.getImageHash(this.path(data, element.fieldId)).toPromise().then((imgHash) => {
		// // 					fieldsToCertify[element.fieldLabel+ "_hash"]= imgHash;
		// // 				});
		// // 			}
		// // 			else {
		// // 				fieldsToCertify[element.fieldLabel] =  (element.startValue) ? element.startValue + this.path(data, element.fieldId): this.path(data, element.fieldId);
	
		// // 			}
		// // 		}
				

		// // 	}
		// // }

		

		// data.productFields.forEach((field) => {
		// 	fieldsToCertify[field.name] = field.value;
		// })
		// if (data.image) {
		// 	fieldsToCertify["immagine_url"] =  environment.services.mediaContentsBasePath + "/"+data.image;
		// 	await this.getImageHash(data.image).toPromise().then((imgHash) => {
		// 		fieldsToCertify["immagine_hash"]= imgHash;
		// 	});
		// }

		let toSend: any = new Object();
		toSend.identity = userProfile.email;
		toSend.name = userProfile.firstName + " " + userProfile.lastName;
		toSend.pubkey = userProfile.walletInfo.pubKey;

		toSend.azienda = company.companyId;
		toSend.cert_id = data.id;

		toSend.data = fieldsToCertify;		
		
		toSend.decorator_name = product.name + " - " + entityDef.name;
		toSend.decorator_description = entityDef.name + " relativo a " + product.name;
		toSend.decorator_external_url = URLUtils.getCertPageURL(company,product, entityDef, data)
		toSend.decorator_image =  environment.services.mediaContentsBasePath + "/"+product.image;
		if (product.image)
			toSend.decorator_image =  environment.services.mediaContentsBasePath + "/"+product.image;
		if (product.image_3d)
			toSend.decorator_image_3d =  environment.services.mediaContentsBasePath + "/"+product.image_3d;


		// return this._chainService.certifyEntity(toSend, secret).toPromise();


		return new Observable((observer) => {
			this._chainService.certifyEntity(toSend, secret).subscribe(
				(result) => {
					if (result) {
						data.certified = true;
						data.certifiedInDateUTC = moment().format();
						// this.saveOrUpdate(data, company.companyId, product.productId, entityDef.entityId).subscribe((r) => {
						this.saveOrUpdate(data, entityDef, product, company).subscribe((r) => {
							observer.next(result);
							observer.complete();
						})
					}
					else {
						observer.next(null);
						observer.complete();
					}
				},
				(error) => {
					observer.next(null);
					observer.complete();
				})

		}).toPromise();

	}

	public getImageHash(imageId): Observable<string>{
		return this._httpService.get(environment.services.mediaContentsBasePath + "/"+imageId + "/hash", "text");
	}

	// protected  certifyData = async (entityDef, dataToCertify: any) => {
	// 	let data = this.currentDomainConfig.marshalCerdData(dataToCertify);
	// 	let certificationFieldsSpec: any = entityDef.resetCertificationFieldsSpec(dataToCertify);
		
		

	// 	let fieldsToCertify : any = {};
		
	// 	for (const element of certificationFieldsSpec) {
	// 		if (element.isArray) {
	// 			fieldsToCertify = [];
	// 			for (const obj of data[element.fieldId]) {
	// 				if (!obj.removedFromBlockChain) {
	// 					let objToCert: any = {};
	// 					for (const spec of element.arrayFieldsSpec) {
	// 						if (this.path(obj, spec.fieldId)) {
	// 							objToCert[spec.fieldLabel] =  (spec.startValue) ? spec.startValue + this.path(obj, spec.fieldId): this.path(obj, spec.fieldId);
	// 							if (spec.isImage) {
	// 								await this._restaurantProvider.getImageHash(this.path(obj, spec.fieldId)).toPromise().then((imgHash) => {
	// 									objToCert[spec.fieldLabel.replaceAll("_url","_hash")] = imgHash;
	// 								});
	// 							}
	
	// 						}
	// 					}
	// 					fieldsToCertify.push(objToCert);
	// 				}
					
	// 			}
	// 		}
	// 		else {
	// 			if (this.path(data, element.fieldId)) {
	// 				if (element.isImage) {
	// 					fieldsToCertify[element.fieldLabel + "_url"] =  (element.startValue) ? element.startValue + this.path(data, element.fieldId): this.path(data, element.fieldId);
	// 					await this._restaurantProvider.getImageHash(this.path(data, element.fieldId)).toPromise().then((imgHash) => {
	// 						fieldsToCertify[element.fieldLabel+ "_hash"]= imgHash;
	// 					});
	// 				}
	// 				else {
	// 					fieldsToCertify[element.fieldLabel] =  (element.startValue) ? element.startValue + this.path(data, element.fieldId): this.path(data, element.fieldId);
	
	// 				}
	// 			}
				

	// 		}
	// 	}
		
	// }
}