import { Type } from '@angular/core';
import { ActionCommand, SearchCriteria, SearchResult, SortCriteria } from '../../models/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseDataTableCellTemplateComponent } from './data-table-cell-template.component';

export class DataTableColumn {
    public name: string;
    public label?: string;
    public sortable: boolean = true;
    public alignment: DataTableColumnAlignment = DataTableColumnAlignment.LEFT;
    public template?: Type<BaseDataTableCellTemplateComponent>;
    public metadata: any = {};
}


export enum DataTableColumnAlignment {
    LEFT = "text-left",
    CENTER = "text-center",
    RIGHT = "text-right"
}


export class DataTableAction extends ActionCommand {
}

export interface IDataTableManager<T> {
    data: BehaviorSubject<ListData<T>>;
    searchCriteria: SearchCriteria;
    searchCallback: (pageIndex: number, pageSize: number, reloading: boolean, sortField?: string, sortAsc?: boolean) => void;
    startSearch: () => void
    startReload: () => void
    destroy: () => void;
	numberOfAvailableItems:() => number;
}

export class RemoteDataTableManager<TData = any, TSearchCriteria extends SearchCriteria = SearchCriteria> implements IDataTableManager<TData> {
    public data = new BehaviorSubject<ListData<TData>>(new ListData<TData>([], 0, 0));
    public searchCriteria: TSearchCriteria;
	public totalCount: number = 0;

    constructor(private _searchFunc: (searchCriteria: SearchCriteria, reloading: boolean) => Observable<SearchResult<TData>>, private setSearchCriteriaFunc: (searchCriteria: SearchCriteria) => void = (searchCriteria: SearchCriteria) => { }, initialSearchCriteria: TSearchCriteria = null) {
        this.searchCriteria = initialSearchCriteria || <TSearchCriteria>(new SearchCriteria());
    }

    public searchCallback = (pageIndex: number, pageSize: number, reloading: boolean, sortField?: string, sortAsc?: boolean): void => {
        this.searchCriteria = <TSearchCriteria>new SearchCriteria();
        this.searchCriteria.pageNumber = pageIndex;
        this.searchCriteria.itemsPerPage = pageSize;
        this.searchCriteria.field = sortField;
        this.searchCriteria.ascending = sortAsc || false;
        this.setSearchCriteriaFunc(this.searchCriteria);
        this.search(this.searchCriteria, reloading);
    }

	public numberOfAvailableItems = (): number => {
		return this.totalCount;
	}

    public startSearch = (): void => {
        this.searchCriteria.pageNumber = 1;
        this.setSearchCriteriaFunc(this.searchCriteria);
        this.search(this.searchCriteria, false);
    }
    public startReload = (): void => {
        this.search(this.searchCriteria, false);
    }

    private search = (searchCriteria: TSearchCriteria, reloading: boolean): void => {
        this._searchFunc(searchCriteria, reloading).subscribe(result => {
			this.totalCount = result.totalCount;
            let data = new ListData(result.items, result.totalCount, searchCriteria.pageNumber);
            this.data.next(data);
        });
    }
    public destroy = (): void => {
        this.data.complete();
    }

}

export class ListTableManager<TData = any>{
    public sortCriteria: SortCriteria = new SortCriteria();
    private _reload: boolean = true;
    public data = new BehaviorSubject<ListData<TData>>(new ListData<TData>([], 0, 0));
    constructor(private _searchFunc: () => Observable<TData[]>) {
    }

    public search = (sortCriteria: SortCriteria): void => {
        let obs: Observable<TData[]>;
        if (this._reload) {
            obs = this._searchFunc();
        }
        else {
            obs = of(this.data.value.data);
        }
        obs.subscribe((result: TData[]) => {
            this._reload = false;
            let items: TData[] = [...result];
			
            if (sortCriteria.field) {
                if (sortCriteria.ascending) {
                    items = items.sortAsc(f => f[sortCriteria.field]);
                }
                else {
                    items = items.sortDesc(f => f[sortCriteria.field]);
                }
            }
            let ret = new ListData(items, items.length, 1);
            this.data.next(ret);
        });
    }

    public startSearch = (): void => {
        this.search(this.sortCriteria);
    }

    public startReload = (): void => {
        this._reload = true;
        this.search(this.sortCriteria);
    }
}

export class ListData<T> {
    constructor(public data: T[], public totalCount: number, public pageIndex: number) {

    }
}