import { Component, ViewEncapsulation } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { SpinnerService } from 'app/common/services/spinner.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'spinner',
    templateUrl: './spinner.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':leave',
                    [
                        style({ height: 300, opacity: 1 }),
                        animate('1s ease-in',
                            style({ height: 0, opacity: 0 }))
                    ]
                )
            ]
        )
    ]
})
export class SpinnerComponent {
    public visible$: Observable<boolean>;
    constructor(public spinnerService: SpinnerService) {
        this.visible$ = this.spinnerService.show$.asObservable();
    }
}
