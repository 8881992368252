import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {
	transform(fullName: string): any {
		if (fullName) {
			if (fullName.indexOf(" ") > 0)
				return fullName
					.split(' ')
					.map((n) => n[0])
					.join('').substring(0,2);
			return fullName.substring(0,2);
		}
		
	}
}
