import { Injectable } from "@angular/core";
import { ClaimedNFT } from "app/models/profile.models";
import { Restaurant } from "app/models/restaurant.model";
import { environment } from "environments/environment";
import { Observable, of } from "rxjs";
import { HttpService } from "./http.service";
declare var  Web3;// = require('web3');

export class EthAccount {
	pubblicKey: string;
	privateKey: string;
}

@Injectable({
	providedIn: 'root'
})
export class BlockChainService {
	constructor(private _httpService: HttpService) {
	}

	public createAccount = (test=false): EthAccount => {
        let web3;
        if (test) web3 = new Web3(new Web3.providers.HttpProvider("https://rpc-mumbai.maticvigil.com/v1/906b20c0a8ab24fba129b1b2bc52b7857e8f57d7"));
        else web3 = new Web3(new Web3.providers.HttpProvider("https://rpc-mainnet.maticvigil.com/v1/906b20c0a8ab24fba129b1b2bc52b7857e8f57d7"));
        let account = web3.eth.accounts.create(web3.utils.randomHex(32));
        // return [account.address, account.privateKey]
        return {pubblicKey: account.address, privateKey: account.privateKey};

    }
	/**
	* It signs data with the sk provided as input
	* @param {secret key of an account signing the data} privateKey 
	* @param {message to be signed} data 
	* @returns a string composed like this: <hash of the signed message>;<the signature over the data>
	*/
   private signData = (privateKey, data, test=false) => {
	   let web3;
	   if (test) web3 = new Web3(new Web3.providers.HttpProvider("https://rpc-mumbai.maticvigil.com/v1/906b20c0a8ab24fba129b1b2bc52b7857e8f57d7"));
	   else web3 = new Web3(new Web3.providers.HttpProvider("https://rpc-mainnet.maticvigil.com/v1/906b20c0a8ab24fba129b1b2bc52b7857e8f57d7"));
	   let signature = web3.eth.accounts.sign(data, privateKey);
	   return `${signature.messageHash};${signature.signature}`;        
   }

       /**
     * This functions creates a random challenge and checks whether the sk is associated with the pk provided
     * @param {the public key that claims to have signed the data} pk 
     * @param {secret key of an account signing the data} sk 
     * @returns a boolean about the correctness of the verification
     */

	public verifyPublicKey = (pk, sk, test=false) => {
        let web3;
        if (test) web3 = new Web3(new Web3.providers.HttpProvider("https://rpc-mumbai.maticvigil.com/v1/906b20c0a8ab24fba129b1b2bc52b7857e8f57d7"));
        else web3 = new Web3(new Web3.providers.HttpProvider("https://rpc-mainnet.maticvigil.com/v1/906b20c0a8ab24fba129b1b2bc52b7857e8f57d7"));
		const ch = web3.utils.randomHex(32);
        let signature = web3.eth.accounts.sign(ch, sk);
        let recoveredPk = web3.eth.accounts.recover(ch, signature.signature);
        return pk == recoveredPk;
    }
	
	public getSignature(data: any, secret: string) {
		const message = data.cert_id+ JSON.stringify(data.data);
		const signature = this.signData(secret, message);

		return {
			message: message,
			signature: signature
		}
	}

	public createWallet = (data: {pubkey: string}): Observable<any> => {
		let url = environment.services.newBlockchainBasePath + "/user/pubkey";
		return this._httpService.post(url,data,"text");
	}

	public certifyEntity = (data: any, secret: string): Observable<any> => {
		let sign = this.getSignature(data,secret);
		let url = environment.services.newBlockchainBasePath + "/v2/issue";
		data.signature = sign.signature;
		return this._httpService.post(url,data,"text");
	}

	public claimNft = (nft: ClaimedNFT): Observable<any> => {
		let url = environment.services.newBlockchainBasePath + "/v2/transfer";
		return this._httpService.post(url, {"cert_id": nft.cert_id, "azienda": nft.domain}, "text")
	}
}