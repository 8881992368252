import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LandingPageDefinition } from 'app/models/landing-page.model';



@Component({
	selector: 'data-view',
	templateUrl: './data-view.page.html',
	styleUrls: ['data-view.page.scss']
})
export class DataViewPage implements OnInit, AfterViewInit{
	companyId: string;
	landingPageConfig: LandingPageDefinition;
	certificate: any;
	issue_date: any;
	error: "NOT_FOUND" | "NOT_AVAILABLE";
	pageLoaded = false;
	certSignature: any = {};
	certificateId;
	filterText = "";
	landingPageSiteLogo = environment.domainConfig.landingPageSiteLogo;
	
	constructor(
		private _translate: TranslateService,
		private _router: Router
	) {

	}

	sectionLogo;

	imageBaseUrl = environment.services.getMediaContentsBasePath + "/";

	companyLogo;

	public get avatarLogo() {
		if (this.landingPageConfig)
			return this.imageBaseUrl + this.landingPageConfig.avatarLogo;
		return "";
	}

	ngAfterViewInit(): void {
		
	}

	
	ngOnInit() {
		
		

		this.pageLoaded = true;
	}

	goToSection = (section) => {
		const queryParams = {};
		this._router.navigate([section], { queryParams: queryParams });
	}
	
}